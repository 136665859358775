import en from '@/assets/i18n/en.json'
import vi from '@/assets/i18n/vi.json'
import faqsVi from '@/assets/i18n/faqs.vi'
import faqsEn from '@/assets/i18n/faqs.en'
import userGuideVi from '@/assets/i18n/userguide.vi'
import userGuideEn from '@/assets/i18n/userguide.en'
import packagesVi from '@/assets/i18n/packages.vi'
import packagesEn from '@/assets/i18n/packages.en'
import titleVi from '@/assets/i18n/title.vi'
import titleEn from '@/assets/i18n/title.en'

export default {
  en: { ...en, faqs: faqsEn, title: titleEn, packages: packagesEn, userguide: userGuideEn },
  vi: { ...vi, faqs: faqsVi, title: titleVi, packages: packagesVi, userguide: userGuideVi },
}
