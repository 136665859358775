<template>
  <div class="d-block d-md-none">
    <div class="bottom-bar">
      <div class="bottom-bar__wrap h-100">
        <div
          class="
            bottom-bar__wrap-items
            d-flex
            justify-content-around
            align-items-center
            h-100
          "
        >
          <p
            class="bottom-bar__item text-center mb-0"
            @click="activeSidebar = !activeSidebar"
          >
            <MenuIcon :active="activeSidebar" />
            <span :class="{'text-white': activeSidebar}" class="d-block">{{ $t('bottom-bar.Menu') }}</span>
          </p>
          <p
            class="bottom-bar__item text-center mb-0"
            @click="goTo('/app/wallets/transfers')"
          >
           <TransferIcon :active="$route.fullPath == '/app/wallets/transfers'" />
            <span :class="{'text-white': $route.fullPath == '/app/wallets/transfers' }" class="d-block">{{ $t('bottom-bar.Transfer') }}</span>
          </p>
          <p
            class="bottom-bar__item text-center mb-0"
            @click="goTo('/scanqr')"
          >
           <ScanIcon :active="$route.fullPath == '/scanqr' " />
            <span :class="{'text-white': $route.fullPath == '/scanqr' }" class="d-block">{{ $t('bottom-bar.Scan-QR') }}</span>
          </p>
          <p
            class="bottom-bar__item text-center mb-0"
            @click="goTo('/receive')"
          >
           <ReceiveIcon :active="$route.fullPath == '/receive'" />
            <span :class="{'text-white': $route.fullPath == '/receive' }" class="d-block">{{ $t('bottom-bar.Receive') }}</span>
          </p>
          <p
            class="bottom-bar__item text-center mb-0"
            @click="goTo('/wallet')"
          >
           <WalletIcon :active="$route.fullPath == '/wallet'" />
            <span :class="{'text-white': $route.fullPath == '/wallet' }" class="d-block">{{ $t('bottom-bar.Wallets') }}</span>
          </p>
        </div>
      </div>
    </div>
    <SideBarMB :activeSidebar="activeSidebar" @closeMenu="closeMenu" />
  </div>
</template>

<script>
import SideBarMB from "@/layout/mobile/SideBarMB";
import MenuIcon from './icon/menu.vue'
import ScanIcon from './icon/scan.vue'
import WalletIcon from './icon/wallet.vue'
import ReceiveIcon from './icon/receive.vue'
import TransferIcon from './icon/transfer.vue'
import isEmpty from "lodash/isEmpty";
import {MODAL} from "@/constants";
export default {
  data() {
    return {
      activeSidebar: false,
    };
  },
  components: {
    SideBarMB,
    MenuIcon,
    ScanIcon,
    WalletIcon,
    ReceiveIcon,
    TransferIcon,
  },
  methods: {
    goTo (value) {
      if (isEmpty(this.$store.getters["Auth/currentUser"])) {
        this.$bvModal.show(MODAL.LOGIN)
        return
      }
      this.$router.push(value);
      this.activeSidebar = false;
    },
    closeMenu(value) {
      this.activeSidebar = value;
    },
  },
};
</script>
<style language="scss">
.bottom-bar {
  z-index: 14;
  height: 56px;
  position: fixed;
  bottom: 0;
  background: #152b4b;
  right: 0;
  left: 0;
  color: #738cb0;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
</style>
