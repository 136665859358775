import repository from './repository'

const resource = '/commissions'

export default {
  indirect(payload) {
    return repository.get(`${resource}/indirect-history`, { params: payload })
  },
  direct(payload) {
    return repository.get(`${resource}/direct-history`, { params: payload })
  },
  faststar(payload) {
    return repository.get(`${resource}/fast-star-bonus-history`, { params: payload })
  },
  pool(payload) {
    return repository.get(`api/pool/pool-bonus-history`, { params: payload })
  },
  faststarthisweek() {
    return repository.get(`/api/new-commission/users-have-new-title`)
  },
  shareAp(payload) {
    return repository.get(`/wallet/get-share-ap-history`, { params: payload })
  },
}
