<template>
  <footer class="footer d-flex justify-content-center align-items-center">
    <div class="d-flex justify-content-center text-center footer-width">
      <span>© VLINK META 2022</span>
      <div class="footer__link">
        <router-link :to="{ path: '/terms' }">{{ $t('footer.terms') }}</router-link> |
        <router-link :to="{ path: '/policy' }">{{ $t('footer.policy') }}</router-link> |
        <router-link :to="{ path: '/faq' }">{{ $t('footer.faq') }}</router-link> |
        <router-link :to="{ path: '/compensation' }">{{ $t('menu.docs') }}</router-link>
      </div>
    </div>
  </footer>
</template>
<script>
export default {};
</script>
<style scoped lang="scss">
.footer {
  width: 100%;
  background: linear-gradient(90deg, #081872 0%, #06123D 17.65%, #040D26 30.98%, #010416 46.21%, #000110 50.28%, #010416 53.79%, #040D26 81.92%, #081872 94.69%);
  color: rgba(49, 118, 156, 1);
  padding: 20px;
  position: relative;
  z-index: 0;
  @media only screen and (max-width: 992px) {
    padding: 5px;
  }
  .footer-width {
    max-width: 1160px;
    margin: 0 auto;
    span {
      padding-top: 0;
      @media only screen and (max-width: 992px) {
        padding-top: 10px;
      }
    }
    @media only screen and (max-width: 992px) {
      flex-direction: column;
      font-size: 14px;
      line-height: 17px;
    }
  }
  &__link {
    padding-left: 5px;
    color: #007bff;
    a {
      color: #007bff;
    }
    @media only screen and (max-width: 992px) {
      padding: 10px;
    }
  }
}
</style>
