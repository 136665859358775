import repository from './repository'

const resource = '/api/Asset'
const ImageManage = '/image/'

export default {
  upload(payload) {
    const headers = { 'Content-Type': 'multipart/form-data' };
    return repository.post(`${resource}`, payload, {headers} )
  },
  getPost(id) {
    return repository.get(`${ImageManage}${id}/get` )
  },
  getBanners(type) {
    return repository.get(`${ImageManage}${type}` )
  }
}
