import repository from './repository'
import qs from 'qs'
const resource = '/api/Cart'

export default {
  addToCart(payload) {
    return repository.post(`${resource}/add-item-to-cart`, payload)
  },
  getMyCart() {
    return repository.get(`${resource}/get-my-cart`)
  },
  updateItem(payload) {
    return repository.put(`${resource}/update-item-cart`, payload)
  },
  splitItem(payload) {
    return repository.post(`${resource}/split-cart`, payload)
  },
  removeItem(id, payload) {
    return repository.delete(`${resource}/delete-cart-item/${id}`, {
      data: JSON.stringify(payload),
    })
  },
  checkout(payload) {
    return repository.post(`${resource}/check-out`, payload)
  },
  checkOutByCash(payload) {
    return repository.post(`${resource}/check-out-by-cash`, payload)
  },
  checkoutByCreditCard(payload) {
    return repository.post(`${resource}/check-out-by-credit-card`, payload)
  },
  useRedeem(code) {
    return repository.post(`${resource}/use-redeem-code?redeemCode=${code}`)
  },
  getTransactioncode() {
    return repository.get(`${resource}/reference-code`)
  },
  approve(code) {
    return repository.post(`${resource}/approve-cart?cartId=${code}`)
  },
  reject(code) {
    return repository.post(`${resource}/reject-cart?cartId=${code}`)
  },
  checkOutByVlgToken(payload) {
    return repository.post(`${resource}/check-out-by-vlg-token`, payload)
  },
  checkOutByVlgPlus(payload) {
    return repository.post(`${resource}/check-out-by-vlg-plus`, payload)
  },
  checkOutByVlgGold(payload) {
    return repository.post(`${resource}/check-out-by-gold`, payload)
  },
  checkoutByUsdt(payload) {
    return repository.post(`${resource}/check-out-by-internal-usdt`, payload)
  },
  fetchCryptoInfo(payload) {
    return repository.post(`${resource}/check-out-by-crypto`, payload)
  },
  checkoutByCrypto(code) {
    return repository.post(
      `${resource}/confirm-check-out-by-crypto?cartId=${code}`,
    )
  },
  getVmmHistory() {
    return repository.get('/api/Package/get-vmm-history')
  },
  getPaymentMethodsByPackageIds(packageIds) {
    return repository.get('/api/Cart/payment-methods', {
      params: {packageIds: packageIds},
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
  },
  getTotalcart(payload) {
    return repository.get(`${resource}/estimate-checkout-amount`, {
      params: payload,
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
  },
}
