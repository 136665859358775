<template>
  <div class="top-nav">
    <div class="logo" @click="$router.push('/')">
      <img src="/img/logo.webp" style="max-width:200px" alt="logo" />
    </div>
    <!-- <div class="top-nav__alert" :class="{'invisible': dataCommission.length === 0}">
      <img src="/img/speaker.svg" alt="speaker" class="speaker" style="margin-right: 15px;">
      <marquee onmouseover="this.stop();" onmouseout="this.start();" width="90%" direction="left">
        {{ $t('reached-title') }}:
        <span v-if="dataCommission && dataCommission.length"> {{ dataCommission.toString() }}</span>
      </marquee>
    </div> -->
    <div
      v-if="$store.state.Auth.currentUser && Object.keys($store.state.Auth.currentUser).length"
      class="top-nav__user-logged"
    >
      <div class="shop-cart" @click="redirectShoppingCart">
        <img src="/img/header/cart.svg" alt="cart" class="cart">
        <span class="number-cart">
          {{ numberMyCart }}
        </span>
      </div>
      <div class="information">
        <img :src="avatarPath" alt="avatar" class="avatar" onerror="this.onerror=null;this.src='/img/header/avatar.png'">
        <div class="name">
          <span>{{ $store.state.Auth.currentUser.FirstName + ' ' + $store.state.Auth.currentUser.LastName }}</span>
        </div>
        <div class="arrow-down"></div>
        <div class="menu-profile">
          <div class="list-menu">
            <div class="icon-arrow-shadow">
              <div class="triangle-with-shadow"></div>
            </div>
            <div class="item d-flex justify-content-center flex-column">
              <router-link :to="`/shop?tab=MyPackage`" class="text-center" v-if="!!currentUser.ImagePath">
                <img
                  width="110px"
                  height="auto"
                  :src="$apiImageUrl + currentUser.ImagePath"
                  class="packages-thumnails mt-2"
                  :alt="currentUser.PackageName"
                />
              </router-link>
              <a href="javascript:void(0)" class="mt-3 text-center" v-if="isVMF" @click="$bvModal.show(MODAL.VMF_CERTIFICATE)">{{ $t("view-cer") }}</a>
              <p class="m-3 text-center h5 text-body" v-if="!!currentUser.Title">{{ currentUser.Title }}</p>
            </div>
            <div
              class="item"
              v-for="menuItem in dashBoardProfileMenu"
              :key="menuItem.label"
            >
              <router-link
                class="nav-link"
                :to="{ path: menuItem.path }"
                :class="{'active-user': $route.path === menuItem.path}"
              >
                <span class="name">{{ $t(`menu-items.profiles.${menuItem.label}`) }}</span>
              </router-link>
            </div>
            <div class="item">
              <a class="nav-link log-out" @click="logout()">
                <span class="name">{{ $t("logout") }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="text-white px-3 pointer">
          <span :class="{ 'text-info': langSelected.toUpperCase() == 'EN' }" @click="changeLocale('en')">EN</span> |
          <span :class="{ 'text-info': langSelected.toUpperCase() == 'VI' }" @click="changeLocale('vi')">VI</span>
        </div>
      </div>
    </div>
    <ModalLogin />
    <ModalEmailConfirmation />
    <ModalRegister />
    <ModalForgotPassword />
    <!-- <ModalCertificate v-if="isVMF" /> -->
  </div>
</template>
<script>
import MENU, { MENU_POSITION } from "@/constants/menu";
import { localeOptions, apiImageUrl } from "@/constants/config";
import { RepositoryFactory } from "@apis/repositoryFactory";
const ShopRepository = RepositoryFactory.get("cart");
const CommissionRepository = RepositoryFactory.get("commission");
import ModalLogin from "@/components/Authen/ModalLogin.vue"
import ModalRegister from "@/components/Authen/ModalRegister.vue"
import ModalForgotPassword from "@components/Authen/ModalForgotPassword";
import ModalEmailConfirmation from "@components/Authen/ModalEmailConfirmation";
import { mapActions } from "vuex";
import { MODAL } from "@/constants";
export default {
  components: {
    ModalLogin,
    ModalRegister,
    ModalForgotPassword,
    // ModalCertificate: () => import("@/containers/Wallet/modal-vmf-certificate.vue"),
    ModalEmailConfirmation
  },
  data () {
    return {
      carts: [],MODAL,
      numberCart: 0,
      dataCommission: [],
      langSelected: this.$i18n.locale,
      localeOptions
    }
  },
  computed: {
    avatarPath() {
      return this.currentUser && this.currentUser.Avatar ? apiImageUrl + this.currentUser.Avatar : '/img/header/avatar.png'
    },
    numberMyCart () {
      return this.$store.state.myCart || 0
    },
    dashBoardProfileMenu() {
      return MENU.filter((i) =>
        i.position.includes(MENU_POSITION.DASHBOARD_PROFILE_MENU)
      )
    },
    isVMF () {
      return this.currentUser && this.currentUser.HasCertificate
    }
  },
  async created() {
    try {
      if (this.$store.state.Auth.currentUser && Object.keys(this.$store.state.Auth.currentUser).length !== 0) {
        this.carts = await ShopRepository.getMyCart()
        this.numberCart = this.carts.CartItems.reduce( function(a, b){
          return a + b['Quantity'];
        }, 0 )
        this.$store.commit('setMyCart', this.numberCart)
        this.dataCommission = await CommissionRepository.faststarthisweek()
      }
    } catch (error) {}
  },
  methods: {
    ...mapActions("Auth", {
      signOut: "signOut",
    }),
    ...mapActions(["setLang"]),
    logout() {
      this.signOut();
    },
    changeLocale(e) {
      this.$store.commit("changeLang", e)
      location.reload()
    },
    redirectShoppingCart() {
      document.getElementsByTagName("html")[0].classList.remove("modal-open");
      if (this.$route.path !== '/shopping-cart') {
        this.$store.commit('setRouteBack', this.$route.path)
      }
      this.$router.push('/shopping-cart')
    }
  }
}
</script>
<style scoped lang="scss">
.top-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // background: #FFFFFF;
  box-shadow: 0 1px 25px rgba(0, 0, 0, 0.6);
  padding: 15px 25px;
  color: #FFFFFF;
  background: linear-gradient(90deg, #010177 0%, #040179 18.5%, #0e017f 32.8%, #1e0189 45.7%, #2d007d 57%, #2d007d 70%, #370078 80%, #3c006e 90%, #2f0044 100%);
  &__alert {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .marquee {
      margin-left: 10px;
      &:hover {
        cursor: pointer;
      }
    }
  }
  &__user-logged {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 80px;
    .shop-cart {
      margin-right: 24px;
      position: relative;
      // img {
      //   width: 24px;
      //   height: 24px;
      //   filter: contrast(0);
      // }
      .number-cart {
        background: #007BFF;
        border: 2px solid #FFFFFF;
        width: 17px;
        height: 17px;
        position: absolute;
        top: -5px;
        right: -5px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        color: #FFFFFF;
        font-size: 9px;
        line-height: 9px;
      }
      &:hover {
        cursor: pointer;
      }
    }
    .information {
      display: flex;
      justify-content: flex-start;
      align-content: center;
      position: relative;
      .avatar {
        width: 65px;
        height: 65px;
        margin-right: 16px;
        border-radius: 50%;
        border: 1px solid #F4CC59;
      }
      .name {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 15px;
        span {
          white-space: nowrap;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          // color: #141822;
          max-width: 120px;
          text-overflow: ellipsis;
          overflow: hidden;
          display: inline-block;
        }
      }
      .arrow-down {
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #FFF;
        margin-top: 26px;
      }
      .menu-profile {
        position: absolute;
        right: 20px;
        top: 55px;
        visibility: hidden;
        opacity: 0;
        z-index: 999;
        transition: all linear 0.2s;
        .list-menu {
          position: relative;
          background: #FFFFFF;
          border-radius: 8px;
          padding: 0;
          margin: 0;
          box-shadow: 0 0 10px #43464E;
          .icon-arrow-shadow {
            position: absolute;
            top: -18px;
            right: 30px;
            .triangle-with-shadow {
              width: 25px;
              height: 25px;
              position: relative;
              overflow: hidden;
              box-shadow: 0 16px 10px -17px #FFFFFF;
            }
            .triangle-with-shadow:after {
              content: "";
              position: absolute;
              width: 25px;
              height: 25px;
              background: #FFFFFF;
              transform: rotate(45deg);
              top: 10px;
              left: 0;
              box-shadow: -1px -1px 10px -2px #43464E;
            }
          }
          .item {
            list-style: none;
            .nav-link {
              padding: 12px 24px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              width: 100%;
              height: 100%;
              span.name {
                font-size: 16px;
                line-height: 24px;
                color: #43464E;
                display: inline-block;
                white-space: nowrap;
                margin-right: 0;
              }
              &:hover, &.active-user {
                span.name {
                  color: #007BFF;
                }
              }
              &.log-out {
                span.icon {
                  &:before {
                    color: #e96058;
                  }
                }
                span.name {
                  color: #e96058;
                }
                &:hover {
                  cursor: pointer;
                  span.icon {
                    &:before {
                      color: #e96058;
                    }
                  }
                  span.name {
                    color: #e96058;
                  }
                }
              }
            }
          }
        }
      }
      &:hover {
        cursor: pointer;
        .menu-profile {
          opacity: 1;
          visibility: visible;
        }
      }
    }
    .language-selector {
      overflow: hidden;
      background: rgba(0, 123, 255, 0.2);
      border-radius: 8px;
      padding: 6px 12px;
      margin-left: 20px;
      select {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #007BFF;
        text-indent: 1px;
        text-overflow: '';
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        padding: 2px 20px 2px 2px;
        border: none;
        background: transparent url("/img/arrow-down.svg") no-repeat 28px center;
        &:focus-visible {
          outline: none;
        }
        option {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #007BFF;
          background: rgba(0, 123, 255, 0.2);
          border: none;
        }
      }
    }
  }
}
</style>
