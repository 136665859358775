import repository from './repository'

const resource = '/api/BankInfo'

export default {
  get(id) {
    return repository.get(`${resource}/${id}`)
  },
  create(payload) {
    return repository.post(`${resource}`, payload)
  },
  update(id, payload) {
    return repository.put(`${resource}/${id}`, payload)
  },
  remove(id) {
    return repository.delete(`${resource}/${id}`)
  },
  getMyBanks() {
    return repository.get(`${resource}/get-my-bank`)
  },
  removeMyBanks() {
    return repository.delete(`${resource}/delete-my-bank`)
  },
}