import Vue from "vue";
import VueRouter from "vue-router";

import NotFound from "@/pages/NotFoundPage.vue";
import DashboardLayout from "@/layout/DashboardLayout.vue";
import HomeLayout from "@/layout/HomeLayout.vue";
import CommonLayout from "@/layout/CommonLayout.vue";

const routes = [
  {
    path: "",
    name: "MainHome",
    redirect: "/home",
    component: HomeLayout,
    children: [
      {
        path: "/",
        name: "Home",
        component: () => import("@/pages/App/Home/index.vue"),
      },
    ],
  },
  {
    path: "/home",
    name: "MainHome2",
    redirect: "/home",
    component: HomeLayout,
    children: [
      {
        path: "/home",
        name: "Home2",
        component: () => import("@/pages/App/Home/index.vue"),
      },
    ],
  },
  {
    path: "/dev",
    name: "Dev",
    redirect: "/dev",
    component: HomeLayout,
    children: [
      {
        path: "/dev",
        name: "Dev",
        component: () => import("@/pages/App/Dev/NotificationPopup.vue"),
      },
    ],
  },
  {
    path: "/app/home",
    name: "home",
    redirect: "/",
  },
  {
    path: "/dashboards",
    component: DashboardLayout,
    children: [
      {
        path: "/dashboards",
        name: "Dashboards",
        component: () => import("@/pages/App/Dashboards/index.vue"),
      },
      {
        path: "/cart",
        name: "Cart",
        component: () => import("@/pages/App/Cart/index.vue"),
      },
      {
        path: "/network",
        name: "Network",
        component: () => import("@/pages/App/Network/index.vue"),
      },
      {
        path: "/network-tree",
        name: "NetworkTree",
        component: () => import("@/pages/App/NetworkTree/index.vue"),
      },
      {
        path: "/our-partners",
        name: "OurPartners",
        component: () => import("@/pages/App/OurPartners/index.vue"),
      },
      {
        path: "/details-partner",
        name: "DetailsPartner",
        component: () => import("@/pages/App/OurPartners/details-partner.vue"),
      },
      {
        path: "/profile",
        redirect: '/profile/overview',
        name: "Profile",
        component: () => import("@/pages/App/Profile/index.vue"),
        children: [
          {
            path: "overview",
            name: "Overview",
            component: () => import("@/pages/App/Profile/Info.vue"),
          },
          {
            path: "kyc",
            name: "KYC",
            component: () => import("@/pages/App/Profile/KYC/index.vue"),
          },
          {
            path: "link-kyc",
            name: 'Link KYC',
            component: () => import("@/pages/App/Profile/LinkKYC/index.vue"),
          },
          {
            path: 'kyc/:id',
            name: "KYC Detail",
            component: () => import("@/pages/App/Profile/KYC/Detail.vue"),
          },
          {
            path: "password",
            name: "Password",
            component: () => import("@/pages/App/Profile/Password.vue"),
          },
          {
            path: "security",
            name: "Security",
            component: () => import("@/pages/App/Profile/Security.vue"),
          },
          {
            path: "accounts",
            name: "Accounts",
            component: () => import("@/pages/App/Profile/Accounts.vue"),
          },
        ],
      },
      {
        path: "/shop",
        name: "Shop",
        component: () => import("@/pages/App/Shop/index.vue"),
      },
      {
        path: "/wallet",
        name: "Wallet",
        component: () => import("@/pages/App/Wallet/index.vue"),
      },
      {
        path: "/wallet/detail",
        name: "WalletDetail",
        component: () => import("@/pages/App/Wallet/Detail/index.vue"),
      },
      {
        path: "/wallet/loan",
        name: "Loan",
        component: () => import("@/pages/App/Wallet/Loan/index.vue"),
      },
      {
        path: "/wallet/loan/detail",
        name: "LoanDetail",
        component: () => import("@/pages/App/Wallet/Loan/Detail/index.vue"),
      },
      {
        path: "/package-detail",
        name: "PackageDetail",
        component: () => import("@/pages/App/PackageDetail/index.vue"),
      },
      {
        path: "/preorder-detail",
        name: "PreOrderDetail",
        component: () => import("@/pages/App/PreOrderDetail/index.vue"),
      },
      {
        path: "/shopping-cart",
        name: "ShoppingCart",
        component: () => import("@/pages/App/Shopping-Cart/index.vue"),
      },
      {
        path: "/app/wallets/transfers",
        name: "Transfer",
        component: () => import("@/pages/App/Transfer/index.vue"),
      },
      {
        path: "/wallet/cover-vmm-history",
        name: "coverVMMHistory",
        component: () => import("@/pages/App/Wallet/cover-history.vue"),
      },
      {
        path: "/wallet/cover-vmm-history/:id",
        name: "coverVMMHistoryDetail",
        component: () => import("@/pages/App/Wallet/cover-history-detail.vue"),
      },
      {
        path: "/scanqr",
        name: "ScanQR",
        component: () => import("@/pages/App/ScanQR/index.vue"),
      },

      {
        path: "/receive",
        name: "Receive",
        component: () => import("@/pages/App/Receive/index.vue"),
      },
      {
        path: "/shop/pre-order/event-proposal",
        component: () =>
          import("@/pages/App/Shop/event-proposal")
      },
    ],
  },
  {
    path: '/',
    component: CommonLayout,
    children: [
      {
        path: "/package",
        name: "package",
        component: () => import("@/pages/App/Package/index.vue")
      },
      {
        path: "/partner",
        name: "DetailsPartner",
        component: () => import("@/pages/App/Partners/details-partner.vue"),
      },
      {
        path: "/pre-order",
        name: "DetailsPreOrder",
        component: () => import("@/pages/App/PreOrder/index.vue"),
      }
    ]
  },
  {
    path: "/compensation",
    component: HomeLayout,
    children: [
      {
        path: "/compensation",
        name: "Compensation",
        component: () => import("@/pages/App/Compensation/index.vue"),
      },
    ],
  },
  {
    path: "/lucky-draw/check-in",
    component: HomeLayout,
    children: [
      {
        path: "/lucky-draw/check-in",
        name: "CheckIn",
        component: () => import("@/pages/App/Scanner/index.vue"),
      },
    ],
  },
  {
    path: "/news",
    component: HomeLayout,
    children: [
      {
        path: "/news",
        name: "News",
        component: () => import("@/pages/App/News/index.vue"),
      },
    ],
  },
  {
    path: "/news/details",
    component: HomeLayout,
    children: [
      {
        path: "/news/details",
        name: "Details",
        component: () => import("@/pages/App/News/details.vue"),
      },
    ],
  },
  {
    path: "/faq",
    component: HomeLayout,
    children: [
      {
        path: "/faq",
        name: "Faq",
        component: () => import("@/pages/App/Faq/index.vue"),
      },
    ],
  },
  {
    path: "/contact",
    component: HomeLayout,
    children: [
      {
        path: "/contact",
        name: "Contact",
        component: () => import("@/pages/App/Contact/index.vue"),
      },
    ],
  },
  {
    path: "/user-guide",
    component: HomeLayout,
    children: [
      {
        path: "/user-guide",
        name: "UserGuide",
        component: () => import("@/pages/App/UserGuide/index.vue"),
      },
    ],
  },
  {
    path: "/policy",
    component: HomeLayout,
    children: [
      {
        path: "/policy",
        name: "Policy",
        component: () => import("@/pages/App/Policy/index.vue"),
      },
    ],
  },
  {
    path: "/terms",
    component: HomeLayout,
    children: [
      {
        path: "/terms",
        name: "Terms",
        component: () => import("@/pages/App/Terms/index.vue"),
      },
    ],
  },
  {
    path: "/user/confirm-register",
    component: () => import("@/pages/App/User/ConfirmRegistration.vue"),
  },
  {
    path: "/user/confirm-change-user-info",
    component: () => import("@/pages/App/User/ConfirmChangeInfo.vue"),
  },
  {
    path: "/user/reset-password",
    component: () => import("@/components/Authen/ModalResetPassword.vue"),
  },
  {
    path: "/user/register",
    redirect: to => {
      return { path: '/home', query: { register: 'true', vlinkId: to.query.vlinkId } }
    },
  },
  {
    path: "/credit-lending",
    component: HomeLayout,
    children: [
      {
        path: "/credit-lending",
        name: "credit-lending",
        component: () => import("@/pages/App/Credit-lending/index.vue"),
      },
    ],
  },
  {
    path: "*",
    component: HomeLayout,
    children: [
      {
        path: "",
        name: "NotFound",
        component: NotFound
      },
    ],
  },
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

Vue.use(VueRouter);

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: "nav-item active",
  mode: "history",
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
