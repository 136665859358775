import Vue from 'vue'
import Vuex from 'vuex'
import app from '@/main'
import { setCurrentLanguage } from '@/core/utils'
import get from 'lodash.get'
import Auth from './modules/auth.js'
import user from './modules/user.js'
import Wallet from './modules/wallet'
import createPersistedState from "vuex-persistedstate";
import interceptor from '@/apis/repository'
Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    myCart: 0,
    listIdSplit: [],
    routeBack: '/'
  },
  mutations: {
    setMyCart (state, payload) {
      state.myCart = payload
    },
    setListIdSplit (state, payload) {
      state.listIdSplit = payload
    },
    setRouteBack (state, payload) {
      state.routeBack = payload
    },
    changeLang (state, payload) {
      app.$i18n.locale = payload
      interceptor.defaults.headers['Accept-Language'] = payload
      setCurrentLanguage(payload);
    },
    showError (state, payload) {
      let val = ''
      if (payload.response) {
        val = payload.response.data
      } else if (payload.request) {
        val = payload.request
      } else {
        val = payload.message
      }
      if (payload.response && payload.response.status == 401) {
        try {
          const currentUrl = window.location.pathname + window.location.search
          localStorage.setItem('redirectAfterLogin', currentUrl)
        } catch (error) {
          console.log(error)
        }
        app.$notifyPopup('error',
          app.$t('swal.title.ops'),
          app.$t('pages.expired-session'),
          {
            action1Text: 'OK',
            didCloseFunc: () => app.$store.dispatch('Auth/signOut', true)
          }
        )

      }

      val = get(val, 'message', 'Something went wrong!')
    }
  },
  actions: {
    setLang ({ commit }, payload) {
      commit('changeLang', payload)
    },
    axiosError ({ commit }, payload) {
      commit('showError', payload)
    }
  },
  modules: {
    Auth,
    Wallet,
    user
  }
})
