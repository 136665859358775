import repository from './repository'
import { googleServicesKey } from '@/constants/config'
import axios from 'axios'

const resource = '/user'
const networkTree = '/network-tree'

export default {
  findReferral(id) {
    return repository.get(`${resource}/find-referral/${id}`)
  },
  updatePassword(payload) {
    return repository.put(`${resource}/change-password`, payload)
  },
  updateInformation(payload) {
    return repository.put(`${resource}/update-my-profile`, payload)
  },
  updatePin(payload) {
    return repository.put(`${resource}/update-pin`, payload)
  },
  updateCountry(userId, payload) {
    return repository.put(`${resource}/${userId}/country`, payload)
  },
  addPin(payload) {
    return repository.put(`${resource}/add-pin`, JSON.stringify(payload))
  },
  getMyInformation() {
    return repository.get(`${resource}/get-my-profile`)
  },
  getChildAccounts(key) {
    return repository.get(`${resource}/get-affiliate-member?q=${encodeURIComponent(key)}`)
  },
  createChildAccount(payload) {
    return repository.post(`${resource}/add-affiliate-member`, payload)
  },
  getNetwork(nodeUserId = null, hasRanking = false, hasRedeem = false, page = 1) {
    return repository.get(`${networkTree}/my-network-tree`, { params: { nodeUserId, hasRanking, hasRedeem, page } })
  },
  getChildrenUserHasRedeem(payload) {
    return repository.get(`${networkTree}/children-user-have-redeem`, { params: { ...payload } })
  },
  getChildrenUserHasRanking(payload) {
    return repository.get(`${networkTree}/children-user-have-ranking`, { params: { ...payload } })
  },
  searchOnNetwork(payload) {
    return repository.get(`${networkTree}/search`, {
      params: {
        ...payload
      }
    })
  },
  getStatisticUser() {
    return repository.get(`${resource}/statistic-user`)
  },
  sendContactForm(payload) {
    return repository.post(`${resource}/contact-form`, payload)
  },
  searchUser(key) {
    return repository.get(`/user/admin/search-user?vlinkId=${key}`)
  },
  get2FAStatus() {
    return repository.post(`${resource}/get-2fa-status`, {})
  },
  recoverPin() {
    return repository.post(`${resource}/forgot-pin`, {})
  },
  getOurPartner() {
    return repository.get(`${resource}/our-partner`)
  },
  getOurPartnerById(id) {
    return repository.get(`${resource}/business-detail/${id}`)
  },
  getOurPartnerAvailable() {
    return repository.get(`${resource}/business-show-on-banner`)
  },
  getOurPartnersShowOnMap() {
    return repository.get(`${resource}/business-show-on-map`)
  },
  searchOurPartners(q) {
    return repository.post(`${resource}/search-business?q=${encodeURIComponent(q)}`)
  },
  findUser(username)
  {
    return repository.get(`/find?userName=${username}`)
  },
  UpdateAvatar(payload) {
    return repository.put(`${resource}/update-avatar`, payload)
  },
  UpdateBusinessLogo(payload) {
    return repository.put(`${resource}/update-business-logo`, payload)
  },
  GetGoogleMapLocationByAddress(address) {
    return axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${googleServicesKey}`)
    .then((response) => {
      if (response && response.status === 200 && response.data.results.length > 0) {
        const location = response.data.results[0].geometry.location
        return location
      }
    })
  },
  shareBonuses(payload) {
    return repository.post(`/co-direct-sponsor`, payload)
  },
  getBonuses(childUserId) {
    return repository.get(`/co-direct-sponsor?childUserId=${childUserId}`)
  },
  getNextLevel() {
    return repository.get(`${resource}/check-ranking-condition`)
  },
  fetchNotifications() {
    return repository.get('api/Notification/allow-cancel-pre-order')
  },
  cancelPreOrder() {
    return repository.post(`/api/PreOrder/cancel`, {})
  },
}