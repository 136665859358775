import repository from './repository'

const resource = '/api/merchant'

export default {
  getCurrentTop() {
    return repository.get(`${resource}/current-top`)
  },
  getDirectMerchants() {
    return repository.get(`${resource}/direct-merchants`)
  },
  getContestResults() {
    return repository.get(`${resource}/contest-results`)
  },
}
