<template>
  <div class="compensation d-none">
    <div class="bg-landing" v-for="i in 3" :key="i" />
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.compensation {
  .bg-landing {
    background-size: 100% 100%;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    &:first-child {
      // background-image: url(/assets/img/background-banner1.png);
      // -webkit-animation: scale-frames 60s ease infinite;
      // animation: scale-frames 60s ease infinite;
    }
    &:nth-child(2) {
      background-image: url(/assets/img/background-banner2.png);
      -webkit-animation: scale-frames-two 60s ease infinite;
      animation: scale-frames-two 60s ease infinite;
    }
    &:nth-child(3) {
      background-image: url(/assets/img/background-banner3.png);
      -webkit-animation: scale-frames-three 60s ease infinite;
      animation: scale-frames-three 60s ease infinite;
    }
  }
}

@-webkit-keyframes scale-frames-two {
  0% {
    -webkit-transform: scaleY(1.3);
    transform: scaleY(1.3);
  }

  50% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  to {
    -webkit-transform: scaleY(1.3);
    transform: scaleY(1.3);
  }
}

@keyframes scale-frames-two {
  0% {
    -webkit-transform: scaleY(1.3);
    transform: scaleY(1.3);
  }

  50% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  to {
    -webkit-transform: scaleY(1.3);
    transform: scaleY(1.3);
  }
}

@-webkit-keyframes scale-frames {
  0% {
    -webkit-transform: scale3d(1.3, 0.5, 1.5);
    transform: scale3d(1.3, 0.5, 1.5);
  }

  50% {
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8);
  }

  to {
    -webkit-transform: scale3d(1.3, 0.5, 1.5);
    transform: scale3d(1.3, 0.5, 1.5);
  }
}

@keyframes scale-frames {
  0% {
    -webkit-transform: scale3d(1.3, 0.5, 1.5);
    transform: scale3d(1.3, 0.5, 1.5);
  }

  50% {
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8);
  }

  to {
    -webkit-transform: scale3d(1.3, 0.5, 1.5);
    transform: scale3d(1.3, 0.5, 1.5);
  }
}

@-webkit-keyframes scale-frames-three {
  0% {
    -webkit-transform: scale3d(1.5, 0.3, 2);
    transform: scale3d(1.5, 0.3, 2);
  }

  50% {
    -webkit-transform: scaleY(1.5);
    transform: scaleY(1.5);
  }

  to {
    -webkit-transform: scale3d(1.5, 0.3, 2);
    transform: scale3d(1.5, 0.3, 2);
  }
}

@keyframes scale-frames-three {
  0% {
    -webkit-transform: scale3d(1.5, 0.3, 2);
    transform: scale3d(1.5, 0.3, 2);
  }

  50% {
    -webkit-transform: scaleY(1.5);
    transform: scaleY(1.5);
  }

  to {
    -webkit-transform: scale3d(1.5, 0.3, 2);
    transform: scale3d(1.5, 0.3, 2);
  }
}
</style>
