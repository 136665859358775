<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.3247 8.56375C17.5688 8.80781 17.5688 9.20355 17.3247 9.44762L15.4362 11.3361C15.3142 11.4582 15.1543 11.5192 14.9943 11.5192C14.8344 11.5192 14.6745 11.4582 14.5524 11.3361C14.3083 11.092 14.3083 10.6963 14.5524 10.4523L15.3739 9.6307H7.88559C7.54039 9.6307 7.26059 9.35086 7.26059 9.0057C7.26059 8.66055 7.54039 8.3807 7.88559 8.3807H15.3739L14.5524 7.55914C14.3083 7.31508 14.3083 6.91934 14.5524 6.67527C14.7966 6.43121 15.1923 6.43117 15.4363 6.67527L17.3247 8.56375ZM15.7329 14.3693H8.62609L9.44762 13.5478C9.69172 13.3037 9.69172 12.908 9.44762 12.6639C9.20359 12.4198 8.80785 12.4198 8.56375 12.6639L6.67523 14.5524C6.55801 14.6696 6.49215 14.8286 6.49215 14.9943C6.49215 15.1601 6.55801 15.3191 6.67523 15.4362L8.56375 17.3248C8.68578 17.4468 8.84574 17.5079 9.00566 17.5079C9.16559 17.5079 9.32555 17.4468 9.44758 17.3248C9.69168 17.0807 9.69168 16.685 9.44758 16.4409L8.62602 15.6193H15.7329C16.0781 15.6193 16.3579 15.3395 16.3579 14.9943C16.3579 14.6492 16.0781 14.3693 15.7329 14.3693V14.3693ZM22 12C22 17.514 17.514 22 12 22C6.48598 22 2 17.514 2 12C2 6.48598 6.48598 2 12 2C17.514 2 22 6.48598 22 12ZM20.75 12C20.75 7.17523 16.8248 3.25 12 3.25C7.17523 3.25 3.25 7.17523 3.25 12C3.25 16.8248 7.17523 20.75 12 20.75C16.8248 20.75 20.75 16.8248 20.75 12Z"
      :fill="!active ? '#738CB0' : '#ffffff'"
    />
  </svg>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
</style>