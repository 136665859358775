<template>
  <BaseModal :config="config">
    <template slot="content">
      <div class="receive__title">{{ $t('bottom-bar.Receive') }}</div>
      <div class="receive__content">
        <BaseSelect
          class="theme-light"
          ref="token"
          :input-value="token"
          name="token"
          :label="$t('transfer.receive')"
          :items="tokens"
          text-field="label"
          value-field="value"
          :is-show-default="false"
          key-loop="index"
          @change="token = $event"
        />
        <div class="qr-img" id="linkQr">
          <qrcode-vue
            id="qrimage"
            :value="qrLink"
            size="338"
            level="M"
          ></qrcode-vue>
        </div>
        <div class="address">
          <p class="mt-4">{{ $t("wallet.address") }}</p>
          <a class="d-block" @click="copyLink(walletAddress)">
            <BaseInput
              disabled
              :value="walletAddress"
              addonRightIcon="icon-icon-copy"
              class="link-ref"
            />
          </a>
        </div>
        <div class="icon-save">
          <a @click="downloadQR('qrcode-for-scan.png', qrLink)"
            ><img src="/img/wallet/icon-save.svg" alt="icon-save"
          /></a>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { MODAL } from "@/constants";
import QrcodeVue from "qrcode.vue";
import { homeUrl } from "@/constants/config";
import BaseSelect from "@/components/Inputs/BaseSelect.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import QRCode from "qrcode";
import helpers from "@/core/mixins/helpers";
import {mapGetters} from "vuex";

export default {
  name: "ModalReceive",
  components: {
    QrcodeVue,
    BaseInput,
    BaseSelect,
  },
  mixins: [helpers],
  data() {
    return {
      config: {
        id: MODAL.RECEIVE,
        class: "modal--receive",
        noCloseOnBackDrop: false,
        centered: true,
        theme: "light",
      },
      tokens: [
        { label: "VLG", value: "VLGTokenAvailable" },
        { label: "VUSD", value: "VLGCash" },
        { label: "VTP", value: "VLGPlus" },
      ],
      token: "VLGTokenAvailable",
    };
  },
  mounted() {},
  computed: {
    ...mapGetters({
      walletValue: "getWallet",
    }),
    walletAddress() {
      return this.walletValue && this.walletValue[this.token] && this.walletValue[this.token].WalletAddress
        ? this.walletValue[this.token].WalletAddress
        : ""
    },
    qrLink() {
      if (this.walletValue && this.walletValue[this.token] && this.walletValue[this.token].WalletAddress) {
        const query = {
          wallettype: this.token,
          address: this.walletValue[this.token].WalletAddress
        }
        const searchParams = new URLSearchParams(query);
        return homeUrl + '/app/wallets/transfers?' + searchParams
      }
    }
  }
};
</script>

<style scoped lang="scss">
.receive {
  width: 407px;
  color: #ffffff;
  margin-top: 32px;
  &__title {
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    color: #141822;
    text-align: center;
  }
  &__content {
    margin-top: 24px;
    .address {
      p {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #43464E;
        margin-bottom: 8px;
      }
      /deep/ {
        .form-control:disabled {
          background: #F7F7F7;
          border: 1px solid #E8E8E9;
          border-radius: 8px 0 0 8px;
          font-size: 16px;
          line-height: 24px;
          color: #43464E;
          border-right: none;
        }
        .input-group-text {
          background: #F7F7F7;
          border: 1px solid #E8E8E9;
          border-left: none;
          border-radius: 0 8px 8px 0;
        }

      }
    }
    .qr-img {
      width: 100%;
      border: 1px solid #adaeb2;
      border-radius: 8px;
      padding: 1rem;
      /deep/ canvas {
        width: 100%;
      }
    }
    .icon-save {
      margin-top: 24px;
      display: flex;
      justify-content: center;
      a {
        background: #f7f7f7;
        padding: 18px;
        border-radius: 100%;
        img {
          width: 28px;
        }
      }
    }
  }
}
::v-deep .base-select {
  margin-bottom: 20px;
  &__label {
    color: #43464e;
  }
}
::v-deep .base-select__wrap {
  background: #f7f7f7;
  .arrow-down {
    color: #43464e;
  }
}
::v-deep .base-select__inner {
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #141822;
  }
}
/deep/ .base-select__options {
  background: #ffffff;
  .base-select__item {
    position: relative;
    &.active {
      position: relative;
      &::before {
        display: block;
        content: "";
        width: 20px;
        height: 20px;
        background-image: url("/img/wallet/icon-check.svg");
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 16px;
      }
    }
    &:hover {
      background: #f7f7f7;
      color: #101828;
      text-decoration: none;
    }
    .base-select__link {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #101828;
      border: none;
    }
  }
}
</style>
