import repository from "./repository";

const resource = "/wallet";
const cashResource = "/vlg-cash";
const stockResource = "/vlg-ofs";
const plusResource = "/vlg-plus";
const tokenResource = "/vlg-token-available";

export default {
  checkFeeSwap(payload) {
    return repository.get(
      `${resource}/swap-fee/${payload.fromWallet}/${payload.toWallet}/${payload.amount}`
    );
  },
  swap(payload) {
    return repository.post(`${resource}/swap`, payload);
  },
  myWallet() {
    return repository.get(`${resource}/my-wallet`);
  },
  getHistoryByType(type, payload) {
    return repository.get(`${resource}/history/${type}`, {
      params: { ...payload },
    });
  },
  getUserWallet(userId, type) {
    return repository.get(`/user/${userId}/wallet-history/${type}`);
  },
  getMyCash() {
    return repository.get(`${cashResource}/balance`);
  },
  createWithdrawInvoice(amount) {
    return repository.post(`${cashResource}/withdraw?amount=${amount}`);
  },
  getWithdrawInvoice() {
    return repository.get(`${resource}/history/VLGCASH`);
  },
  getStockAvailable() {
    return repository.get(`${stockResource}/ofs-in-market`);
  },
  getMyStock() {
    return repository.get(`${stockResource}/balance`);
  },
  buyStock(vlgPlusAmount, pin) {
    return repository.post(`${stockResource}/buy`, { vlgPlusAmount, pin });
  },
  sellStock(VlgOFSAmount, pin) {
    return repository.post(`${stockResource}/sell`, { VlgOFSAmount, pin });
  },
  getOfsCertificateData() {
    return repository.get(`${resource}/ofs-certificate-data`);
  },
  getShareCertificateData() {
    return repository.get(`${resource}/share-certificate-data`);
  },
  getVmfCertificateData(id) {
    return repository.get(`user/${id}/vmf-certificate`);
  },
  transferWalletByType(type, payload) {
    return repository.post(`${type}/transfers`, payload);
  },
  fetchFeeWalletByType(type, payload) {
    return repository.get(`${type}/transaction-fee`, { params: payload });
  },
  fetchUsername(usernameOrWallet, walletType) {
    return repository.get(
      `${resource}/receiver-info/${usernameOrWallet}/${walletType}`
    );
  },
  convertVLGPToCash(vlgPlusAmount) {
    return repository.post(
      `${plusResource}/convert-to-cash?amount=${vlgPlusAmount}`
    );
  },
  convertOFSToVLGTokenBlock(payload) {
    return repository.post(
      `${stockResource}/convert-ofs-to-vlg-token`,
      payload
    );
  },
  withdrawVLG(payload) {
    return repository.post(`${tokenResource}/withdraw-to-coin`, payload);
  },
  getPools() {
    return repository.get(`api/pool/pool-info`);
  },
  convertVLGTokenToVIO(payload) {
    return repository.post(`${resource}/transfer-to-vio`, payload);
  },
  convertVLGTokenToLocal(payload) {
    return repository.post(`${tokenResource}/transfer-to-local`, payload);
  },
  SearchVioUser(searchString) {
    return repository.get(
      `${tokenResource}/search-vio-user?str=${searchString}`
    );
  },
  getTronWallet() {
    return repository.get(`${resource}/get-tron-wallet`);
  },
  createTronWallet() {
    return repository.post(`${resource}/create-tron-wallet`);
  },
  getLogTronWallet(payload) {
    return repository.get(`${resource}/get-vio-transfer-log`, {
      params: { ...payload },
    });
  },
  convertVTPToCash(amount) {
    return repository.post(`${plusResource}/convert-to-cash?amount=${amount}`);
  },
  convertVLGToVMM(pin, vlgAmount) {
    return repository.post(
      `${tokenResource}/convert-vlg-to-vmm?pin=${pin}&vlgAmount=${vlgAmount}`
    );
  },
  transferToVlinkPay(payload) {
    return repository.post(`${resource}/transfer-to-vlink-pay`, payload);
  },
  createLending(amount) {
    return repository.get(`/api/CreditLending/estimate?amount=${amount}`);
  },
  fetchAirdropBlock() {
    return repository.get(`/airdrop/blocked`);
  },
  getExchangeRate() {
    return repository.get(`${resource}/get-exchange-rate`);
  },
  getReleaseVmmHistories() {
    return repository.get(`/vmm/my-convert-histories`);
  },
  getReleaseVmmHistoriesLog(convertLogId) {
    return repository.get(`vmm/release-vmm-plant?convertLogId=${convertLogId}`);
  },
  getStakingConvertAmount() {
    return repository.get(`/vlg-token-block/staking-convert-amount`);
  },
  checkWallet(walletAddress) {
    return repository.get(
      `${resource}/check-valid-vlinkpay-wallet-address?walletAddress=${walletAddress}`
    );
  },
  checkAllowTransferVlinkPay() {
    return repository.get(`${tokenResource}/allow-transfer-to-vlinkpay`);
  },
};
