<template>
  <BaseModal :config="config" @hidden="resetForm">
    <template slot="content">
      <div class="register__title">
        {{$t('user.register')}}
      </div>
      <form @submit.prevent="submit" >
        <div class="register__content">
          <div class="register__content-top">
            <BaseInputCustom
              class-name="register__firstName"
              ref="firstName"
              v-model="formData.FirstName"
              type="text"
              name="firstName"
              required
              :label="$t('users.infor.first-name')"
            />
            <BaseInputCustom
              class-name="register__lastName"
              ref="lastName"
              v-model="formData.LastName"
              type="text"
              name="lastName"
              required
              :label="$t('users.infor.last-name')"
            />
          </div>
          <BaseInputCustom
            class-name="register__username"
            ref="username"
            v-model="formData.Username"
            type="text"
            name="username"
            required
            username-validate
            :label="$t('users.infor.username')"
          />
          <BaseInputCustom
            class-name="register__email"
            ref="email"
            v-model="formData.Email"
            type="email"
            name="email"
            required
            :label="$t('users.infor.email')"
          />
          <BaseInputCustom
            class-name="register__password"
            ref="password"
            v-model="formData.Password"
            type="password"
            name="password"
            required
            :label="$t('users.infor.password')"
            :max-length="50"
            :min-length="6"
            :limit-input="50"
          />
          <BaseInputCustom
            class-name="register__confirm-password"
            ref="confirmPassword"
            v-model="confirmPassword"
            :same-as="formData.Password"
            :error-same-as="$t('validate.isSame', {0 : $t('users.infor.password')})"
            type="password"
            name="confirmPassword"
            required
            :max-length="50"
            :min-length="6"
            :limit-input="50"
            :label="$t('user.confirm-password')"
          />
          <BaseSelect
            ref="type"
            :input-value="formData.Type"
            name="type"
            :label="$t('users.infor.account-type')"
            :items="accountTypes"
            text-field="label"
            value-field="value"
            :is-show-default="false"
            required
            key-loop="index"
            @change="formData.Type = $event"
          />
          <div class="register__content-top mt-4">
            <BaseInputCustom
              class-name="register__referralId"
              v-model="formData.ReferralId"
              type="text"
              name="ReferralId"
              :label="$t('user.sponsor')"
            />
            <BaseInputCustom
              class-name="register__referralName"
              v-model="referralName"
              v-if="referralName"
              type="text"
              name="ReferralName"
              :label="$t('users.infor.name')"
              disabled
            />
          </div>
          <BaseCheckbox
            v-model="isValid"
            :inline="true"
            class="register__agree"
          >
            <span class="label">{{$t('acceptPolicy.first')}}
              <a href="/terms" target="_blank">{{$t('acceptPolicy.second')}}</a> {{$t('acceptPolicy.third')}}
                <a href="/policy" target="_blank"> {{$t('acceptPolicy.forth')}}</a>
            </span>
          </BaseCheckbox>
        </div>
        <button
          class="btn btn-block btn-primary register__btn"
          type="submit"
          :class="{'button--loading': loading}"
        >
          <span>{{$t('user.register-button')}}</span>
        </button>
        <div class="register__redirect-sign">
          {{$t('regist.has-acc')}}
          <span @click="openLoginModal">{{$t('regist.sign-in-here')}}</span>
        </div>
      </form>

    </template>
  </BaseModal>
</template>

<script>
import {MODAL, NOTIFICATION_ICON} from "@/constants";
import BaseInputCustom from "@/components/Inputs/BaseInputCustom.vue"
import BaseCheckbox from "@/components/Inputs/BaseCheckbox.vue"
import BaseSelect from "@/components/Inputs/BaseSelect.vue"
import validateMixin from '@/core/mixins/validate'
import {reCaptchaKey} from "@/constants/config";
import { RepositoryFactory } from "@apis/repositoryFactory";
const AuthRepository = RepositoryFactory.get('auth')
const UserRepository = RepositoryFactory.get('user')
import {mapGetters} from 'vuex'
import debounce from 'lodash.debounce'

export default {
  name: "ModalRegister",
  components: {
    BaseInputCustom,
    BaseCheckbox,
    BaseSelect
  },
  mixins: [validateMixin],
  data() {
    return {
      config: {
        id: MODAL.REGISTER,
        class: 'modal--register',
        noCloseOnBackDrop: false,
        centered: true
      },
      loading: false,
      formData: {
        FirstName: '',
        LastName: '',
        Username: '',
        Password: '',
        Email: '',
        Type: 'AFFILIATE',
        ReferralId: '',
        ReCaptchaToken: reCaptchaKey
      },
      referralName: '',
      confirmPassword: '',
      isValid: false,
      accountTypes: [
        {
          label: 'AFFILIATE',
          value: 'AFFILIATE'
        },
        {
          label: 'CUSTOMER',
          value: 'CUSTOMER'
        },
        {
          label: 'BUSINESS',
          value: 'BUSINESS'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('Auth', {currentUser: 'currentUser'}),
    isFullFill () {
      return (
        !Object.values(this.formData).some(x => x === null || x === '') || this.isValid
      )
    }
  },
  watch: {
    '$route.query.vlinkId':{
      handler(val) {
        this.formData.ReferralId = val
      },
      immediate: true
    },
    'formData.ReferralId': {
      handler() {
        this.searchUser(this)
      },
      immediate: true
    }

  },
  methods: {
    searchUser: debounce(async (vm) => {
      vm.referralName = ""
      if (vm.formData.ReferralId) {
        try {
          const data = await UserRepository.findReferral(vm.formData.ReferralId)
          vm.referralName = data.Name
        } catch (e) {
          vm.$alertMsg('fail', e.response.data.message)
          vm.formData.ReferralId = ""
          vm.referralName = ""
        }
      }
    }, 1000),
    async submit () {
      const refs = ['username', 'password', 'email', 'firstName', 'lastName', 'confirmPassword', 'type', ]
      const refsValid = this.$_validateMixin_refs(refs)
      if (!refsValid) {
        return
      }
      if (!this.isValid) {
        this.$alertMsg('fail', this.$t('validate.isconfirm'))
        return
      }
      this.loading = true
      try {
        await AuthRepository.signUp(this.formData)
        this.$notifyPopup(NOTIFICATION_ICON.SUCCESS,
          this.$t('regist.thanks'),
          `${this.$t('regist.text-1')}. ${this.$t('regist.text-2')}`,
          {
            action1Text: 'OK',
            action1Func: () => {}
          }
        )
        this.$bvModal.hide(this.config.id)
        this.loading = false
      } catch (e) {
        this.loading = false
        this.$alertMsg('fail', e.response.data.message)
      }
    },

    openLoginModal() {
      this.$bvModal.show(MODAL.LOGIN)
      this.$bvModal.hide(MODAL.REGISTER)
    },
    resetForm() {
      const refs = ['username', 'password', 'email', 'firstName', 'lastName', 'confirmPassword', 'type', ]
      this.formData = {
        FirstName: '',
        LastName: '',
        Username: '',
        Password: '',
        Email: '',
        Type: 'AFFILIATE',
        ReferralId: ''
      }
      this.confirmPassword = ''
      this.isValid = false
      this.$_validateMixin_reset(refs)
      this.$store.commit('Auth/setRegisterSuccess', false)
      let query = Object.assign({}, this.$route.query);
      delete query.register;
      this.$router.replace({ query });
    },
  },
}
</script>

<style scoped lang="scss">
  .register {
  color: #FFFFFF;
  margin-top: 32px;
  &__title {
    color: #FFFFFF;
    font-weight: 700;
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    margin-bottom: 40px;
  }
  &__content {
    &-top {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .register__firstName, .register__lastName, .register__referralName, .register__referralId {
        width: calc((100% - 24px) / 2);
        @media only screen and (max-width: 768px) {
          width: calc((100% - 12px) / 2);
        }
      }

    }
  }
  &__agree {
    &.form-check {
      padding-left: 0;
      &::v-deep {
        .form-check-label {
          .form-check-sign::before {
            color: #20242D;
            font-size: 24px;
            background:   #20242D;
            height        : 24px;
            width         : 24px;
            border-radius: 4px;
            margin-left: -35px;
            margin-top: -18px;
          }
          .form-check-sign::after {
            content         : '\2713';
            color: #007BFF;
            height        : 24px;
            width         : 24px;
            font-size: 24px;
            margin-left: -35px;
            margin-top: -18px;
          }
        }
      }

    }
    .label {
      color: #FFFFFF;
    }
  }
  &__btn {
    margin-top: 30px;
    height: 40px;
  }
  &__reset-pass {
    display         : flex;
    justify-content : flex-end;
    margin-top: 16px;
    margin-bottom: 32px;
    span {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      text-transform : capitalize;
      color: #007BFF;
      cursor         : pointer;
      &:hover, &:focus {
        color : #ffffff;
      }
    }
  }
  &__redirect-sign {
    margin-top : 24px;
    text-align      : center;
    color           : #FFFFFF;
    display         : flex;
    justify-content : center;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    span {
      margin-left    : 5px;
      color          : #007BFF;
      &:hover, &:focus {
        text-decoration: underline;
        cursor         : pointer;
      }
    }
  }
}
</style>
