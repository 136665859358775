import { RepositoryFactory } from '@/apis/repositoryFactory'
const AssetRepository = RepositoryFactory.get('asset')
import { copyToClipboard, fixUrl } from '@/core/utils'
import QRCode from 'qrcode'

export default {
  methods: {
    copyLink(contents) {
      copyToClipboard(contents)
      this.$alertMsg("success", this.$t("action.copied-text"));
    },
    downloadQR(nameFile = 'qrcode-for-scan.png', id = null) {
      const opts = {
        errorCorrectionLevel: 'M',
        width: 600,
      };
      QRCode.toDataURL(id, opts, function (err, url) {
        if (err) throw err
        let link = document.createElement('a')
        link.href = url
        link.download = nameFile
        link.click()
      })
    },
    async uploadImage(event) {
      try {
        let data = new FormData()
        const file = event.target.files[0]
        data.append('file', file)
        const response = await AssetRepository.upload(data)
        return response.Photo
      } catch (error) {}
    },
    fixImageUrl(url) {
      return fixUrl(url)
    },
    async showQRcode (id = null) {
      let imageWrapper = document
      if (id) {
        imageWrapper = document.getElementById(id)
      }
      var image = imageWrapper.getElementsByTagName('canvas')[0].toDataURL()
      const html = `<div class="card-with-title">
      <h5 class="text-white text-center">QR Code:</h5><div class="input-group mb-3 align-items-center justify-content-center">
      <img src="${image}" class="mw-100" />
      </div></div></div>`;
      await this.$swal({
        customClass: {
          popup: 'bg-common',
        },
        html
      })
    }
  },
}
