<template>
  <form class="verification" @submit.prevent="submit">
    <div class="verification__title">
      {{ $t('security-email.index') }}
    </div>
    <div class="verification__input" v-if="hasGoogleVerification">
      <BaseInputCustom
        class-name="google-verification__verification-code"
        ref="googleVerificationCode"
        v-model="formData.GoogleCode"
        type="tel"
        name="code"
        required
        focus
        :min-length="6"
        :limit-input="6"
        :placeholder="$t('validate.verification-code', { 0: 6})"
        :label="$t('google.step-4.code')"
      />
    </div>
    <div class="verification__input" v-if="hasEmailVerification">
      <BaseInputCustom
        class-name="verification__verification-code"
        ref="emailVerificationCode"
        v-model="formData.EmailCode"
        type="tel"
        name="code"
        required
        focus
        :placeholder="$t('validate.verification-code', { 0: 6})"
        :error-required="$t('security-email.required')"
        :label="$t('security-email.code')"
      />
      <div class="verification__time">
        <a
          v-if="!counting"
          href="javascript:void(0)"
          @click="resendEmail">
          {{$t('security-email.re-send')}}
        </a>
        <countdown :time="59 * 1000" v-else @end="counting = false" class="text-right">
          <template slot-scope="props">{{ `${$t('security-email.time')}: ${props.seconds}` }} s</template>
        </countdown>
      </div>
    </div>
    <button
      class="btn btn-primary w-100"
      :class="{'button--loading': loading}"
    >
      <span>{{$t('action.submit')}}</span>
    </button>
    <div class="mt-3 justify-content-center d-flex">
      <a href="javascript:void(0)"  @click.prevent="$emit('back')">{{$t("action.back")}}</a>
    </div>
  </form>
</template>

<script>
import BaseInputCustom from "@components/Inputs/BaseInputCustom";
import { RepositoryFactory } from "@apis/repositoryFactory";
const AuthRepository = RepositoryFactory.get('auth')
import validateMixin from "@/core/mixins/validate";
import {mapGetters} from "vuex";

export default {
  components: {
    BaseInputCustom
  },
  mixins: [validateMixin],
  computed: {
    ...mapGetters('Auth', {authenticator: 'authenticator'}),
    authenticationTypes () {
      return this.authenticator || []
    },
    hasGoogleVerification() {
     return this.authenticationTypes.includes('google')
    },
    hasEmailVerification() {
      return this.authenticationTypes.includes('email')
    }
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      formData: {
        EmailCode: "",
        GoogleCode: "",
      },
      counting: true,
    }
  },
  methods: {
    resetData () {
      this.emailVerificationCode =  ''
       this.counting = true
    },
    async resendEmail () {
      this.$emit("resendEmail")
      this.counting = true
    },
    async submit () {
      let refs = []
      if (this.hasGoogleVerification) {
        refs.push("googleVerificationCode")
      }
      if (this.hasEmailVerification) {
        refs.push("emailVerificationCode")
      }
      const valid = this.$_validateMixin_refs(refs)
      if (valid) {
        this.$emit("submit", this.formData)
      }
    }
  },
}
</script>

<style scoped lang="scss">
.verification {
  font-family: 'Overpass';
  font-style: normal;
  padding-bottom: 32px;
  margin-top: 16px;
  &__title {
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 32px;
  }
  &__input {
    position: relative;
  }
  &__time {
    position: absolute;
    right: 0;
    top: 0;
    color: #FFFFFF;
    font-size: 12px;
    text-transform: capitalize;
  }
}
</style>
