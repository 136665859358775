<template>
  <div class="top-navbar">
    <div class="top-navbar__top">
      <router-link to="/" class="link-dashboard">
        <img src="/img/logo.webp" alt="logo" class="logo" />
      </router-link>
      <div class="action">
        <span
          :class="{ active: activeMenuProfile }"
          class="icon icon-menu-profile"
          @click="activeMenuProfile = !activeMenuProfile"
        >
        </span>
      </div>
    </div>
    <div
      class="top-navbar__bottom"
      :class="{ 'active-bottom': activeMenuProfile }"
    >
      <div
        v-if="
          $store.state.Auth.currentUser &&
          Object.keys($store.state.Auth.currentUser).length
        "
        class="user-logged"
      >
        <div class="shop-cart" @click="redirectShoppingCart">
          <img src="/img/header/cart.svg" alt="cart" class="cart" />
          <span class="number-cart">
            {{ numberMyCart }}
          </span>
        </div>
        <div class="information">
          <img @click="activeMenu = !activeMenu" :src="avatarPath" alt="avatar" class="avatar" onerror="this.onerror=null;this.src='/img/header/avatar.png'"/>
          <div @click="activeMenu = !activeMenu" class="name">
            <span style="width: 85px;">{{
              $store.state.Auth.currentUser.FirstName +
              "" +
              $store.state.Auth.currentUser.LastName
            }}</span>
          </div>
          <div @click="activeMenu = !activeMenu" class="arrow-down"></div>
          <div class="menu-profile" :class="{ active: activeMenu }">
            <div class="list-menu">
              <div class="icon-arrow-shadow">
                <div class="triangle-with-shadow"></div>
              </div>
              <div class="item d-flex justify-content-center flex-column">
                <router-link :to="`/shop?tab=MyPackage`" class="text-center" v-if="!!currentUser.ImagePath">
                  <img
                    width="110px"
                    height="auto"
                    :src="$apiImageUrl + currentUser.ImagePath"
                    class="packages-thumnails mt-2"
                    :alt="currentUser.PackageName"
                  />
                </router-link>
                <a href="javascript:void(0)" class="mt-3 text-center" v-if="isVMF" @click="$bvModal.show(MODAL.VMF_CERTIFICATE)">{{ $t("view-cer") }}</a>
                <p class="m-3 text-center h5 text-body" v-if="!!currentUser.Title">{{ currentUser.Title }}</p>
              </div>
              <div
                class="item"
                v-for="menuItem in dashBoardProfileMenu"
                :key="menuItem.label"
              >
                <a
                  @click="openLink(menuItem.path)"
                  class="nav-link"
                  :class="{ 'active-user': $route.path === menuItem.path }"
                >
                  <span class="name">{{
                    $t(`menu-items.profiles.${menuItem.label}`)
                  }}</span>
                </a>
              </div>
              <div class="item">
                <a class="nav-link log-out" @click="logout()">
                  <span class="name">{{ $t("logout") }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="language-selector">
          <select
            v-model="langSelected"
            class="language-selector__select"
            @change="changeLocale"
          >
            <option
              v-for="(l, index) in localeOptions"
              :key="index"
              :value="l.id"
            >
              {{ l.id.toUpperCase() }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <ModalCertificate v-if="isVMF" />
  </div>
</template>

<script>
import SideBarMB from "@/layout/mobile/SideBarMB";
import MENU, { MENU_POSITION } from "@/constants/menu";
import { apiImageUrl, localeOptions } from "@/constants/config";
import { RepositoryFactory } from "@apis/repositoryFactory";
const ShopRepository = RepositoryFactory.get("cart");
import { mapActions } from "vuex";
import { MODAL } from "@/constants";
export default {
  name: "TopNavbarMb",
  components: {
    SideBarMB,
    ModalCertificate: () => import("@/containers/Wallet/modal-vmf-certificate.vue")
  },
  data() {
    return {
      activeMenuProfile: true,
      activeSidebar: false,
      activeMenu: false,
      langSelected: this.$i18n.locale,
      localeOptions,
      MODAL,
    };
  },
  computed: {
    avatarPath() {
      return this.currentUser && this.currentUser.Avatar
        ? apiImageUrl + this.currentUser.Avatar
        : "/img/header/avatar.png";
    },
    numberMyCart() {
      return this.$store.state.myCart || 0;
    },
    dashBoardProfileMenu() {
      return MENU.filter((i) =>
        i.position.includes(MENU_POSITION.DASHBOARD_PROFILE_MENU)
      );
    },
    isVMF () {
      return this.currentUser && this.currentUser.HasCertificate
    }
  },
  methods: {
    ...mapActions("Auth", {
      signOut: "signOut",
    }),
    ...mapActions(["setLang"]),
    openLink (link) {
      this.activeMenu = !this.activeMenu
      this.$router.push(link)
    },
    logout() {
      this.signOut();
    },
    changeLocale(e) {
      // this.setLang(e.target.value);
      this.$store.commit("changeLang", e.target.value)
      location.reload()
    },
    redirectShoppingCart() {
      document.getElementsByTagName("html")[0].classList.remove("modal-open");
      if (this.$route.path !== "/shopping-cart") {
        this.$store.commit("setRouteBack", this.$route.path);
      }
      this.$router.push("/shopping-cart");
    },
  },
};
</script>

<style scoped lang="scss">
.top-navbar {
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #152b4b;
    height: 72px;
    padding: 0 16px;
    z-index: 2;
    position: relative;
    .link-dashboard {
      img {
        width: 152px;
        height: 48px;
      }
    }
    .action {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .icon {
        font-size: 30px;
        &.icon-menu-mb {
          padding-right: 10px;
        }
        &.icon-menu-profile {
          padding-left: 10px;
        }
        &.active {
          &:before {
            color: #ffffff;
          }
        }
      }
    }
  }
  &__bottom {
    transition: all 0.5s;
    margin-top: -68px;
    opacity: 0;
    visibility: hidden;
    .user-logged {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 68px;
      background: #ffffff;
      box-shadow: 0 1px 25px rgba(0, 0, 0, 0.1);
      padding: 0 16px;
      .shop-cart {
        margin-right: 24px;
        position: relative;
        img {
          width: 24px;
          height: 24px;
          filter: contrast(0);
        }
        .number-cart {
          background: #007bff;
          border: 2px solid #ffffff;
          width: 17px;
          height: 17px;
          position: absolute;
          top: -5px;
          right: -5px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          color: #ffffff;
          font-size: 9px;
          line-height: 9px;
        }
      }
      .information {
        display: flex;
        justify-content: flex-start;
        align-content: center;
        position: relative;
        .avatar {
          width: 32px;
          height: 32px;
          margin-right: 16px;
          border-radius: 50%;
        }
        .name {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 15px;
          span {
            white-space: nowrap;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #141822;
            max-width: 120px;
            text-overflow: ellipsis;
            overflow: hidden;
            display: inline-block;
          }
        }
        .arrow-down {
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 10px solid #43464e;
          margin-top: 10px;
        }
        .menu-profile {
          position: absolute;
          left: -75px;
          top: 55px;
          width: 330px;
          visibility: hidden;
          opacity: 0;
          z-index: 10;
          transition: all linear 0.2s;
          .list-menu {
            position: relative;
            background: #ffffff;
            border-radius: 8px;
            padding: 0;
            margin: 0;
            box-shadow: 0 0 10px #43464e;
            .icon-arrow-shadow {
              position: absolute;
              top: -18px;
              left: 170px;
              .triangle-with-shadow {
                width: 25px;
                height: 25px;
                position: relative;
                overflow: hidden;
                box-shadow: 0 16px 10px -17px #ffffff;
              }
              .triangle-with-shadow:after {
                content: "";
                position: absolute;
                width: 25px;
                height: 25px;
                background: #ffffff;
                transform: rotate(45deg);
                top: 10px;
                left: 0;
                box-shadow: -1px -1px 10px -2px #43464e;
              }
            }
            .item {
              list-style: none;
              .nav-link {
                padding: 12px 24px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                height: 100%;
                span.name {
                  font-size: 16px;
                  line-height: 24px;
                  color: #43464e;
                  display: inline-block;
                  white-space: nowrap;
                  margin-right: 0;
                }
                &:hover,
                &.active-user {
                  span.name {
                    color: #007bff;
                  }
                }
                &.log-out {
                  span.icon {
                    &:before {
                      color: #e96058;
                    }
                  }
                  span.name {
                    color: #e96058;
                  }
                  &:hover {
                    cursor: pointer;
                    span.icon {
                      &:before {
                        color: #e96058;
                      }
                    }
                    span.name {
                      color: #e96058;
                    }
                  }
                }
              }
            }
          }
          &.active {
            opacity: 1;
            visibility: visible;
          }
        }
      }
      .language-selector {
        overflow: hidden;
        background: rgba(0, 123, 255, 0.2);
        border-radius: 8px;
        padding: 6px 12px;
        margin-left: 30px;
        select {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #007bff;
          text-indent: 1px;
          text-overflow: "";
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          padding: 2px 20px 2px 2px;
          border: none;
          background: transparent url("/img/arrow-down.svg") no-repeat 28px
            center;
          &:focus-visible {
            outline: none;
          }
          option {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #007bff;
            background: rgba(0, 123, 255, 0.2);
            border: none;
          }
        }
      }
    }
    &.active-bottom {
      margin-top: 0;
      opacity: 1;
      visibility: visible;
    }
  }
}
</style>
