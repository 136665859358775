import auth from './auth'
import user from './user'
import bank from './bank'
import asset from './asset'
import shop from './shop'
import cart from './cart'
import kyc from './kyc'
import wallet from './wallet'
import overview from './overview'
import commission from './commission'
import home from './home'
import merchant from './merchant'
import lending from './lending'
import network from './network'
import event from "./event";

const repositories = {
  auth,
  user,
  bank,
  asset,
  shop,
  cart,
  kyc,
  wallet,
  overview,
  commission,
  home,
  merchant,
  lending,
  network,
  event
}

export const RepositoryFactory = {
  get: name => repositories[name],
  post: name => repositories[name],
  put: name => repositories[name],
  delete: name => repositories[name]
}
