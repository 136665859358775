<template>
  <BaseModal :config="config" @hidden="resetData">
    <template slot="content">
      <div class="confirmation-email__title mb-3">
        {{$t('user.resend-email-question')}}
      </div>
      <div class="confirmation-email__desc">
        {{$t('forms.verify-mail')}}
      </div>
      <div class="confirmation-email__content mt-4">
        <BaseInputCustom
          class-name="confirmation-email__email"
          ref="email"
          v-model="formData.Email"
          type="email"
          name="email"
          required
          :label="$t('user.email')"
        />
      </div>
      <button
        class="btn btn-block btn-primary confirmation-email__btn"
        :class="{'button--loading': loading}"
        @click="submit"
      >
        <span>{{$t('action.resend')}}</span>
      </button>
    </template>
  </BaseModal>
</template>

<script>
import {MODAL, NOTIFICATION_ICON} from "@/constants";
import {mapActions} from "vuex";
import BaseInputCustom from "@components/Inputs/BaseInputCustom";
import validateMixin from "@/core/mixins/validate";
import { RepositoryFactory } from "@apis/repositoryFactory";
const AuthRepository = RepositoryFactory.get('auth')

export default {
  components: {
    BaseInputCustom
  },
  mixins: [validateMixin],
  data() {
    return {
      config: {
        id: MODAL.CONFIRMATION_EMAIL,
        class: 'modal--confirmation-email',
        noCloseOnBackDrop: false,
        centered: true
      },
      formData: {
        Email: ''
      },
      loading: false
    }
  },
  methods: {
    async submit() {
      const refs = ['email']
      const refsValid = this.$_validateMixin_refs(refs)
      if (refsValid) {
        this.loading = true
        try {
          await AuthRepository.resendEmail(this.formData.Email)
          this.$notifyPopup(NOTIFICATION_ICON.SUCCESS,
            this.$t('swal.title.sent'),
            this.$t('security-email.sent'),
            {
              action1Text: 'OK',
              action1Func: () => {}
            }
          )
        } catch (e) {
          this.$alertMsg('fail', e.response.data.message)
        }
        this.loading = false
      }
    },
    resetData () {
      this.loading = false
      this.formData = {
        Email: ""
      }
    }
  },
}
</script>

<style scoped lang="scss">
.confirmation-email {
  color: #FFFFFF;
  margin-top: 32px;

  &__title {
    color: #FFFFFF;
    font-weight: 700;
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    margin-bottom: 40px;
  }
  &__desc {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #ADAEB2;
    text-align: center;
  }
  &__btn {
    margin-top: 24px;
  }
}
</style>
