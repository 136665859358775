import repository from './repository'

const resource = '/network-tree'

export default {
    getPoolHistory(payload) {
        return repository.get(`/api/pool/pool-bonus-history`, { params: payload })
    },
    getDirectHistory(payload) {
        return repository.get(`/commissions/direct-history`, { params: payload })
    },
    getUserRanking(payload) {
        return repository.get(`${resource}/children-user-have-ranking`, { params: { ...payload } })
    },
    getUserRedeemed(payload) {
        return repository.get(`${resource}/children-user-have-redeem`, { params: { ...payload } })
    },
    createMember(payload) {
    return repository.post(`/user/add-affiliate-member`, payload)
  },
}