export const IMAGE_TYPE = {
  BANNER: 'Banner',
  NEWS: 'News',
  EVENT: 'Event',
}

export const MODAL = {
  LOGIN: "modal-login",
  UPDATE_INFO: "modal-update-info",
  RESET_PASSWORD: "modal-reset-pw",
  REGISTER: "modal-register",
  FORGOT_PASSWORD: "modal-forgot-pw",
  FORGOT_USERNAME: "modal-forgot-username",
  CREATE_ACCOUNT: "modal-create-account",
  CREATE_QR: "modal-create-qr",
  TRANSFER: "modal-transfer",
  TRANSFER_HOME: "modal-transfer-home",
  GOOGLE_AUTHENTICATION: "modal-google-authentication",
  EMAIL_VERIFICATION_CONFIRMATION: "modal-email-verification-confirmation",
  GOOGLE_VERIFICATION_CONFIRMATION: "modal-google-verification-confirmation",
  PIN_SECURITY_CONFIRMATION: "modal-pin-security-confirmation",
  WITHDRAW: "modal-withdraw",
  TRANSFER_VIO: "modal-trasfer-vio",
  CERTIFICATE: "modal-certificate",
  CONVERT_TO_CASH: "modal-convert-to-cash",
  CONVERT_TO_VMM: "modal-convert-to-vmm",
  CREATE_LENDING: "modal-create-lending",
  TRANSFER_TRON: "modal-trasfer-tron",
  SHARE_BONUS: "modal-share-bonus",
  RECEIVE: "modal-receive",
  CONFIRMATION_EMAIL: "modal-confirmation-email",
  UPLOAD_DOCUMENTS: "modal-upload_documents",
  REPAY_DETAIL: "modal-repay-detail",
  REPAY: "modal-repay",
  PAYMENT: "modal-payment",
  VMF_CERTIFICATE: "modal-vmf-certificate",
  SWAP: "modal-swap",
  REQUEST_LINKKYC: "modal-kyc-request",
  EMAIL: "modal-update-email",
  PIN_VERIFICATION: "modal-pin-verification"
}
export const NOTIFICATION_ICON = {
  WARNING: 'warning',
  ERROR: 'error',
  SUCCESS: 'success'
}

export const PAYMENT_METHOD = {
  CRYPTO: 'CRYPTO',
  CREDIT_CARD:'CREDIT_CARD'
}

export const STATUS_COLOR = {
  OPEN : 'primary',
  REVIEW : 'primary',
  PENDING : 'secondary',
  REJECTED : 'danger',
  APPROVED : 'success',
  ACTIVE : 'success',
  WAITING : 'success',
  INACTIVE : 'warning',
  DELETED : 'info',
  LOCK : 'dark'
}

export const CRYPTO_LIST = [
  {
    index: 0,
    label: 'VLG Token',
    name: 'VLG',
    src: '/assets/img/icon-wallet/vlg.png',
    value: 'VLGTokenAvailable',
    transaction: '10'
  },
  {
    index: 1,
    label: 'VTP Token',
    name: 'VTP',
    src: '/assets/img/icon-wallet/vtp.png',
    value: 'VLGPlus',
    transaction: '3'
  },
  {
    index: 2,
    label: 'VUSD Token',
    name: 'VUSD',
    src: '/assets/img/icon-wallet/vusd.png',
    value: 'VLGCash',
    transaction: '3'
  },
  {
    index: 3,
    label: 'VLG Gold Token',
    name: 'GOLD',
    src: '/assets/img/icon-wallet/vlg-gold.png',
    value: 'VLGGold',
    transaction: '25'
  },
  {
    index: 4,
    label: 'USDT (TRC20)',
    name: 'USDT',
    src: '/img/payment/usdt.png',
    transaction: '0',
    invisible: true,
    value: 'Usdt',
    otherCryptoCurrency: true
  },
]
export const PAYMENT_METHODS =  [
  {
    index: 1,
    label: 'VTP Token',
    name: 'VTP',
    logo: '/assets/img/icon-wallet/vtp.png',
    value: 'VLGPlus',
    transaction: '3',
    showEquivalentBalance: true
  },
  {
    index: 2,
    label: 'VUSD Token',
    name: 'VUSD',
    logo: '/assets/img/icon-wallet/vusd.png',
    value: 'VLGCash',
    transaction: '3',
    showEquivalentBalance: true
  },
  {
    index: 3,
    label: 'VLG Gold Token',
    name: 'GOLD',
    logo: '/assets/img/icon-wallet/vlg-gold.png',
    value: 'VLGGold',
    transaction: '25',
    showEquivalentBalance: true
  },
  // {
  //   index: 0,
  //   label: 'VLG Token',
  //   name: 'VLG',
  //   logo: '/assets/img/icon-wallet/vlg.png',
  //   value: 'VLGTokenAvailable',
  //   transaction: '10',
  //   showEquivalentBalance: true
  // },
  {
    index: 4,
    label: 'USDT (TRC20)',
    name: 'USDT',
    logo: '/img/payment/usdt.png',
    transaction: '0',
    value: 'Usdt_trc20',
    showEquivalentBalance: false
  },
  {
    index: 6,
    label: 'USDT (VRC20)',
    name: 'USDT',
    logo: '/img/payment/usdt.png',
    transaction: '0',
    value: 'Usdt',
    tag: '/img/payment/label-usdt-vrc20.svg',
    showEquivalentBalance: true
  },
  {
    index: 5,
    label: 'Credit Card',
    name: 'CreditCard',
    logo: '/img/payment/credit.png',
    transaction: '0',
    invisible: true,
    value: 'CreditCard',
    showEquivalentBalance: false
  }
]

export const TITLE = [
  {
    title: "agent",
    icon: "/img/title/thumb/agent.v2.png",
    description: "badge.agent.description",
    rankingDescription: "badge.agent.ranking-description"
  },
  {
    title: "supervisor",
    icon: "/img/title/thumb/supervisor.v2.png",
    description: "badge.supervisor.description",
    condition:  "badge.supervisor.condition",
    rankingDescription: "badge.supervisor.ranking-description",
    rankingCondition:  "badge.supervisor.ranking-condition",
  },
  {
    title: "manager",
    icon: "/img/title/thumb/manager.v2.png",
    description: "badge.manager.description",
    condition:  "badge.manager.condition",
    rankingDescription: "badge.manager.ranking-description",
    rankingCondition:  "badge.manager.ranking-condition",
  },
  {
    title: "regional manager",
    icon: "/img/title/thumb/regional-manager.v2.png",
    description: "badge.regional-manager.description",
    condition:  "badge.regional-manager.condition",
    rankingDescription: "badge.regional-manager.ranking-description",
    rankingCondition:  "badge.regional-manager.ranking-condition",
  },
  {
    title: "state manager",
    icon: "/img/title/thumb/state-manager.v2.png",
    description: "badge.state-manager.description",
    condition:  "badge.state-manager.condition",
    rankingDescription: "badge.state-manager.ranking-description",
    rankingCondition:  "badge.state-manager.ranking-condition",
  },
  {
    title: "director",
    icon: "/img/title/thumb/director.v2.png",
    description: "badge.director.description",
    condition:  "badge.director.condition",
    rankingDescription: "badge.director.ranking-description",
    rankingCondition:  "badge.director.ranking-condition",
  },
  {
    title: "global director",
    icon: "/img/title/thumb/global-director.v2.png",
    description: "badge.global-director.description",
    condition:  "badge.global-director.condition",
    rankingDescription: "badge.global-director.ranking-description",
    rankingCondition:  "badge.global-director.ranking-condition",
  },
  {
    title: "chairman",
    icon: "/img/title/thumb/chairman.v2.png",
    description: "badge.chairman.description",
    condition:  "badge.chairman.condition",
    rankingDescription: "badge.chairman.ranking-description",
    rankingCondition:  "badge.chairman.ranking-condition",
  },
]
export const WALLET_TRANSFER = [
  {
    url: "/vlg-cash",
    value: 'VLGCash',
    label: 'VUSD'
  },
  {
    url:  "/vlg-token-available",
    value: 'VLGTokenAvailable',
    label: 'VLG'
  },
  {
    url: "/vlg-plus",
    value: 'VLGPlus',
    label: 'VTP'
  },
]
export const END_PRE_ORDER_ROUND_1 = '2022-08-06T00:00:00'
export const END_PRE_ORDER_ROUND_2 = '2022-08-06T00:00:00'
export const LUCKY_EVENT_ROUND_1 = '2022-08-06T00:00:00'
export const LUCKY_EVENT_ROUND_2 = '2022-08-06T01:00:00'
export const TYPE_FOR_SEARCH_NETWORK_TREE = ['username', 'phone', 'email']
export const L1 = [
  {
    no: "1 to 10 packages",
    price: "$80,000",
    ap: "28,000 AP",
    promotion: "12,000"
  },
  {
    no: "11 to 20 packages",
    price: "$85,000",
    ap: "29,750 AP",
    promotion: "12,000"
  },
  {
    no: "21 to 30 packages",
    price: "$90,000",
    ap: "31,500 AP",
    promotion: "12,000"
  },
  {
    no: "31 to 40 packages",
    price: "$95,000",
    ap: "33,250 AP",
    promotion: "12,000"
  },
  {
    no: "41 to 50 packages",
    price: "$100,000",
    ap: "35,000 AP",
    promotion: "12,000"
  }
]
export const EB3 = [
  {
    no: "1 to 10 packages",
    price: "$100,000",
    ap: "35,000 AP",
    promotion: "14,500"
  },
  {
    no: "11 to 20 packages",
    price: "$105,000",
    ap: "36,750 AP",
    promotion: "14,500"
  },
  {
    no: "21 to 30 packages",
    price: "$110,000",
    ap: "38,500 AP",
    promotion: "14,500"
  },
  {
    no: "31 to 40 packages",
    price: "$115,000",
    ap: "40,250 AP",
    promotion: "14,500"
  },
  {
    no: "41 to 50 packages",
    price: "$120,000",
    ap: "42,000 AP",
    promotion: "14,500"
  }
]
export const EB5 = [
  {
    no: "1 to 5 packages",
    price: "$225,000",
    ap: "78,750 AP",
    promotion: "32,500"
  },
  {
    no: "6 to 10 packages",
    price: "$235,000",
    ap: "82,250 AP",
    promotion: "32,500"
  },
  {
    no: "11 to 15 packages",
    price: "$245,000",
    ap: "85,750 AP",
    promotion: "32,500"
  },
  {
    no: "16 to 20 packages",
    price: "$255,000",
    ap: "89,250 AP",
    promotion: "32,500"
  }
]
export const MERCHANT = [
  {
    no: "1 to 50 packages",
    dividend: "100%",
    price: "$5,555",
    ap: "5,000",
    promotion: "100 VLG + 200 VLGS"
  },
  {
    no: "51 to 105 packages",
    dividend: "95%",
    price: "$5,555",
    ap: "5,000",
    promotion: "100 VLG + 200 VLGS"
  },{
    no: "106 to 155 packages",
    dividend: "90%",
    price: "$5,555",
    ap: "5,000",
    promotion: "100 VLG + 200 VLGS"
  },{
    no: "156 to 205 packages",
    dividend: "85%",
    price: "$5,555",
    ap: "5,000",
    promotion: "100 VLG + 200 VLGS"
  },{
    no: "206 to 255 packages",
    dividend: "80%",
    price: "$5,555",
    ap: "5,000",
    promotion: "100 VLG + 200 VLGS"
  },{
    no: "256 to 305 packages",
    dividend: "70%",
    price: "$5,555",
    ap: "5,000",
    promotion: "100 VLG + 200 VLGS"
  },{
    no: "306 to 355 packages",
    dividend: "60%",
    price: "$5,555",
    ap: "5,000",
    promotion: "100 VLG + 200 VLGS"
  },{
    no: "356 to 405 packages",
    dividend: "50%",
    price: "$5,555",
    ap: "5,000",
    promotion: "100 VLG + 200 VLGS"
  },{
    no: "406 to 455 packages",
    dividend: "45%",
    price: "$5,555",
    ap: "5,000",
    promotion: "100 VLG + 200 VLGS"
  },{
    no: "456 to 555 packages",
    dividend: "40%",
    price: "$5,555",
    ap: "5,000",
    promotion: "100 VLG + 200 VLGS"
  },
]

export const LINK_KYC_TYPE = {
  LINKING: 'LINKING',
  UNAVAILABLE: 'UNAVAILABLE',
  APPROVED: 'APPROVED',
}