import repository from './repository'

// const resource = '/user'
const resource = '/api/auth'

export default {
  login(payload) {

    return repository.post(`${resource}/login`, payload )
  },

  logout() {
    return repository.post(`${resource}/log-out`, {} )
  },

  forgotPassword(payload) {
    return repository.post(`${resource}/forgot-password`, payload)
  },

  forgotUsername(payload) {
    return repository.post(`${resource}/forgot-username`, JSON.stringify(payload))
  },

  signUp(payload) {
    return repository.post(`${resource}/register`, payload)
  },

  resetPassword(token, payload) {
    return repository.put(`${resource}/reset-password?token=${token}`, payload)
  },
  confirmRegister(token) {
    return repository.put(`${resource}/confirm-register?token=${token}`, {})
  },
  confirmChangeInfo(token, userLogId) {
    return repository.put(`${resource}/confirm-change-user-info?token=${token}&userLogId=${userLogId}`, {})
  },
  resendEmail(payload) {
    return repository.post(`${resource}/resend-verify-email`, JSON.stringify(payload))
  },

  googleAuth2FA() {
    return repository.post(`${resource}/enable-google-auth`, {})
  },

  googleAuth2FAConfirm(code) {
    return repository.post(`${resource}/confirm-enable-google-auth/${code}`, {})
  },

  emailAuth2FAConfirm(code) {
    return repository.post(`${resource}/confirm-enable-email-auth/${code}`, {})
  },

  disable2FA (payload) {
    return repository.post(`${resource}/disable-two-factor-auth`, payload)
  },

  sendCodeDisable2FAForEmail () {
    return repository.post(`${resource}/send-code-disable-email-auth`, {})
  },

  getCodeEnable2FAForEmail () {
    return repository.post(`${resource}/enable-email-auth`, {})
  }

}
