import repository from './repository'

const resource = '/api/CreditLending'

export default {
  lending(payload) {
    return repository.post(`${resource}`, payload )
  },
  getlending () {
    return repository.get(`${resource}`)
  },
  refundlending (payload) {
    return repository.post(`${resource}/monthly-interest/${payload.MonthlyInterestId}/debt-payment`, payload)
  },
  refundLendingOther(payload) {
    return repository.post(`${resource}/monthly-interest/${payload.PaymentCode}/help-debt-payment`, payload)
  },
}