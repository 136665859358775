export const appRoot = '/app'
export const homeUrl = 'https://test.vlinknet.net'
export const apiUrl = 'https://test-api.vlinknet.net/'
// export const apiUrl = 'http://localhost:5005'
export const apiImageUrl = 'https://test-api.vlinknet.net/Resources/'
export const reCaptchaKey = '6Lf7LeMaAAAAAIFZ2Ub7Cr5nkXlfILjhHNebEnu2'
export const googleServicesKey = 'AIzaSyChkQTr5zrDpUq1l41-jYcYjwWePcPxgDc'

export const defaultLocale = 'en'
export const localeOptions = [
  { id: 'en', name: 'English' },
  { id: 'vi', name: 'Tiếng Việt' }
]
