

export default {
  'question-1': 'Đăng ký tài khoản mới và xác thực email',
  'answer-1': `
<iframe
                width="100%"
                height="370px"
                src="https://youtube.com/embed/GZIg7iFJdEE"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
<p>1. Truy cập Trang chủ <a data-fr-linked="true" href="https://vlinkmeta.com/">VLINKMETA</a></p>
<p>2. Click chọn Đăng K&yacute;</p>
<p>3. Nhập c&aacute;c th&ocirc;ng tin c&aacute; nh&acirc;n, bao gồm: Họ, T&ecirc;n, T&ecirc;n đăng nhập (hiển thị trong hệ thống), Email v&agrave; nhập Mật khẩu 2 lần.</p>
<p>4. Chọn loại t&agrave;i khoản, c&oacute; 3 loại t&agrave;i khoản kh&aacute;c nhau tương ứng với c&aacute;c đặc điểm v&agrave; quyền lợi kh&aacute;c nhau, chi tiết tham khảo FAQ của VLINKMETA</p>
<p>5. Nhập m&atilde; của người giới thiệu nếu có &nbsp;</p>
<p>6. X&aacute;c nhận rằng bạn đ&atilde; đọc v&agrave; chấp nhận <a data-fr-linked="true" href="https://vlinkmeta.com/terms">Điều khoản sử dụng</a> v&agrave; <a data-fr-linked="true" href="https://vlinkmeta.com/policy">Ch&iacute;nh s&aacute;ch bảo mật</a> của VLINKMETA trước khi ho&agrave;n tất đăng k&yacute; t&agrave;i khoản</p>
<p>7. Hệ thống sẽ th&ocirc;ng b&aacute;o Đăng k&yacute; th&agrave;nh c&ocirc;ng, đồng thời gửi email x&aacute;c thực t&agrave;i khoản đến địa chỉ mail đ&atilde; đăng k&yacute;.</p>
<p>8. Kiểm tra hộp thư của email đ&atilde; đăng k&yacute; v&agrave; click v&agrave;o Confirm (X&aacute;c nhận)</p>
<p>9. Hệ thống tự động chuyển trang về website của VLINKMETA, th&ocirc;ng b&aacute;o t&agrave;i khoản của bạn đ&atilde; đăng k&yacute; th&agrave;nh c&ocirc;ng v&agrave; sẵn s&agrave;ng để đăng nhập</p>`,

  'question-2': 'Đăng nhập',
  'answer-2': `
<iframe
                width="100%"
                height="370px"
                src="https://youtube.com/embed/y9-glYASVCs"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
<ol>
    <li>Truy cập Trang chủ <a data-fr-linked="true" href="https://vlinkmeta.com/">VLINKMETA</a></li>
    <li>Click chọn Đăng nhập</li>
    <li>Nhập c&aacute;c th&ocirc;ng tin t&agrave;i khoản: T&ecirc;n người d&ugrave;ng v&agrave; mật khẩu</li>
    <li>X&aacute;c nhận</li>
</ol>`,
  'question-3': 'Quên mật khẩu',
  'answer-3': `
<iframe
                width="100%"
                height="370px"
                src="https://youtube.com/embed/2ZuHoWLV-NA"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
<ol>
    <li>Truy cập Trang chủ <a data-fr-linked="true" href="https://vlinkmeta.com/">VLINKMETA</a></li>
    <li>Click chọn Đăng nhập</li>
    <li>Click v&agrave;o Qu&ecirc;n mật khẩu</li>
    <li>Nhập email hoặc username của bạn v&agrave; x&aacute;c nhận &rArr; Hệ thống sẽ gửi 1 email để lấy lại mật khẩu</li>
    <li>Kiểm tra hộp mail v&agrave; mở mail của hệ thống</li>
    <li>Click v&agrave;o button Choose a new password</li>
    <li>Tạo mật khẩu mới v&agrave; x&aacute;c nhận</li>
    <li>Hệ thống chuyển trang về Trang chủ, b&acirc;y giờ bạn c&oacute; thể đăng nhập với mật khẩu mới tạo.</li>
</ol>`,
  'question-4': 'Tạo QR code',
  'answer-4': `
<p>Chỉ áp dụng cho tài khoản Business và Affiliate đã kích hoạt gói từ 1000 VUSD trở lên / Available only for Business accounts and Affiliate ones activating package from 1000 VUSD</p>
  <iframe
                width="100%"
                height="370px"
                src="https://youtube.com/embed/xT03VHDuLH8"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
  'question-5': 'Chuyển tiền nội bộ và ngoài hệ thống VLINKMETA',
  'answer-5': `
<p>1. Chuyển tiền nội bộ VLINKMETA v&agrave; người nhận kiểm tra số dư đ&atilde; nhận th&agrave;nh c&ocirc;ng hay chưa:</p>
<iframe
                width="100%"
                height="370px"
                src="https://youtube.com/embed/gMfCYhCP8o8"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
<p>2. Chuyển tiền sang nền tảng VLGTOKEN.COM</p>
<iframe
                width="100%"
                height="370px"
                src="https://youtube.com/embed/KraSKrbY4nk"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
`,
  'question-6': 'Mua và kích hoạt 1 gói',
  'answer-6': `
<p>1. Mua v&agrave; thanh to&aacute;n bằng Credit card:</p>
<iframe
                width="100%"
                height="370px"
                src="https://youtube.com/embed/8p-BmqmjsWs"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
<p>2. Mua v&agrave; thanh to&aacute;n bằng c&aacute;c loại v&iacute; của VLINKMETA:</p>
<iframe
                width="100%"
                height="370px"
                src="https://youtube.com/embed/wVBFysJe9nI"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
<p>3. C&aacute;ch xem lịch sử giao dịch (danh s&aacute;ch c&aacute;c g&oacute;i đ&atilde; mua) v&agrave; c&aacute;c g&oacute;i đ&atilde; k&iacute;ch hoạt th&agrave;nh c&ocirc;ng:&nbsp;</p>
<iframe
                width="100%"
                height="370px"
                src="https://youtube.com/embed/t68Dv7pcu1g"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
<p>4. C&aacute;ch k&iacute;ch hoạt 1 g&oacute;i:</p>
<iframe
                width="100%"
                height="370px"
                src="https://youtube.com/embed/CLTAcFDAyPk"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  `,
  'question-7': 'Xem số dư các loại ví (tương ứng với từng loại tài sản kỹ thuật số)',
  'answer-7': `
  <iframe
                width="100%"
                height="370px"
                src="https://youtube.com/embed/StpHRvcUdvc"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  `,
  'question-8': 'Xem lịch sử các loại tiền thưởng',
  'answer-8': `
    <iframe
                width="100%"
                height="370px"
                src="https://youtube.com/embed/4jwApQQu3rQ"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
  'question-9': 'Xem bản đồ đối tác của VLINKMETA',
  'answer-9': `
  <iframe
                width="100%"
                height="370px"
                src="https://youtube.com/embed/UPkalGFtrNE"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
`,
  'question-10': 'Cách lấy link liên kết tài khoản',
  'answer-10': `
  <p>1. C&aacute;ch lấy link li&ecirc;n kết t&agrave;i khoản (Affiliate link)</p>
  <iframe
                width="100%"
                height="370px"
                src="https://youtube.com/embed/qs76m_xH_-U"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
<p>&nbsp; &nbsp; Ghi ch&uacute;: c&oacute; 2 c&aacute;ch để sử dụng link li&ecirc;n kết:</p>
<p>&nbsp; &nbsp; - Copy link v&agrave; gửi cho người muốn giới thiệu</p>
<p>&nbsp; &nbsp; - Gửi QR code cho người muốn giới thiệu scan bằng camera</p>
<p>2. T&igrave;m kiếm người d&ugrave;ng đ&atilde; li&ecirc;n kết với m&igrave;nh &nbsp;&nbsp;</p>
 <iframe
                width="100%"
                height="370px"
                src="https://youtube.com/embed/MiRAYDTJGLY"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
<p>&nbsp; &nbsp; Ghi ch&uacute;: C&oacute; thể t&igrave;m kiếm bằng username, phone hoặc email. Trong video hướng dẫn d&ugrave;ng username để t&igrave;m, c&oacute; thứ hạng, t&igrave;m user &lsquo;đ&atilde; nhập m&atilde;&rsquo; v&agrave; d&ugrave;ng tree</p>
<p>3. Danh s&aacute;ch những người d&ugrave;ng li&ecirc;n kết c&oacute; thứ hạng (để c&oacute; thứ hạng th&igrave; user phải nhập m&atilde; của package &ge; 500 VUSD)</p>
  <iframe
                width="100%"
                height="370px"
                src="https://youtube.com/embed/N_4NMlBnU-A"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
<p>4. Danh s&aacute;ch những người d&ugrave;ng li&ecirc;n kết đ&atilde; k&iacute;ch hoạt 1 g&oacute;i bất kỳ</p>
  <iframe
                width="100%"
                height="370px"
                src="https://youtube.com/embed/fELNS9qPNSw"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  `,
  'question-11': 'Liên hệ hỗ trợ',
  'answer-11': `
    <iframe
                width="100%"
                height="370px"
                src="https://youtube.com/embed/x35ooFP2HRM"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  <p>Ghi chú: cần Đăng nhập trước khi liên hệ hỗ trợ</p>
  `,
  'question-12': 'Hướng dẫn chuyển đổi VLG sang VMM',
  'answer-12': `
    <iframe
                width="100%"
                height="480px"
                src="https://youtube.com/embed/lMemBhOlK1E"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  `,
}
