<template>
  <div>
    <div class="text-center overflow-auto">
      <canvas id="myCanvas2" data="personal" :width="1028" :height="720"></canvas>
      <div class="load-font-Uvn-Regular" style="visibility: hidden">.</div>
      <div class="load-font-avant-book" style="visibility: hidden">.</div>
      <div class="load-font-consola" style="visibility: hidden">.</div>
    </div>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/apis/repositoryFactory";
const WalletRepository = RepositoryFactory.get("wallet");

export default {
  name: "ModalVMFCertificate",
  mixins: [],
  data() {
    return {
      certificateData: {},
      loading: false,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.Auth.currentUser
    },
  },
  async mounted() {
    try {
      this.loading = true;
      this.certificateData = await WalletRepository.getVmfCertificateData(this.currentUser.Id);
      var data = this.certificateData;
      var canvas = document.getElementById("myCanvas2");
      var context = canvas.getContext("2d");
      var imageObj = new Image();
      if (data) {
        const FullName = this.capitalizeFirstLetter(this.currentUser.FirstName) + " " + this.capitalizeFirstLetter(this.currentUser.LastName)
        const UserName = this.currentUser.Username

        const str = '00000';
        const index = str.length - data.MemberNumber.toLocaleString().length;
        const replacement = data.MemberNumber.toLocaleString();

        const indexVmfUnit = str.length - data.VmfUnit.toLocaleString().length;
        const replacementVmfUnit = data.VmfUnit.toLocaleString();

        const MemberNumber =
          str.substring(0, index) +
          replacement +
          str.substring(index + replacement.length);

        const DateConfirmed = this.$options.filters.formatDateTime(data.ActivatedDate, "MM/DD/YYYY hh:mm A")

        const VmfUnit =
          str.substring(0, indexVmfUnit) +
          replacementVmfUnit +
          str.substring(indexVmfUnit + replacementVmfUnit.length);

          imageObj.onload = function () {
            context.drawImage(imageObj, 10, 10);
            const MemberNumberArray = MemberNumber.split('')
            const VmfUnitArray = VmfUnit.split('')

            const image = new Image();
            const image1 = new Image();
            const image2 = new Image();
            const image3 = new Image();
            const image4 = new Image();

            image.onload = function () { context.drawImage(image, 180, 190) }
            image1.onload = function () { context.drawImage(image1, 200, 190) }
            image2.onload = function () { context.drawImage(image2, 220, 190) }
            image3.onload = function () { context.drawImage(image3, 240, 190) }
            image4.onload = function () { context.drawImage(image4, 260, 190) }

            image.src =  `/assets/numbers/${MemberNumberArray[0]}.png`
            image1.src =  `/assets/numbers/${MemberNumberArray[1]}.png`
            image2.src =  `/assets/numbers/${MemberNumberArray[2]}.png`
            image3.src =  `/assets/numbers/${MemberNumberArray[3]}.png`
            image4.src =  `/assets/numbers/${MemberNumberArray[4]}.png`

            const image00 = new Image();
            const image01 = new Image();
            const image02 = new Image();
            const image03 = new Image();
            const image04 = new Image();

            image00.onload = function () { context.drawImage(image00, 775, 190) }
            image01.onload = function () { context.drawImage(image01, 795, 190) }
            image02.onload = function () { context.drawImage(image02, 815, 190) }
            image03.onload = function () { context.drawImage(image03, 835, 190) }
            image04.onload = function () { context.drawImage(image04, 855, 190) }

            image00.src =  `/assets/numbers/${VmfUnitArray[0]}.png`
            image01.src =  `/assets/numbers/${VmfUnitArray[1]}.png`
            image02.src =  `/assets/numbers/${VmfUnitArray[2]}.png`
            image03.src =  `/assets/numbers/${VmfUnitArray[3]}.png`
            image04.src =  `/assets/numbers/${VmfUnitArray[4]}.png`

            context.textAlign = "center";
            context.font = "15pt consola";
            context.fillStyle = "#3b3f7c";
            context.fillText('Username: ' + UserName , 521, 410);

            context.textAlign = "center";
            context.fillStyle = "#3b3f7c";
            context.font = "30pt Uvn-Regular";
            context.fillText(FullName, 521, 345);
            context.textAlign = "left";
            context.font = "12pt avant-book";
            context.fillText(DateConfirmed, 175, 545);
          };
      }
      imageObj.src = "/assets/img/vmf-cerf.png";
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  },
  methods: {
    capitalizeFirstLetter(string) {
      string = string.normalize('NFC')
      string = string.toLowerCase()
      const words = string.split(" ")
      for (let i = 0; i < words.length; i++) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].substr(1);
      }
      return words.join(' ');
    }
  }
};
</script>

<style scoped lang="scss">
.certificate {
  color: #ffffff;
  margin-top: 32px;

  &__title {
    font-family: "Overpass";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    color: #141822;
  }

  &__password {
    margin-top: 15px;
  }

  &__btn {
    height: 40px;
  }

  &__action {
    display: grid;
    grid-template-columns: 47% 47%;
    justify-content: space-between;
  }

  &__btn-cancel {
    color: #007bff;
    background: #ffffff;
    border: 1px solid #007bff;

    &:hover {
      background: #007bff;
      color: #ffffff;
    }
  }
}

::v-deep .eye {
  color: #43464e !important;
}

::v-deep .base-select {
  margin-bottom: 20px;

  &__label {
    color: #43464e;
  }
}

::v-deep .base-select__wrap {
  background: #f7f7f7;

  .arrow-down {
    color: #43464e;
  }
}

::v-deep .base-select__inner {
  p {
    color: #898b91;
  }
}

@media (max-width: 1200px) {
  #myCanvas2 {
    zoom: 85%;
  }
}

@media (max-width: 991px) {
  #myCanvas2 {
    zoom: 65%;
  }
}

@media (max-width: 768px) {
  #myCanvas2 {
    zoom: 45%;
  }
}

@media (max-width: 520px) {
  #myCanvas2 {
    zoom: 38%;
  }
}

@media (max-width: 375px) {
  #myCanvas2 {
    zoom: 35%;
  }
}

@media (max-width: 320px) {
  #myCanvas2 {
    zoom: 31%;
  }
}
</style>
