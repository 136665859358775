export default {
  'title-1': {
    index: 'agent',
  },

  'title-2': {
    index: 'supervisor',
    bonus: '30% in the bonus pool',
    description: '3 conditions:',
    condition:
      '<span>1. Direct F1 minimum 5 AGENTS. </span></br><span>2. F1 direct sales at least 4,000 AP calculated in 1 quarter. </span></br><span>3. Affiliate Point in your direct and indirect leg team sale minimum 25,000 AP.</span>',
  },

  'title-3': {
    index: 'manager',
    bonus: '20% in bonus pool',
    description: '3 conditions:',
    condition:
      '<span>1. Two SUPERVISORS in 2 legs from any levels. </span></br><span>2. F1 direct sales at least 6,000 AP calculated in 1 quarter. </span></br><span>3. Affiliate Point in your direct and indirect legs team sale minimum 50,000 AP.</span>',
  },

  'title-4': {
    index: 'regional manager',
    bonus: '10% of the bonus pool',
    description: '3 conditions:',
    condition:
      '<span>1. Two MANAGERS in 2 legs from any levels. </span></br><span>2. F1 direct sales at least 10,000 AP calculated in 1 quarter.</span></br><span>Affiliate Point in your direct and indirect legs team sale minimum 100,000 AP.</span>',
  },

  'title-5': {
    index: 'state manager',
    bonus: '10% in bonus pool',
    description: '3 conditions:',
    condition:
      '<span>1. 2 REGIONAL MANAGER in 2 legs from any levels.</span></br><span>2. F1 direct sales at least 15,000 AP calculated in 1 quarter.</span></br><span>Affiliate Point in your direct and indirect legs team sale minimum 200,000 AP.</span>',
  },

  'title-6': {
    index: 'director',
    bonus: '10% in bonus pool',
    description: '3 conditions:',
    condition:
      '<span>1. Three STATE MANAGERS in 3 legs from any levels. </span></br><span>2. F1 direct sales at least 20,000 AP calculated in 1 quarter.</span></br><span>3. Affiliate Point in your direct and indirect legs team sale minimum 600,000 AP.</span>',
  },

  'title-7': {
    index: 'global director',
    bonus: '10% of the bonus pool',
    description: '3 conditions:',
    condition:
      '<span>1. Three DIRECTORS in 3 legs from any levels. </span></br><span>2. F1 direct sales at least 25,000 AP calculated in 1 quarter</span></br><span>3. Affiliate Point in your direct and indirect legs team sale minimum 1,800,000 AP.</span>',
  },

  'title-8': {
    index: 'chairman',
    bonus: '10% of the bonus pool',
    description: '3 conditions:',
    condition:
      '<span>1. Three GLOBAL DIRECTORS in 3 legs from any levels.</span></br><span>2. F1 direct sales at least 30,000 AP calculated in 1 quarter.</span></br><span>3. Affiliate Point in your direct and indirect legs team sale minimum 5,400,000 AP.</span>',
  },
}
