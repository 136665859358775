import moment from "moment";
import {getCurrentLanguage} from "@/core/utils";
let offsetTime = new Date().getTimezoneOffset() / 60;
offsetTime = offsetTime - 5;
const locale = getCurrentLanguage();
function formatDate(value, format = locale == 'vi' ? "DD/MM/YYYY"  : "MM/DD/YYYY") {
  return value ? moment(value).add(-offsetTime, 'hours').format(format) : 'N/A';
}

function formatDateNoOffset(value) {
  return locale == 'vi' ? moment(value).format('DD/MM/YYYY') :  moment(value).format('MMMM Do, YYYY');
}

function formatDateTime(value, format = locale == 'vi' ? "DD/MM/YYYY HH:mm"  : "MM/DD/YYYY hh:mm A") {
  return moment(value).add(-offsetTime, 'hours').format(format);
}

function formatNumber(value) {
  return new Intl.NumberFormat("en", { minimumFractionDigits: 2, }).format(value)
}

function toCurrency(value, isShowSymbol = false, digits = 2) {
  const config = isShowSymbol
    ? {
        style: 'currency',
        currency: 'USD',
      }
    : {}
  const formatter = new Intl.NumberFormat('en-US', {
    ...config,
    minimumFractionDigits: digits,
  })
  return formatter.format(value)
}
function toCryptocurrency(value, name = '', digits = 2) {
  const formatter = new Intl.NumberFormat('en', {
    minimumFractionDigits: digits,
  })
  return formatter.format(value) + name
}
function formatAmountUnitEvent(str) {
  const amount = parseInt(Number(str))
  return amount.toLocaleString('en-US')
}
function formatMarkToNumber(str, multiplier = 1) {
  if (str) {
    return Number(str.replace(/,|\./g, '')) * multiplier
  }
  return 0
}
function formatValueCurrency(str, multiplier = 1) {
  if (str) {
    return Number(str.replace(/,/g, '')) * multiplier
  }
  return 0
}
export {
  formatNumber,
  formatDate,
  formatDateNoOffset,
  formatDateTime,
  toCurrency,
  toCryptocurrency,
  formatAmountUnitEvent,
  formatMarkToNumber,
  formatValueCurrency
}
