export default {
  'question-1': 'Registration and confirm via email',
  'answer-1': `
<iframe
                width="100%"
                height="370px"
                src="https://youtube.com/embed/GZIg7iFJdEE"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
<p>1. Go to Homepage <a data-fr-linked="true" href="https://vlinkmeta.com/">VLINKMETA</a> </p>
<p>2. Click Sign Up</p>
<p>3. Enter personal information, including: Full name, First name, User name (displayed in the system), Email and enter Password 2 times.</p>
<p>4. Choose an account type, there are 3 different account types corresponding to different characteristics and benefits, please refer to the FAQ of VLINKMETA for details.</p>
<p>5. Input the referrer's code if any</p>
<p>6. Confirm that you have read and accepted the <a data-fr-linked="true" href="https://vlinkmeta.com/terms">Terms of Use</a> and the <a data-fr-linked="true" href="https://vlinkmeta.com/policy">Privacy Policy</a> of VLINKMETA before Complete account registration</p>
<p>7. The system will notify successful registration, and send an account verification email to the registered email address.</p>
<p>8. Check the mailbox of the registered email and click Confirm.</p>
<p>9. The system automatically redirects the page to VLINKMETA&apos;s website, informing that your account has been successfully registered and ready to log in.</p>`,

  'question-2': 'Login',
  'answer-2': `
<iframe
                width="100%"
                height="370px"
                src="https://youtube.com/embed/y9-glYASVCs"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
<ol>
    <li>Go to Homepage <a data-fr-linked="true" href="https://vlinkmeta.com/">VLINKMETA</a> </li>
    <li>Click sign in</li>
    <li>Enter the account information: Username and password</li>
    <li>Confirm</li>
</ol>`,
  'question-3': 'Forgot password',
  'answer-3': `
<iframe
                width="100%"
                height="370px"
                src="https://youtube.com/embed/2ZuHoWLV-NA"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

    <p>1. Go to Homepage <a data-fr-linked="true" href="https://vlinkmeta.com/">VLINKMETA</a> </p>
    <p>2. Click Sign In</p>
    <p>3. Click Forgot Password</p>
    <p>4. Enter your email or username and confirm The system will send 1 email to retrieve the password</p>
    <p>5. Check your mail box and open the system&apos;s mail</p>
    <p>6. Click the button Choose a new password</p>
    <p>7. Create a new password and confirm</p>
    <p>8. The system redirects the page to the Home page, now you can login with the newly created password.</p>
`,
  'question-4': 'QR code creation',
  'answer-4': `
  <p>Only applicable for Business and Affiliate accounts that have activated packages from 1000 VUSD or more / Available only for Business accounts and Affiliate ones activating package from 1000 VUSD</p>
  <iframe
                width="100%"
                height="370px"
                src="https://youtube.com/embed/xT03VHDuLH8"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
`,
  'question-5': 'Transfer internal and external VLINKMETA',
  'answer-5': `
<p>1. VLINKMETA internal transfer and the recipient check the balance received successfully or not::</p>
<iframe
                width="100%"
                height="370px"
                src="https://youtube.com/embed/gMfCYhCP8o8"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
<p>2. Transfer money to VLGTOKEN.COM platform</p>
<iframe
                width="100%"
                height="370px"
                src="https://youtube.com/embed/KraSKrbY4nk"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
`,
  'question-6': 'Purchasing and redeeming packages',
  'answer-6': `
<p>1. Buy and pay by Credit card:</p>
<iframe
                width="100%"
                height="370px"
                src="https://youtube.com/embed/8p-BmqmjsWs"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
<p>2. Buy and pay with VLINKMETA wallets:</p>
<iframe
                width="100%"
                height="370px"
                src="https://youtube.com/embed/wVBFysJe9nI"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
<p>3. How to view transaction history (list of purchased packages) and successfully activated packages:</p>
<iframe
                width="100%"
                height="370px"
                src="https://youtube.com/embed/t68Dv7pcu1g"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
<p>4. How to activate 1 pack:</p>
<iframe
                width="100%"
                height="370px"
                src="https://youtube.com/embed/CLTAcFDAyPk"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  `,
  'question-7': 'View my Wallets',
  'answer-7': `
  <iframe
                width="100%"
                height="370px"
                src="https://youtube.com/embed/StpHRvcUdvc"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  `,
  'question-8': 'View my bonuses ',
  'answer-8': `
  <iframe
                width="100%"
                height="370px"
                src="https://youtube.com/embed/4jwApQQu3rQ"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  `,
  'question-9': 'View Our Partners',
  'answer-9': `
  <iframe
                width="100%"
                height="370px"
                src="https://youtube.com/embed/UPkalGFtrNE"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  `,
  'question-10': 'Get my Affiliate link',
  'answer-10': `
  <p>1. How to get account link (Affiliate link)</p>
    <iframe
                width="100%"
                height="370px"
                src="https://youtube.com/embed/qs76m_xH_-U"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
<p>&nbsp; &nbsp; &nbsp;Note: there are 2 ways to use affiliate links:</p>
<p>&nbsp; &nbsp; &nbsp;- Copy the link and send it to the person you want to introduce</p>
<p>&nbsp; &nbsp; &nbsp;- Send QR code to someone who wants to recommend scanning with camera</p>
<p>2. Search for users associated with you</p>
  <iframe
                width="100%"
                height="370px"
                src="https://youtube.com/embed/MiRAYDTJGLY"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
<p>&nbsp; &nbsp; &nbsp;Note: Searchable by username, phone or email. In the video tutorial using username to find, get rank, find user &apos;entered code&apos; and use tree</p>
<p>3. List of linked users with rank (to get the rank, the user must enter the code of the package &ge; 500 VUSD)</p>
  <iframe
                width="100%"
                height="370px"
                src="https://youtube.com/embed/N_4NMlBnU-A"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
<p>4. List of linked users who have activated any package</p>
  <iframe
                width="100%"
                height="370px"
                src="https://youtube.com/embed/fELNS9qPNSw"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  `,
  'question-11': 'Contact support ',
  'answer-11': `
    <iframe
                width="100%"
                height="370px"
                src="https://youtube.com/embed/x35ooFP2HRM"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  <p>Note: Login is required before contacting support</p>
  `,
  'question-12': 'Convert VLG to VMM',
  'answer-12': `
    <iframe
                width="100%"
                height="480px"
                src="https://youtube.com/embed/lMemBhOlK1E"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  `,
}
