<template>
  <BaseModal :config="config">
    <template slot="content">
      <div class="forgot-pw__title">
        {{$t('user.forgot-password')}}
      </div>
      <div class="forgot-pw__content">
        <BaseInputCustom
          class-name="forgot-pw__email"
          ref="username"
          v-model="formData.Username"
          type="text"
          name="username"
          required
          :label="$t('forms.forgot-password-type')"
          :min-length="6"
        />
      </div>
      <div class="forgot-pw__desc">
        {{$t('forms.forgot-password')}}
      </div>
      <button
        class="btn btn-block btn-primary forgot-pw__btn"
        :class="{'button--loading': loading}"
        @click="submit"
      >
        <span>{{$t('forms.submit')}}</span>
      </button>
    </template>
  </BaseModal>
</template>

<script>
import {MODAL, NOTIFICATION_ICON} from "@/constants";
import {mapActions} from "vuex";
import BaseInputCustom from "@components/Inputs/BaseInputCustom";
import validateMixin from "@/core/mixins/validate";
import { RepositoryFactory } from "@apis/repositoryFactory";
const AuthRepository = RepositoryFactory.get('auth')

export default {
  name: "ModalForgotPassword",
  components: {
    BaseInputCustom,
  },
  mixins: [validateMixin],
  data() {
    return {
      config: {
        id: MODAL.FORGOT_PASSWORD,
        class: 'modal--forgot-pw',
        noCloseOnBackDrop: false,
        centered: true
      },
      formData: {
        Username: ''
      },
      loading: false
    }
  },
  methods: {
    ...mapActions('Auth', {
      forgotPassword: 'forgotPassword'
    }),
    async submit() {
      const refs = ['username']
      const refsValid = this.$_validateMixin_refs(refs)
      if (refsValid) {
        this.loading = true
        try {
          await AuthRepository.forgotPassword(this.formData)
          this.$notifyPopup(NOTIFICATION_ICON.SUCCESS,
            this.$t('swal.title.sent'),
            this.$t('security-email.sent'),
            {
              action1Text: 'OK',
              action1Func: () => {}
            }
          )
        } catch (e) {
          console.log(e)
          this.$alertMsg('fail', e.response.data.message)
        }
        this.loading = false
      }
    }
  },
}
</script>

<style scoped lang="scss">
.forgot-pw {
  color: #FFFFFF;
  margin-top: 32px;

  &__title {
    color: #FFFFFF;
    font-weight: 700;
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    margin-bottom: 40px;
  }
  &__desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #ADAEB2;
    text-align: center;
  }
  &__btn {
    margin-top: 24px;
    margin-bottom: 16px;
  }
}
</style>
