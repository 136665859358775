<template>
  <BaseModal :config="config" @hidden="resetAll">
    <template slot="content" v-if="!securityVerification">
      <div class="login__title">
        {{ $t("user.login-title") }}
      </div>
      <form @submit.prevent="submit">
        <div class="login__content">
          <BaseInputCustom
            class-name="login__username"
            ref="username"
            v-model="formData.Username"
            type="text"
            name="username"
            required
            :label="$t('user.username-login')"
          />
          <BaseInputCustom
            class-name="login__password"
            ref="password"
            v-model="formData.Password"
            type="password"
            name="password"
            required
            :label="$t('user.password')"
            :max-length="50"
            :min-length="6"
            :limit-input="50"
          />
          <div class="login__reset-pass">
            <span @click="openModalForgotPw">{{
              $t("user.forgot-password-question")
            }}</span>
            <span class="mt-2" @click="openModal(MODAL.FORGOT_USERNAME)">{{
              $t("user.forgot-username-question")
            }}</span>
          </div>
        </div>
        <button
          class="btn btn-block btn-primary login__btn"
          type="submit"
          :class="{ 'button--loading': loading }"
        >
          <span>{{ $t("user.login-button") }}</span>
        </button>
        <div class="login__redirect-sign">
          {{ $t("regist.not-acc") }}
          <span @click="openRegisterModal">{{
            $t("regist.sign-up-here")
          }}</span>
        </div>
        <div class="login__resend-email">
          <span @click="openResendEmail"
            >{{ $t("user.resend-email-question") }}
          </span>
        </div>
      </form>
    </template>
    <template slot="content" v-else>
      <SecurityVerification
        :loading="loading"
        @submit="verifySecurity"
        @back="resetAll"
        @resendEmail="submit"
      />
    </template>
  </BaseModal>
</template>

<script>
import { MODAL } from "@/constants";
import BaseInputCustom from "@/components/Inputs/BaseInputCustom.vue";
import SecurityVerification from "@/components/Authen/SecurityVerification.vue";
import validateMixin from "@/core/mixins/validate";
import { mapActions, mapGetters } from "vuex";
import { reCaptchaKey } from "@/constants/config";
export default {
  name: "ModalLogin",
  components: {
    BaseInputCustom,
    SecurityVerification,
  },
  mixins: [validateMixin],
  data() {
    return {
      MODAL,
      config: {
        id: MODAL.LOGIN,
        class: "modal--login",
        noCloseOnBackDrop: true,
        centered: true,
      },
      securityVerification: false,
      formData: {
        Username: "",
        Password: "",
        ReCaptchaToken: reCaptchaKey,
      },
    };
  },
  mounted() {
    const query = this.$route.query;
    if (query && query.modal == MODAL.LOGIN) {
      this.$bvModal.show(MODAL.LOGIN);
    }
  },
  methods: {
    ...mapActions("Auth", {
      login: "login",
    }),
    openModal(modalId) {
      this.$bvModal.hide(MODAL.LOGIN);
      this.$bvModal.show(modalId);
    },
    openModalForgotPw() {
      this.$bvModal.hide(MODAL.LOGIN);
      this.$bvModal.show(MODAL.FORGOT_PASSWORD);
    },
    openResendEmail() {
      this.$bvModal.hide(MODAL.LOGIN);
      this.$bvModal.show(MODAL.CONFIRMATION_EMAIL);
    },
    openRegisterModal() {
      this.$bvModal.hide(MODAL.LOGIN);
      this.$bvModal.show(MODAL.REGISTER);
    },
    resetForm() {
      const refs = ["username", "password"];
      this.formData = {
        Username: "",
        Password: "",
      };
      this.$_validateMixin_reset(refs);
    },
    resetAll() {
      this.resetForm();
      this.$store.commit("Auth/setAuthenticator", null);
    },
    submit() {
      const refs = ["username", "password"];
      const refsValid = this.$_validateMixin_refs(refs);
      if (refsValid) {
        this.login(this.formData);
      }
    },
    verifySecurity(data) {
      this.login({ ...this.formData, ...data });
    },
  },
  computed: {
    ...mapGetters("Auth", {
      currentUser: "currentUser",
      loading: "processing",
      authenticator: "authenticator",
    }),
  },
  watch: {
    authenticator(val) {
      if (val && val.length) {
        this.securityVerification = true;
      } else {
        this.securityVerification = false;
      }
    },
    currentUser(val) {
      if (val && val.Token) {
        this.$bvModal.hide(this.config.id);
        return;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.login {
  color: #ffffff;
  margin-top: 32px;
  &__title {
    color: #ffffff;
    font-weight: 700;
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    margin-bottom: 40px;
  }
  &__password {
    margin-top: 15px;
  }
  &__btn {
    height: 40px;
  }
  &__reset-pass {
    display: flex;
    align-items: flex-end;
    margin-top: 16px;
    margin-bottom: 32px;
    flex-direction: column;
    span {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      text-transform: capitalize;
      color: #007bff;
      cursor: pointer;
      &:hover,
      &:focus {
        color: #ffffff;
      }
    }
  }
  &__redirect-sign {
    margin-bottom: 18px;
    margin-top: 24px;
    text-align: center;
    color: #ffffff;
    display: flex;
    justify-content: center;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    span {
      margin-left: 5px;
      color: #007bff;
      &:hover,
      &:focus {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
  &__resend-email {
    text-align: center;
    color: #ffffff;
    display: flex;
    justify-content: center;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    span {
      margin-left: 5px;
      color: #007bff;
      &:hover,
      &:focus {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
</style>
