<template>
  <div class="header">
    <div class="new-header d-flex justify-content-around align-items-center py-3 px-2">
      <router-link :to="{ path: '/' }">
        <img src="/img/logo.webp" alt="logo" class="logo" />
      </router-link>

      <ul class="navbar-nav flex-row text-uppercase">
        <li v-for="menuItem in headerMenu" :key="menuItem.id" class="nav-item px-3 text-center align-items-center d-flex">
          <router-link :class="[$route.name.toLowerCase() === menuItem.id ? 'text-gradient' : 'text-white']"
            class="nav-link text-nowrap header-item px-0" style="height: 32px" :to="{ path: menuItem.path }">
            {{ $t(menuItem.label) }}
          </router-link>
        </li>
      </ul>
      <HomeTotalUser class="total-member" />
      <div class="d-flex align-items-center">
        <div v-if="isLogged" class="user-logged form-pc">
          <div class="user-logged__header">
            <div class="shop-cart" @click="redirectShoppingCart">
              <img src="/img/header/cart.svg" alt="cart" class="cart" />
              <span class="number-cart">
                {{ numberMyCart }}
              </span>
            </div>
            <div class="information">
              <img :src="avatarPath" alt="avatar" class="avatar"
                onerror="this.onerror=null;this.src='/img/header/avatar.png'" />
              <div class="arrow-down"></div>
              <div class="information__content">
                <div class="packages">
                  <router-link :to="`/shop?tab=MyPackage`" v-if="!!currentUser.ImagePath">
                    <img width="110px" height="auto" :src="$apiImageUrl + currentUser.ImagePath"
                      class="packages-thumnails mt-2" :alt="currentUser.PackageName" />
                  </router-link>
                  <a href="javascript:void(0)" class="mt-3 text-center text-view-cer" v-if="isVMF"
                    @click="$bvModal.show(MODAL.VMF_CERTIFICATE)">{{ $t("view-cer") }}</a>
                  <p class="mt-3 text-center h5 text-white" v-if="!!currentUser.Title">{{ currentUser.Title }}</p>
                </div>
                <ul class="list-menu">
                  <li class="item" v-for="menuItem in profileMenu" :key="menuItem.label">
                    <router-link class="nav-link" :to="{ path: menuItem.path }" @click.native="closeMenuMobile()">
                      <span :class="menuItem.icon" />
                      <span class="name">{{ $t(menuItem.label) }}</span>
                    </router-link>
                  </li>

                  <li class="item">
                    <a class="nav-link log-out" @click="logout()">
                      <span class="icon icon-logout"></span>
                      <span class="name">{{ $t("logout") }}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div v-else class="form-pc form-inline my-2 my-lg-0">
          <button class="btn-bg btn" @click="openLoginModal">
            <span>{{ $t('user.login-title') }}</span>
          </button>
          <button class="btn-bg btn" @click="openRegisterModal">
            <span>{{ $t('user.register') }}</span>
          </button>
        </div>

        <div class="text-white pr-3 pl-1 d-flex pointer">
          <span class="pr-1" :class="{ 'text-info': langSelected.toUpperCase() == 'EN' }" @click="changeLocale('en')">EN</span> |
          <span class="pl-1" :class="{ 'text-info': langSelected.toUpperCase() == 'VI' }" @click="changeLocale('vi')">VI</span>
        </div>
        <div class="pl-2 right">
          <img src="/img/menu.png" alt="icon-menu" @click="openMenuMobile()" />
        </div>
      </div>
    </div>
    <hr class="bg-gradient m-0" style="height: 2px;" />


    <div v-if="activeMenuMb" class="menu-mobile">
      <div class="menu-mobile__title">
        <img src="/img/icon-close.png" alt="icon-close" class="icon-close" @click="closeMenuMobile()" />
        <div v-if="
          $store.state.Auth.currentUser &&
          Object.keys($store.state.Auth.currentUser).length !== 0
        " @click="toggleMenuUserMB()" class="user-logged">
          <div class="user-logged__header">
            <div class="shop-cart" @click="redirectShoppingCart">
              <img src="/img/header/cart.svg" alt="cart" class="cart" />
              <span class="number-cart">
                {{ numberMyCart }}
              </span>
            </div>
            <div class="information">
              <img :src="avatarPath" alt="avatar" class="avatar"
                onerror="this.onerror=null;this.src='/img/header/avatar.png'" />
              <div class="arrow-down"></div>
              <div v-if="activeMenuMbUser" class="information__content mobile">
                <div class="packages">
                  <router-link :to="`/shop?tab=MyPackage`" v-if="!!currentUser.ImagePath">
                    <img width="110px" height="auto" :src="$apiImageUrl + currentUser.ImagePath"
                      class="packages-thumnails mt-2" :alt="currentUser.PackageName" />
                  </router-link>
                  <a href="javascript:void(0)" class="mt-3 text-center text-view-cer" v-if="isVMF"
                    @click="$bvModal.show(MODAL.VMF_CERTIFICATE)">{{ $t("view-cer") }}</a>
                  <p class="m-3 text-center h5 text-white" v-if="!!currentUser.Title">{{ currentUser.Title }}</p>
                </div>
                <ul class="list-menu">
                  <li class="item" v-for="menuItem in profileMenu" :key="menuItem.label">
                    <a href="javascript:void(0)" class="nav-link" @click="redirectLink(menuItem.path)">
                      <span :class="menuItem.icon"></span>
                      <span class="name">{{ $t(menuItem.label) }}</span>
                    </a>
                  </li>
                  <li class="item">
                    <a class="nav-link log-out" @click="logout()">
                      <span class="icon icon-logout"></span>
                      <span class="name">{{ $t("logout") }}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="form-mb form-inline">
          <button class="btn-bg btn" @click="openLoginModal">
            <span>{{ $t('user.login-title') }}</span>
          </button>
          <button class="btn-bg btn" @click="openRegisterModal">
            <span>{{ $t('user.register') }}</span>
          </button>
        </div>
      </div>
      <div class="menu-mobile__list">
        <div class="d-flex flex-column justify-content-center align-items-center mt-3">
          <home-total-user />
        </div>
        <ul class="navbar-nav mr-auto">
          <li v-for="menuItem in headerMenu" :key="menuItem.id" class="nav-mb-item"
            :class="{ active: $route.name.toLowerCase() === menuItem.id }">
            <router-link class="nav-link" :to="{ path: menuItem.path }" @click.native="closeMenuMobile()">
              {{ $t(menuItem.label) }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>

    <ModalLogin />
    <ModalRegister />
    <ModalForgotPassword />
    <ModalEmailConfirmation />
    <ModalForgotUsername />
    <ModalCertificate v-if="isVMF" />
  </div>
</template>
<script>
import { MODAL } from "@/constants";
import { apiImageUrl, localeOptions } from "@/constants/config";
import MENU, { MENU_POSITION } from "@/constants/menu";
import ModalLogin from "@/components/Authen/ModalLogin.vue";
import ModalRegister from "@/components/Authen/ModalRegister.vue";
import ModalForgotPassword from "@components/Authen/ModalForgotPassword";
import ModalForgotUsername from "@components/Authen/ModalForgotUsername";
import { RepositoryFactory } from "@apis/repositoryFactory";
import globalMixin from '@/core/mixins/global';
import { mapActions } from "vuex";
import ModalEmailConfirmation from "@components/Authen/ModalEmailConfirmation";
import ModalCertificate from "@/containers/Wallet/modal-vmf-certificate.vue";
const HomeRepository = RepositoryFactory.get("home");
const ShopRepository = RepositoryFactory.get("cart");

export default {
  name: "the-header",
  components: {
    ModalEmailConfirmation,
    ModalForgotUsername,
    ModalForgotPassword,
    ModalLogin,
    ModalCertificate,
    HomeTotalUser: () => import('./HomeTotalUser'),
    ModalRegister
  },
  mixins: [globalMixin],
  data() {
    return {
      MODAL,
      localeOptions,
      activeMenu: false,
      activeMenuMbUser: false,
      activeMenuMb: false,
      prices: [],
      carts: [],
      langSelected: this.$i18n.locale,
      numberCart: 0,
      newPrices: {}
    };
  },
  computed: {
    avatarPath() {
      return this.currentUser && this.currentUser.Avatar ? apiImageUrl + this.currentUser.Avatar : '/img/header/avatar.png'
    },
    profileMenu() {
      return MENU.filter((i) =>
        i.position.includes(MENU_POSITION.PROFILE_MENU)
      );
    },
    headerMenu() {
      return MENU.filter((i) => i.position.includes(MENU_POSITION.HEADER_MENU));
    },
    numberMyCart() {
      return this.$store.state.myCart || 0;
    },
    isVMF() {
      return this.currentUser && this.currentUser.PackageName == 'Vlinkmeta Mall Founders'
    }
  },
  async created() {
    try {
      this.prices = await HomeRepository.getPrice();
      this.newPrices = {
        'VTP': this.prices.VlgPlus,
        'VLG GOLD': this.prices.SharePrice,
        'VLG TOKEN': this.prices.TokenPrice,
        'VUSD': this.prices.VusdPrice
      }
      if (
        this.$store.state.Auth.currentUser &&
        Object.keys(this.$store.state.Auth.currentUser).length !== 0
      ) {
        this.carts = await ShopRepository.getMyCart();
        this.numberCart = this.carts.CartItems.reduce(function (a, b) {
          return a + b["Quantity"];
        }, 0);
        this.$store.commit("setMyCart", this.numberCart);
      }
    } catch (error) { }
  },
  methods: {
    ...mapActions("Auth", {
      signOut: "signOut",
    }),
    ...mapActions(["setLang"]),
    logout() {
      this.signOut();
    },
    toggleMenuUserMB() {
      this.activeMenuMbUser = !this.activeMenuMbUser;
    },
    openLoginModal() {
      this.$bvModal.show(MODAL.LOGIN);
    },
    openRegisterModal() {
      this.$bvModal.show(MODAL.REGISTER);
    },
    openForgotPass() {
      this.$bvModal.show(MODAL.FORGOT_PASSWORD);
    },
    openMenuMobile() {
      this.activeMenuMb = true;
      document.getElementsByTagName("html")[0].classList.add("modal-open");
    },
    closeMenuMobile() {
      this.activeMenuMb = false;
      document.getElementsByTagName("html")[0].classList.remove("modal-open");
    },
    redirectLink(link) {
      this.closeMenuMobile()
      this.$router.push(link)
    },
    changeLocale(e) {
      // this.setLang(e.target.value);
      // this.$store.commit("changeLang", e.target.value)
      this.$store.commit("changeLang", e)

      location.reload()
    },
    redirectShoppingCart() {
      document.getElementsByTagName("html")[0].classList.remove("modal-open");
      if (this.$route.path !== '/shopping-cart') {
        this.$store.commit('setRouteBack', this.$route.path)
      }
      this.$router.push('/shopping-cart')
    }
  },
  async mounted() {
    await this.$nextTick()
    if (this.$route.query && this.$route.query.register) {
      setTimeout(() => this.openRegisterModal(), 500)
    }
    if (this.$route.query && this.$route.query.login === true) {
      setTimeout(() => this.openLoginModal(), 500)
    }
    if (this.$route.query && this.$route.query.forgot) {
      setTimeout(() => this.openForgotPass(), 500)
    }
  }
};
</script>

<style scoped lang="scss">
.new-header {
  display: flex;
  background: rgb(1, 1, 119);
  background: linear-gradient(90deg, rgba(1, 1, 119, 1) 0%, rgba(4, 1, 121, 1) 18.5%, rgba(14, 1, 127, 1) 32.8%, rgba(30, 1, 137, 1) 45.7%, rgba(45, 0, 125, 1) 57%, rgba(45, 0, 125, 1) 70%, rgba(55, 0, 120, 1) 80%, rgba(60, 0, 110, 1) 90%, rgba(47, 0, 68, 1) 100%);

  .logo {
    width: 153px;
    height: 48px;
  }

  .total-member {
    display: block;

    @media only screen and (max-width: 1380px) {
      display: none;
    }
  }

  .right {
    display: none;

    img {
      width: 24px;
      height: 24px;
    }
  }

  @media only screen and (max-width: 1125px) {
    justify-content: space-between !important;

    .navbar-nav {
      display: none;
    }

    .right {
      display: block;
    }
  }

  .form-pc {
    @media only screen and (max-width: 550px) {
      display: none;
    }
  }
}

.btn-bg {
  background-image: url('./btn-gradient.png');
  background-size: 100%;
  background-repeat: no-repeat;
  min-width: 130px;
  height: 32px;
}

.header {
  .menu-mobile {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    width: 100%;
    height: 100vh;
    background: #141822;
    transition: all linear 0.2s;

    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #152b4b;
      padding: 16px;

      .icon-close {
        width: 24px;
        height: 24px;
      }

      .form-mb {

        .btn-register,
        .btn-login {
          width: 124px;
          height: 40px;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            font-weight: 600;
          }
        }

        .btn-login {
          background: transparent;
          border: 1px solid #007bff;
          margin-right: 16px;

          span {
            color: #007bff;
          }
        }

        .btn-register {
          background: #007bff;
          border: 1px solid #007bff;

          span {
            color: #ffffff;
          }
        }
      }
    }

    &__list {
      ul.navbar-nav {
        .nav-mb-item {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          padding: 5px;
          margin: 0 16px;
          text-align: right;
          // padding-right: 1rem;
          border-bottom: 1px solid #f0d363;

          &:first-child {
            padding-top: 16px;
          }

          &.active-menu {
            .nav-link {
              color: #007bff;
            }
          }
        }

        .nav-link {
          text-transform: uppercase;
          line-height: 0;
          color: #ffffff;

          @media only screen and (max-width: 992px) {
            line-height: 24px;
            padding-right: 10px;
            border-right: 5px solid #efd363;
          }
        }
      }
    }

    &__selector {
      margin-top: 20px;

      .language-selector {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        select {
          border: none;
          outline: none;
          width: 59px;
          height: 32px;
          background: rgba(0, 123, 255, 0.2);
          border-radius: 8px;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #007bff;
          text-align: left;
          padding-left: 10px;
          appearance: none;
        }

        .arrow-down {
          position: absolute;
          top: 50%;
          -webkit-transform: translateY(-50%);
          transform: translate(-50%, -50%);
          width: 12.57px;
          height: 7.62px;
          right: 45%;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    &__coin {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 20px;
      row-gap: 25px;
      padding: 10px 30px;
      background: rgba(18, 34, 57, 1);

      .item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }

        .oval {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background: #2efe52;
          margin-right: 18px;
          position: relative;

          &:before {
            content: "";
            background: transparent;
            width: 23px;
            height: 23px;
            top: -2px;
            right: 0;
            left: -2px;
            border: 2px solid rgba(46, 254, 82, 0.2);
            display: inline-block;
            border-radius: 50%;
            position: absolute;
          }
        }

        &:nth-child(2) {
          .oval {
            background: #1ce8fc;

            &:before {
              border: 2px solid rgba(28, 232, 252, 0.2);
            }
          }
        }

        &:nth-child(3) {
          .oval {
            background: #ff5401;

            &:before {
              border: 2px solid rgba(255, 84, 1, 0.2);
            }
          }
        }

        &:nth-child(4) {
          .oval {
            background: #dbac35;

            &:before {
              border: 2px solid rgba(219, 172, 53, 0.2);
            }
          }
        }

        .ticker {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;

          span {
            &:first-child {
              font-weight: 500;
              font-size: 18px;
              line-height: 28px;
              color: #ffffff;
            }

            &:last-child {
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: #adaeb2;
            }
          }
        }
      }
    }
  }

  .navbar-top {
    background: #152b4b !important;

    .navbar-collapse {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media only screen and (max-width: 992px) {
        padding: 0 15px;
        justify-content: space-between;

        .left {
          display: none;
        }
      }
    }

    .logo {
      width: 153px;
      height: 48px;
    }

    // .total-member {
    //   justify-content: center;
    //   align-items: center;
    //   display: flex;

    //   span {
    //     font-family: 'Overpass';
    //     font-style: normal;
    //     font-weight: 400;
    //     font-size: 16px;
    //     line-height: 24px;
    //     //text-transform: capitalize;
    //     text-align: center;
    //     color: #FFFFFF;
    //   }

    //   @media only screen and (max-width: 992px) {
    //     display: none;
    //   }
    // }

    .left {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .information {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 30px;

        .item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-right: 20px;

          &:last-child {
            margin-right: 0;
          }

          .oval {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: #2efe52;
            margin-right: 18px;
            position: relative;

            &:before {
              content: "";
              background: transparent;
              width: 23px;
              height: 23px;
              top: -2px;
              right: 0;
              left: -2px;
              border: 2px solid rgba(46, 254, 82, 0.2);
              display: inline-block;
              border-radius: 50%;
              position: absolute;
            }
          }

          &:nth-child(2) {
            .oval {
              background: #1ce8fc;

              &:before {
                border: 2px solid rgba(28, 232, 252, 0.2);
              }
            }
          }

          &:nth-child(3) {
            .oval {
              background: #ff5401;

              &:before {
                border: 2px solid rgba(255, 84, 1, 0.2);
              }
            }
          }

          &:nth-child(4) {
            .oval {
              background: #dbac35;

              &:before {
                border: 2px solid rgba(219, 172, 53, 0.2);
              }
            }
          }

          .ticker {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            width: 97px;

            span {
              &:first-child {
                font-weight: 500;
                font-size: 18px;
                line-height: 28px;
                color: #ffffff;
              }

              &:last-child {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #adaeb2;
              }
            }
          }
        }
      }

      .language-selector {
        position: relative;

        select {
          border: none;
          outline: none;
          width: 59px;
          height: 32px;
          background: rgba(0, 123, 255, 0.2);
          border-radius: 8px;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #007bff;
          text-align: left;
          padding-left: 10px;
          appearance: none;
        }

        .arrow-down {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 12.57px;
          height: 7.62px;
          right: 10px;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

  }
  .form-pc.form-inline.my-2.my-lg-0 {
    width: 260px !important;
  }
  @media only screen and (max-width: 1500px) and (min-width: 1350px){
  ul.navbar-nav {
  .nav-item {
    &.px-3.text-center {
        padding: 0 5px !important;
      }   
    }
  }
}
  .navbar {
    padding: 10px 0;
    //background: rgba(18, 34, 57, 0.5);
    background: #122239;
    position: relative;
    z-index: 12;

    &-toggler {
      width: auto !important;
      height: auto !important;
    }

    .collapsed {
      display: block;
    }

    &.navbar-menu {
      @media only screen and (max-width: 768px) {
        display: none;
      }
    }

    .logo {
      width: 153px;
      height: 48px;
    }

    ul.navbar-nav {
      .nav-item {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        padding-right: 15px;
        font-family: 'Overpass';

        &.active,
        &:hover {
          .nav-link {
            color: #007bff;
          }
        }
      }

      .nav-link {
        line-height: 0;
        color: #ffffff;
        text-transform: uppercase;
        display: inline;

        @media only screen and (max-width: 992px) {
          line-height: 24px;
        }
      }
    }

    .btn-register,
    .btn-login {
      width: 124px;
      height: 40px;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        font-weight: 600;
      }
    }

    .btn-login {
      background: transparent;
      border: 1px solid #007bff;
      margin-right: 16px;

      span {
        color: #007bff;
      }

      &:hover {
        background: #007bff;
        border: 1px solid #007bff;

        span {
          color: #ffffff;
        }
      }
    }

    .btn-register {
      background: #007bff;
      border: 1px solid #007bff;

      span {
        color: #ffffff;
      }

      &:hover {
        background: transparent;
        border: 1px solid #007bff;

        span {
          color: #007bff;
        }
      }
    }

    .form-mobile {
      display: none;

      @media only screen and (max-width: 992px) {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-right: 10px;
      }
    }

  }

  .collapse {
    max-width: 1200px;
    margin: 0 auto;
  }

  .user-logged {
    padding-right: 15px;
    position: relative;

    &.desktop {
      @media only screen and (max-width: 992px) {
        display: none;
      }
    }

    &__header {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .shop-cart {
        margin-right: 24px;
        position: relative;

        .number-cart {
          background: #007bff;
          border: 2px solid #ffffff;
          width: 17px;
          height: 17px;
          position: absolute;
          top: 0;
          right: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          color: #ffffff;
          font-size: 9px;
          line-height: 9px;
        }

        &:hover {
          cursor: pointer;
        }
      }

      .information {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .avatar {
          width: 40px;
          height: 40px;
          margin-right: 10px;
          border-radius: 50%;
        }

        .arrow-down {
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 10px solid #ffffff;
        }

        &__content {
          position: absolute;
          right: 5px;
          top: 55px;
          visibility: hidden;
          opacity: 0;
          z-index: 999;
          transition: all linear 0.2s;

          &.mobile {
            opacity: 1;
            visibility: visible;
            width: 480%;
            right: -12px;

            .packages {
              &:before {
                right: 45px;
              }
            }
          }

          .packages {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: #30353f;
            position: relative;
            border-radius: 8px 8px 0 0;

            &:before {
              position: absolute;
              content: "";
              width: 0;
              height: 0;
              border-left: 15px solid transparent;
              border-right: 15px solid transparent;
              border-bottom: 15px solid #30353f;
              top: -10px;
              right: 30px;
            }
          }

          .list-menu {
            position: relative;
            background: #30353f;
            padding: 0;
            margin: 0;
            border-radius: 0 0 8px 8px;

            .item {
              list-style: none;

              .nav-link {
                padding: 14px 28px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                height: 100%;

                span.icon {
                  &:before {
                    color: #ffffff;
                  }
                }

                span.name {
                  font-size: 16px;
                  line-height: 24px;
                  color: #ffffff;
                  display: inline-block;
                  white-space: nowrap;
                  padding-left: 14px;
                }

                &.active-user {
                  span.icon {
                    &:before {
                      color: #007bff;
                    }
                  }

                  span.name {
                    color: #007bff;
                  }
                }

                &.log-out {
                  span.icon {
                    &:before {
                      color: #e96058;
                    }
                  }

                  span.name {
                    color: #e96058;
                  }

                  @media only screen and (min-width: 992px) {
                    &:hover {
                      span.icon {
                        &:before {
                          color: #e96058;
                        }
                      }

                      span.name {
                        color: #e96058;
                      }
                    }
                  }
                }

                @media only screen and (min-width: 992px) {
                  &:hover {
                    span.icon {
                      &:before {
                        color: #007bff;
                      }
                    }

                    span.name {
                      color: #007bff;
                    }
                  }
                }
              }
            }
          }
        }

        @media only screen and (min-width: 550px) {
          &:hover {
            cursor: pointer;

            .information__content {
              visibility: visible;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

.text-view-cer {
  color: white;

  &:hover {
    color: #007bff
  }
}
.header-item {
  font-size: 15px;
  &:hover {
    background: radial-gradient( circle,
    rgb(163, 120,	58) 0%,
    rgb(238, 213,	137) 20%,
    rgb(250, 248,	218) 30%,
    rgb(238, 213,	137) 40%,
    rgb(238, 213,	137) 60%,
    rgb(249, 247,	218) 70%,
    rgb(238, 213,	137) 80%,
    rgb(164, 121,	58) 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

@media only screen and (max-width: 768px) {
  .header {
      position: sticky;
      top: 0;
      z-index: 9;
  }
}
</style>
