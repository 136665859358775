<template>
  <b-modal
    :id="config.id"
    :modal-class="config.class"
    :no-close-on-backdrop="config.noCloseOnBackDrop"
    hide-header
    :centered="config.centered"
    hide-footer
    ok-only
    no-fade
    :static="config.static"
    :lazy="config.lazy"
    :size="config.size || 'lg'"
    @hidden="$emit('hidden'), hideModal()"
    @shown="showModal"
  >
    <div
      class="modal-close"
      @click="hideModal"
      v-if="!config.notShowCloseButton"
    >
      <img
        :src="
          config.theme === 'light'
            ? '/img/icons/icon-close-black.svg'
            : '/img/icons/icon-close.svg'
        "
        alt="close-icon"
      />
    </div>
    <div v-if="image" class="modal-image">
      <slot name="image" />
    </div>
    <div class="modal-slot">
      <slot name="content" />
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "BaseModal",
  props: {
    config: {
      type: Object,
      default: () => {
        return {
          id: "",
          class: "",
          lazy: true,
          iconClose: "",
          static: false,
          theme: "",
          notShowCloseButton: false,
        };
      },
    },
  },
  data() {
    return {
      image: false,
    };
  },
  methods: {
    hideModal() {
      document.body.style.setProperty("overflow", null);
      this.$root.$emit("bv::hide::modal", this.config.id);
    },
    showModal() {
      this.$emit("show");
      this.image = true;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .modal {
  height: 100%;
  &-open {
    height: 100%;
  }
  .modal-close {
    top: 19.5px;
    right: 19.5px;
    position: absolute;
    color: #838a92;
    width: 19.5px;
    height: 19.5px;
    opacity: 1;
    cursor: pointer;
    z-index: 9;
  }
  .modal-content {
    border-radius: 8px;
    background: #ffffff;
  }
  &.show {
    padding-left: 0 !important;
    &.modal {
      transform: translateX(0);
      transition: all 0.4s linear;
    }
  }
  &-backdrop {
    // opacity: 0.8;
  }
  &.modal--login,
  &.modal--register,
  &.modal--forgot-pw,
  &.modal--confirmation-email,
  &.modal--reset-pw {
    .modal-dialog {
      max-width: 460px;
      width: 100%;
      margin: 0 auto;
      @media only screen and (max-width: 375px) {
        margin: 0;
      }
    }
    .modal-content {
      background: #30353f;
    }
  }
  &.modal--email-verification-confirmation {
    .modal-dialog {
      max-width: 460px;
      width: 100%;
      margin: 0 auto;
      @media only screen and (max-width: 375px) {
        margin: 0;
      }
    }
  }
  &.modal--pin-security-confirmation {
    .modal-dialog {
      max-width: 480px;
      width: 100%;
      margin: 0 auto;
      @media only screen and (max-width: 375px) {
        margin: 0;
      }
    }
  }
  &.modal--share-bonus {
    .modal-dialog {
      max-width: 568px;
      width: 100%;
      margin: 0 auto;
      @media only screen and (max-width: 375px) {
        margin: 0;
      }
    }
    .modal-body {
      padding: 32px;
    }
  }

  &.modal--create-account {
    .modal-dialog {
      max-width: 568px;
      width: 100%;
      margin: 0 auto;
      @media only screen and (max-width: 375px) {
        margin: 0;
      }
    }
    .modal-body {
      padding: 32px;
    }
  }
  &.modal--withdraw,
  &.modal--transfer,
  &.modal--convert-to-cash,
  &.modal--create-lending,
  &.modal--transfer-tron,
  &.modal--create-qr,
  &.modal--google-verification,
  &.modal--upload-documents {
    .modal-dialog {
      max-width: 568px;
      width: 100%;
      margin: 0 auto;
      @media only screen and (max-width: 375px) {
        margin: 0;
      }
    }
    .modal-body {
      padding: 32px;
    }
  }
  &.modal--receive {
    .modal-dialog {
      max-width: 407px;
      width: 100%;
      margin: 0 auto;
      @media only screen and (max-width: 375px) {
        margin: 0;
      }
    }
  }
  &.modal--update {
    .modal-dialog {
      max-width: 470px;
      width: 100%;
      margin: 0 auto;
      @media only screen and (max-width: 375px) {
        margin: 0;
      }
    }
  }
  &.modal--certificate {
    .modal-dialog {
      max-width: fit-content;
      width: 100%;
      margin: 0 auto;
      @media only screen and (max-width: 375px) {
        margin: 0;
      }
    }
    .modal-content {
      padding: 0;
      border-radius: 0;
      background: unset;
    }
    .modal-body {
      padding: 0 !important;
    }
  }
}
</style>
