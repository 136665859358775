import store from '@/store'

export default {
  state: {
   KYC: {}
  },
  getters: {
    KYC: state => state.KYC
  },
  mutations: {
    setKYC(state, payload) {
      state.KYC = payload
    },
    resetStore(state) {
      store.replaceState({
        auth: {
          currentUser: {},
          loginError: null,
          processing: false,
          forgotMailSuccess: null,
          resetPasswordSuccess: null,
          confirmRegisterSuccess: null,
          registerSuccess: null,
          staticUser: null,
          authenticator: null,
        },
        cart: {
          cart: {}
        },
        wallet: {
          wallet: {}
        },
        user: {
          KYC: {},
          overviewInfo: null
        },
        menu: {...store.state.menu}
      })
    }
  },
  namespaced: true
}
