import Swal from 'sweetalert2'
const {NOTIFICATION_ICON} = require("@/constants");
const GlobalNotificationPopup = {
  install(Vue) {
    const notify = (icon, title, desc, { action1Text= '', action2Text= '',  action1Func= null, action2Func= null, didCloseFunc = null  }, className, descHtml = "", contentClass = '', theme = 'dark') => {
      let iconUrl = ''
      switch (icon) {
        case NOTIFICATION_ICON.ERROR:
          iconUrl = '/img/icons/failed.png'
          break
        case NOTIFICATION_ICON.SUCCESS:
          iconUrl =  '/img/icons/ico-success.png'
          break
        default:
          iconUrl =  '/img/icons/ico-success.png'
      }
      const background = theme == 'light' ? '#ffffff' : '#30353F'
      Swal.fire({
        imageUrl: iconUrl,
        imageHeight: 80,
        imageWidth: 80,
        title: title,
        showCancelButton: !!action2Text,
        showCloseButton: true,
        text: desc,
        html: descHtml,
        customClass: {
          container: className,
          content: contentClass
        },
        background,
        confirmButtonText: action1Text,
        cancelButtonText: action2Text,
        onAfterClose: didCloseFunc
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          action1Func()
        } else if ( result.dismiss === Swal.DismissReason.cancel) {
          action2Func()
        }
      })
    }
    Vue.prototype.$notifyPopup = notify
  }
}

export default GlobalNotificationPopup




