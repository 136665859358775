import { currentUser, isAuthGuardActive } from '@/constants/config'
import { NOTIFICATION_ICON, MODAL } from "@/constants";
import { setCurrentUser, getCurrentUser } from '@/core/utils'
import { RepositoryFactory } from '@/apis/repositoryFactory'
import interceptor from '@/apis/repository'
const AuthRepository = RepositoryFactory.get('auth')
const UserRepository = RepositoryFactory.get('user')
const ShopRepository = RepositoryFactory.get('cart')
import get from 'lodash.get'
import router from "@/core/routes"
import store from '@/store'
import app from '@/main'
import isEmpty from "lodash/isEmpty"
import Swal from 'sweetalert2'
import moment from 'moment';

export default {
  state: {
    currentUser: isAuthGuardActive ? getCurrentUser() : {},
    loginError: null,
    processing: false,
    forgotMailSuccess: null,
    resetPasswordSuccess: null,
    confirmRegisterSuccess: null,
    registerSuccess: null,
    staticUser: null,
    authenticator: null,
    openLoginModal: false,
  },
  getters: {
    currentUser: state => state.currentUser,
    processing: state => state.processing,
    loginError: state => state.loginError,
    forgotMailSuccess: state => state.forgotMailSuccess,
    resetPasswordSuccess: state => state.resetPasswordSuccess,
    confirmRegisterSuccess: state => state.confirmRegisterSuccess,
    registerSuccess: state => state.registerSuccess,
    staticUser: state => state.staticUser,
    authenticator: state => state.authenticator,
  },
  mutations: {
    setUser(state, payload) {
      state.currentUser = payload
      state.processing = false
      state.loginError = null
    },
    setLogout(state) {
      state.currentUser = null
      state.processing = false
      state.loginError = null
    },
    setProcessing(state, payload) {
      state.processing = payload
      state.loginError = null
    },
    setError(state, payload) {
      state.loginError = payload
      state.currentUser = null
      state.processing = false
    },
    setForgotMailSuccess(state, payload) {
      state.loginError = null
      state.currentUser = null
      state.processing = false
      state.forgotMailSuccess = payload
    },
    setResetPasswordSuccess(state) {
      state.loginError = null
      state.currentUser = null
      state.processing = false
      state.resetPasswordSuccess = true
    },
    clearError(state) {
      state.loginError = null
    },
    setConfirmRegisterSuccess(state) {
      state.loginError = null
      state.currentUser = null
      state.processing = false
      state.confirmRegisterSuccess = true
    },
    setRegisterSuccess(state, payload = true) {
      state.loginError = null
      state.currentUser = null
      state.processing = false
      state.registerSuccess = payload
    },
    updateCurrentUser(state, payload) {
      state.currentUser = { ...state.currentUser, ...payload }
      setCurrentUser({ ...state.currentUser, ...payload })
    },
    setStaticUser(state, payload) {
      state.staticUser = payload
    },
    setAuthenticator(state, payload) {
      state.authenticator = payload
    },
    resetStore(state) {
      store.replaceState({
        Auth: {
          currentUser: {},
          loginError: null,
          processing: false,
          forgotMailSuccess: null,
          resetPasswordSuccess: null,
          confirmRegisterSuccess: null,
          registerSuccess: null,
          staticUser: null,
          authenticator: null,
        },
        cart: {
          cart: {}
        },
        wallet: {
          wallet: {}
        },
        user: {
          KYC: {},
          overviewInfo: null
        },
        menu: { ...store.state.menu }
      })
    }
  },
  actions: {
    login({ commit, dispatch }, payload) {
      commit('clearError')
      commit('setProcessing', true)
      AuthRepository.login(payload).then(
        user => {
          commit('setAuthenticator', user.AuthenticationTypes)
          setCurrentUser(user)

          const userinf = getCurrentUser()
          const token = get(userinf, 'Token', null)
          if (!!token) {
            commit('setUser', user)
            interceptor.defaults.headers.common['Authorization'] = `Bearer ${token}`
            UserRepository.getMyInformation().then(
              async user => {
                const isRedirectBefore = false;
                setCurrentUser({ ...user, ...userinf })
                commit('setUser', { ...user, ...userinf })
                if (localStorage.getItem('redirectAfterLogin') && !isRedirectBefore) {
                  router.push(localStorage.getItem('redirectAfterLogin'))
                  localStorage.removeItem('redirectAfterLogin')
                } else {
                  if (user.NeedPayInterestCredit) {
                    if (!isRedirectBefore) {
                      router.push('/dashboards');
                    }
                    const html = `<div class="card-with-title">
                    <div class="card-title">
                      <h3 class="text-gradient">You have a borrow need payment</h3>
                    </div>
                      <div class="text-left h3 mt-3">
                        <p class="text-white mb-2">Please pay your interest</p>
                      </div>
                    </div>`
                    const result = await app.$swal({
                      customClass: {
                        popup: 'bg-common',
                      },
                      html,
                      showCloseButton: true,
                      showCancelButton: true,
                      confirmButtonText: 'Ok'
                    })
                    if (result.isConfirmed) {
                      router.push('/wallet/loan');
                    }
                  } else {
                    if (!isRedirectBefore) {
                      router.push('/dashboards');
                    }
                  }
                }
              }
            )
            ShopRepository.getMyCart().then(
              carts => {
                const numberCart = carts.CartItems.reduce(function (a, b) {
                  return a + b['Quantity'];
                }, 0)
                commit('setMyCart', numberCart, { root: true })
              }
            )
            dispatch('fetchWallet', {}, { root: true })
            document.getElementsByTagName('html')[0].classList.remove('modal-open')
          }
          commit('setProcessing', false)
        },
        err => {
          commit('setProcessing', false)
          setCurrentUser(null);
          if (err.response.data && err.response.data.status === "LOCK") {
            app.$notifyPopup(NOTIFICATION_ICON.ERROR,
              'Oops',
              null,
              {
                action1Text: 'OK',
                action1Func: 'OK',
              },
              "",
              err.response.data.message
            )
            return
          }
          app.$alertMsg('fail', err.response.data.message)
        }
      )
    },
    forgotPassword({ commit }, payload) {
      commit('clearError')
      commit('setProcessing', true)
      AuthRepository.forgotPassword(payload).then(
        success => {
          commit('setForgotMailSuccess', true)
        }
      ).catch(
        err => {
          commit('setError', err.message)
          app.$alertMsg('fail', err.response.data.message)
        }

      ).finally(function () {
        commit('clearError')
        commit('setForgotMailSuccess', false)
      }
      )
    },
    resetPassword({ commit }, payload) {
      commit('clearError')
      commit('setProcessing', true)
      const { Password, PasswordAgain, RecaptchaToken } = payload
      AuthRepository.resetPassword(payload.token, { Password, PasswordAgain, RecaptchaToken }).then(
        user => {
          commit('clearError')
          commit('setResetPasswordSuccess')
        },
        err => {
          commit('setError', err.message)
          setTimeout(() => {
            commit('clearError')
          }, 3000)
        }
      )
    },
    confirmRegister({ commit }, payload) {
      commit('clearError')
      commit('setProcessing', true)
      AuthRepository.confirmRegister(payload).then(
        user => {
          commit('clearError')
          commit('setConfirmRegisterSuccess')
        },
        err => {
          setCurrentUser(null);
          // app.$alertMsg('fail','Something went wrong!')
          app.$alertMsg('fail', err.response.data.errors.token)
        }
      )
    },

    signOut({ commit }, payload) {
      setCurrentUser(null)
      AuthRepository.logout().then(
        user => {
          commit('resetStore')
          commit('setLogout')
          const redirect = payload ? `/home?modal=${MODAL.LOGIN}` : '/home'
          window.location = redirect
        },
        err => {
          commit('setError', err.message)
          setTimeout(() => {
            commit('clearError')
          }, 3000)
        }
      )
    },

    signUp({ commit }, payload) {
      commit('clearError')
      commit('setProcessing', true)
      AuthRepository.signUp(payload).then(
        user => {
          commit('setRegisterSuccess')
        },
        err => {
          app.$alertMsg('fail', err.response.data.message)
        }
      )
      setCurrentUser(null);
    },

    async getStatisticUser({ commit }) {
      try {
        const data = await UserRepository.getStatisticUser();
        commit('setStaticUser', data)
      } catch (error) {
        commit('setStaticUser', null)
      }
    }
  },
  namespaced: true
}
