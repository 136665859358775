<template>
  <div
    style="background: #141822"
    class="wrapcenter d-flex justify-content-center align-items-center"
  >
    <div class="text-center position-relative">
      <div>
        <p class="text-white notfound">{{ $t("404.title") }}</p>
        <p class="text-white notfound-sub">{{ $t("404.sub") }}</p>
        <!-- <b-button variant="primary" @click="$router.push('/')" class="default">Back to home</b-button> -->
      </div>
      <div class="position-absolute centered">
        <img
          src="/assets/img/404.svg"
          alt="certificate"
          class="img-404 w-auto container"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  watch: {
    'isLogged':{
      handler(newVal, oldVal) {
        // if(this.$route.path === '/app/home') {
        //   this.$router.push('/home')
        // }
        if(newVal !== oldVal) {
          this.$router.push('/home')
        }
      },
    },
  }
};
</script>
<style scoped>
.wrapcenter {
  height: calc(100vh - 190px);
}
.notfound {
  font-weight: 700;
  font-size: 48px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.25px;
  color: rgba(255, 255, 255, 0.9);
}
.notfound-sub {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: center;
  max-width: 430px;
  color: rgba(255, 255, 255, 0.75);
}
.centered {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 768px) {
  .wrapcenter {
    height: calc(100vh - 142px);
  }
}
@media (max-width: 576px) {
  .notfound {
    font-size: 30px;
  }
  .img-404.container {
    width: 100vw !important;
  }
}
</style>