export default {
  'name-1': 'Starter',
  'description-1': 'F1 direct sponsorship package receive 10% direct commission of total AP points',

  'name-2': 'Super star',
  'description-2': 'F1 direct sponsorship package receive 12.5% direct commission of total AP points',

  'name-3': 'VUSD 1k',
  'description-3': 'F1 direct sponsorship package receive 15% direct commission of total AP points',

  'name-4': 'VUSD 2k',
  'description-4': 'F1 direct sponsorship package receive 17.5% direct commission of total AP points',

  'name-5': 'VUSD 5k',
  'description-5': 'F1 direct sponsorship package receive 20% direct commission of total AP points',

  'name-6': 'VUSD 10k',
  'description-6': 'F1 direct sponsorship package receive 25% direct commission of total AP points',

  'name-7': 'VUSD 20k',
  'description-7': 'F1 direct sponsorship package receive 30% direct commission of total AP points',

  'name-8': 'VUSD 50k',
  'description-8': 'F1 direct sponsorship package receive 30% direct commission of total AP points',

  'name-9': 'VUSD 100k',
  'description-9': 'F1 direct sponsorship package receive 30% direct commission of total AP points',

  'name-10': 'VLINK MERCHANT META',
  'description-10': 'Shareholders packages participating in direct sponsorship of F1 receives 25% direct commission of total AP points',

  'name-11': 'PRO BUSINESS',
  'description-11': 'Business packages participating in direct sponsorship of F1 receive 20% direct commission of total AP points',

  'name-12': 'PLATINUM BUSINESS',
  'description-12': 'Business packages participating in direct sponsorship of F1 receive 15% direct commission of total AP points',

  'name-13': 'BRONZE BUSINESS',
  'description-13': 'Business packages participating in direct sponsorship of F1 receive 10% direct commission of total AP points',
}
