<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.88461 4H19.1154C20.706 4 22 5.29402 22 6.88461V17.6538C22 19.2444 20.706 20.5384 19.1154 20.5384H4.88461C3.29402 20.5384 2 19.2444 2 17.6538V6.88461C2 5.29402 3.29402 4 4.88461 4ZM16.6154 14H20.8462V10.5384H16.6154C15.661 10.5384 14.8846 11.3148 14.8846 12.2692C14.8846 13.2236 15.6611 14 16.6154 14ZM16.6154 9.38461H20.8462V6.88461C20.8462 5.93023 20.0697 5.15383 19.1154 5.15383H4.88461C3.93023 5.15383 3.15383 5.93027 3.15383 6.88461V17.6538C3.15383 18.6082 3.93027 19.3846 4.88461 19.3846H19.1154C20.0698 19.3846 20.8462 18.6082 20.8462 17.6538V15.1538H16.6154C15.0248 15.1538 13.7308 13.8598 13.7308 12.2692C13.7308 10.6786 15.0248 9.38461 16.6154 9.38461ZM16.616 11.6919H17.3852C17.7038 11.6919 17.9621 11.9502 17.9621 12.2688C17.9621 12.5874 17.7038 12.8457 17.3852 12.8457H16.616C16.2974 12.8457 16.0391 12.5874 16.0391 12.2688C16.0391 11.9502 16.2973 11.6919 16.616 11.6919Z"
      :fill="!active ? '#738CB0' : '#ffffff'"
    />
  </svg>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
</style>