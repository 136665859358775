<template>
  <BaseModal :config="config" @hidden="cancel">
    <template slot="content">
      <div class="modal-transfer__title">
        {{ $t("titles.transfer") }}
        {{
          walletName && walletName.label && activeTab == 'INTERNAL' ? walletName.label : ""
        }}
      </div>
      <div class="modal-transfer__content">
        <div class="modal-transfer__content--tabs">
          <span
            class="transfer-tab"
            @click="activeTab = 'INTERNAL'"
            :class="{ active: activeTab === 'INTERNAL' }"
            >{{ $t("titles.internal") }}</span
          >
          <span
            class="transfer-tab"
            @click="activeTab = 'EXTERNAL'"
            :class="{ active: activeTab === 'EXTERNAL' }"
            >{{ $t("titles.external") }}</span
          >
        </div>
        <div class="modal-transfer__content--content">
          <TransferInternal
            :walletType="walletType"
            v-if="activeTab === 'INTERNAL'"
            @cancel="cancel"
            @changeWaletType="updateWalletName"
            @refresh="$emit('refresh')"
          />
          <TransferExternal
            :walletType="walletType"
            v-if="activeTab === 'EXTERNAL'"
            @cancel="cancel"
            @refresh="$emit('refresh')"
          />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { MODAL, WALLET_TRANSFER } from "@/constants";
import globalMixin from "@/core/mixins/global";
import TransferInternal from "./transfer-internal.vue";
import TransferExternal from "./transfer-external.vue";

export default {
  name: "ModalTransfer",
  components: {
    TransferInternal,
    TransferExternal,
  },
  mixins: [globalMixin],
  props: {
    walletType: {
      type: String,
      default: "",
    },
  },
  computed: {
    walletName() {
      return this.walletType
        ? WALLET_TRANSFER.find((i) => this.walletType == i.value)
        : "";
    },
  },
  data() {
    return {
      config: {
        id: MODAL.TRANSFER_HOME,
        class: "modal--transfer",
        noCloseOnBackDrop: false,
        centered: true,
        theme: "light",
      },
      activeTab: "INTERNAL",
    };
  },
  methods: {
    updateWalletName (e) {
      this.walletType = e
    },
    cancel() {
      this.activeTab = "INTERNAL"
      this.$bvModal.hide(this.config.id);
    },
  },
};
</script>

<style scoped lang="scss">
.modal-transfer {
  &__title {
    font-family: "Overpass";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    text-align: center;
    color: #141822;
    margin-bottom: 32px;
  }
  &__content {
    &--tabs {
      padding-bottom: 1rem;
      border-bottom: 1px solid #eaeaea;
      position: relative;
      span {
        font-size: 20px;
        line-height: 28px;
        color: #43464e;
        cursor: pointer;
        position: relative;
        &:first-child {
          margin-right: 48px;
        }
        &.active {
          color: #007bff;
          &::before {
            content: "";
            width: 100%;
            height: 1px;
            background: #007bff;
            position: absolute;
            bottom: -17px;
          }
        }
      }
    }
  }
}
</style>
