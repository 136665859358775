<template>
  <div class="transfer-vio">
    <!-- <h4 class="transfer-vio__title">
      {{ $t("titles.transfer-external", [fiatSelected]) }}
      <a href='http://vlgtoken.com'>vlgtoken.com</a>
    </h4> -->
    <div class="transfer-vio__content">
      <BaseSelect
        ref="type"
        :input-value="platformSelected"
        name="type"
        :label="$t('wallet.platform')"
        :items="platform"
        :is-show-default="false"
        required
        classLabel="mh-16px"
        key-loop="index"
        class="position-relative"
        @change="changePlatform"
      />
      <BaseInputCustom
        class-name="address-wallet theme-light"
        ref="address"
        v-model="formData.WalletAddress"
        type="text"
        name="address"
        required
        :label="$t('wallet.address')"
        @input="handleInput"
      >
        <span v-if="walletNotFoundError" class="text-danger small">{{
          $t("wallet.address-not-found")
        }}</span>
        <span
          @click.prevent="scanEnabled = true"
          class="position-absolute d-md-none"
          style="right: 10px; top: 50%"
        >
          <img src="./scan.svg" alt="" />
        </span>
      </BaseInputCustom>
      <BaseSelect
        ref="type"
        :input-value="fiatSelected"
        name="type"
        :label="$t('wallet.wallet-type')"
        :items="amountType"
        :is-show-default="false"
        required
        classLabel="mh-16px"
        key-loop="index"
        class="position-relative"
        @change="changeType"
      />
      <BaseInputCustom
        class-name="theme-light"
        ref="amount"
        v-model="formData.Amount"
        type="tel"
        :limitInput="20"
        name="amount"
        required
        thousands-separator-symbol=","
        :label="$t('fields.Amount')"
        @input="debounceInput"
      >
        <small class="text-body mt-1">
          {{ $t("wallet.avaible-balance") }}:
          <strong
            >{{ wallet[link].Amount | toCurrency }} {{ fiatSelected }}</strong
          >
        </small>
      </BaseInputCustom>
      <BaseInputCustom
        class-name="theme-light"
        ref="message"
        v-model="formData.Reason"
        type="textarea"
        name="mesage"
        required
        :limit-input="50"
        :placeholder="$t('placeholder.textarea.max')"
        :label="$t('transfer.message')"
        rows="3"
      />
      <BaseInputCustom
        class-name="theme-light"
        ref="pin"
        v-model="formData.Pin"
        name="pin"
        :isPin="true"
        inputmode="numeric"
        required
        :label="$t('transfer.pin')"
      />
    </div>
    <p class="text-note" v-if="fetchedFee && fee != 0">
      <span
        >{{ $t("payments.methods.fee") }}:
        <template v-if="fetchedFee">{{ fee | toCurrency }} </template>
        <template v-else
          ><span class="spinner-border spinner-border-sm"
        /></template>

        <template v-if="walletSelected && walletSelected.label">
          {{ walletSelected.label }}
        </template>
      </span>
      <a class="ml-1" href="/faq" target="_blank"
        ><svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 2C6.47301 2 2 6.4725 2 12C2 17.5269 6.4725 22 12 22C17.527 22 22 17.5275 22 12C22 6.47309 17.5275 2 12 2ZM12 20.6046C7.25539 20.6046 3.39535 16.7446 3.39535 12C3.39535 7.25535 7.25539 3.39535 12 3.39535C16.7446 3.39535 20.6046 7.25535 20.6046 12C20.6046 16.7446 16.7446 20.6046 12 20.6046Z"
            fill="#ADAEB2"
          />
          <path
            d="M12.0018 10.3359C11.4095 10.3359 10.9883 10.5861 10.9883 10.9546V15.9697C10.9883 16.2857 11.4095 16.6015 12.0018 16.6015C12.5679 16.6015 13.0285 16.2857 13.0285 15.9697V10.9546C13.0285 10.5861 12.5679 10.3359 12.0018 10.3359Z"
            fill="#ADAEB2"
          />
          <path
            d="M12.0012 7.24219C11.3957 7.24219 10.9219 7.67656 10.9219 8.17676C10.9219 8.67699 11.3957 9.12453 12.0012 9.12453C12.5936 9.12453 13.0675 8.67699 13.0675 8.17676C13.0675 7.67656 12.5935 7.24219 12.0012 7.24219Z"
            fill="#ADAEB2"
          />
        </svg>
      </a>
    </p>
    <p class="text-note">
      {{ $t("stock.pin.first") }}
      <a
        target="_blank"
        href="/profile/security?tab=pin"
        style="color: #007bff; font-weight: bold"
        >{{ $t("stock.pin.second") }}</a
      >{{ $t("stock.pin.third") }}
    </p>
    <div class="transfer-vio__action">
      <button
        class="btn btn-primary btn-round transfer-vio__btn-cancel"
        @click="cancel"
      >
        <span>{{ isPage ? $t("transfer.Clean") : $t("action.cancel") }}</span>
      </button>
      <button
        :disabled="isDisabledButton()"
        class="btn btn-primary transfer-vio__btn-create"
        :class="{ 'button--loading': loading }"
        @click="submit"
      >
        <span>{{ $t("action.transfer") }}</span>
      </button>
    </div>
    <div class="check-in" v-if="scanEnabled">
      <div class="container">
        <div class="row">
          <div class="scan-camera">
            <div class="scan-camera__header">
              <p class="text-white position-absolute">Scan QR Code</p>
              <img
                src="./arrow-left.svg"
                alt="trend-up"
                class="arrow-left"
                @click="scanEnabled = false"
              />
            </div>
            <qrcode-stream :camera="'auto'" @decode="onDecode" @init="onInit">
              <img src="./group.png" alt="" class="group-camera" />
            </qrcode-stream>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BaseSelect from "@/components/Inputs/BaseSelect.vue";
import { MODAL, NOTIFICATION_ICON, WALLET_TRANSFER } from "@/constants";
import BaseInputCustom from "@components/Inputs/BaseInputCustom";
import validateMixin from "@/core/mixins/validate";
import { RepositoryFactory } from "@apis/repositoryFactory";
const WalletRepository = RepositoryFactory.get("wallet");
import moment from "moment";
import { QrcodeStream } from "vue-qrcode-reader";
import WAValidator from "@getdelta/wallet-address-validator";
import debounce from "lodash.debounce";

export default {
  name: "ModalTransferVio",
  components: {
    BaseInputCustom,
    BaseSelect,
    QrcodeStream,
  },
  props: {
    isPage: {
      type: Boolean,
      default: false,
    },
    walletType: {
      type: String,
      default: "",
    },
  },
  mixins: [validateMixin],
  data() {
    return {
      isAllowTransferVlinkPay: false,
      formData: {
        Amount: "",
        WalletType: "VLG",
        Pin: "",
        Reason: "",
        WalletAddress: "",
        Platform: "VLINKPAY",
      },
      amountType: ["VLG"],
      vlinkPayAmountType: ["VLG"],
      fullAmountType: ["VLG", "VTP", "USDT"],
      platform: ["VLINKPAY", "VLGTOKEN.COM"],
      platformSelected: "VLINKPAY",
      fiatSelected: "VLG",
      loading: false,
      WALLET_TRANSFER,
      result: "",
      camera: "auto",
      showScanConfirmation: false,
      link: "VLGTokenAvailable",
      scanEnabled: false,
      debounceTimer: null,
      walletNotFoundError: false,
      isNotFound: false,
      fetchedFee: true,
      fee: 0,
    };
  },
  computed: {
    ...mapGetters({
      wallet: "getWallet",
    }),
    walletSelected() {
      return WALLET_TRANSFER.find((i) => this.type == i.value);
    },
  },
  created() {
    WalletRepository.checkAllowTransferVlinkPay().then((res) => {
      this.isAllowTransferVlinkPay = res;
      if (!this.isAllowTransferVlinkPay) {
        this.platform = ["VLGTOKEN.COM"];
        this.formData.Platform = "VLGTOKEN.COM";
        this.platformSelected = "VLGTOKEN.COM";
        this.amountType = this.fullAmountType;
      }
    });
  },
  mounted() {
    if (this.walletType === "VLGPlus") {
      this.type = "VTP";
      this.fiatSelected = this.type;
      this.formData.WalletType = this.type;
    }
  },
  methods: {
    debounceInput: debounce(function (e) {
      this.fetchFee();
    }, 500),
    isValidHttpUrl(string) {
      let url;
      try {
        url = new URL(string);
      } catch (_) {
        return false;
      }
      return url.protocol === "http:" || url.protocol === "https:";
    },
    isDisabledButton() {
      return (
        !(
          this.formData.Amount &&
          this.formData.Reason &&
          this.formData.Pin &&
          this.formData.WalletAddress
        ) ||
        !this.fetchedFee ||
        this.isNotFound
      );
    },
    handleInput() {
      clearTimeout(this.debounceTimer);

      this.debounceTimer = setTimeout(() => {
        this.checkWalletAddress();
        this.fetchFee();
      }, 500);
    },
    async checkWalletAddress() {
      if (this.formData.Platform === "VLGTOKEN.COM") {
        this.walletNotFoundError = false;
        this.isNotFound = false;
        return;
      }
      const isWalletValid = await WalletRepository.checkWallet(
        this.formData.WalletAddress
      );

      if (!isWalletValid) {
        this.walletNotFoundError = true;
        this.isNotFound = true;
      } else {
        this.walletNotFoundError = false;
        this.isNotFound = false;
      }
    },
    async fetchFee() {
      try {
        this.fetchedFee = false;
        const data = {
          toWalletAddress: this.formData.WalletAddress,
          amount: this.formData.Amount,
          transferToVlinkPay:
            this.formData.Platform === "VLINKPAY" ? true : false,
        };
        if (data.toWalletAddress && data.amount) {
          const typeWallet = WALLET_TRANSFER.find((i) => this.link == i.value);
          const response = await WalletRepository.fetchFeeWalletByType(
            typeWallet.url.replace(/[^\x00-\x7F]/g, ""),
            data
          );
          this.fee = response.Fee;
        }
      } catch (error) {
      } finally {
        this.fetchedFee = true;
      }
    },
    changeType($event) {
      this.type = $event;
      this.fiatSelected = this.type;
      this.formData.WalletType = this.type;
      switch (this.fiatSelected) {
        case "VTP":
          this.link = "VLGPlus";
          break;
        case "USDT":
          this.link = "Usdt";
          break;
        default:
          this.link = "VLGTokenAvailable";
          break;
      }
    },
    changePlatform($event) {
      this.platformSelected = $event;
      this.formData.Platform = this.platformSelected;
      if (this.platformSelected === "VLINKPAY") {
        this.fiatSelected = "VLG";
        this.amountType = this.vlinkPayAmountType;
      } else {
        this.fiatSelected = "VLG";
        this.amountType = this.fullAmountType;
      }
      this.checkWalletAddress();
    },
    async onDecode(content) {
      try {
        const addressValid = WAValidator.validate(content, "TRON");
        if (!addressValid) {
          throw "address invalid";
        }
        this.formData.WalletAddress = content;
        this.scanEnabled = false;
      } catch (err) {
        this.$alertMsg("fail", this.$t("swal.qr-fail"));
        this.pause();
        setTimeout(() => this.unpause(), 100);
      }
    },
    unpause() {
      this.camera = "auto";
    },
    pause() {
      this.camera = "off";
    },
    async onInit(promise) {
      try {
        await promise;
      } catch (e) {
        console.error(e);
      } finally {
        this.showScanConfirmation = this.camera === "off";
      }
    },
    cancel() {
      if (!this.isPage) {
        this.$emit("cancel");
        return;
      }
      this.formData = {
        Amount: "",
        WalletType: "VLG",
        Pin: "",
        Reason: "",
        WalletAddress: "",
      };
      const refs = ["address", "type", "amount", "message", "pin"];
      this.$_validateMixin_reset(refs);
    },
    async submit() {
      const refs = ["address", "type", "amount", "message", "pin"];
      const refsValid = this.$_validateMixin_refs(refs);
      if (refsValid) {
        this.loading = true;
        try {
          if (this.formData.Platform === "VLINKPAY") {
            const response = await WalletRepository.transferToVlinkPay(
              this.formData
            );
          } else {
            const response = await WalletRepository.convertVLGTokenToVIO(
              this.formData
            );
          }
          const descHtml = `<div class="transaction-sucess_detail">
            <p class="d-flex justify-content-between">
              <span class="text-note text-nowrap">${this.$t(
                "user.receiver"
              )}:   </span>
              <span
              style="
                width: 250px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;"
                class="text-note-2">${this.formData.WalletAddress}</span>
            </p>
            <p class="d-flex justify-content-between">
              <span class="text-note text-nowrap">${this.$t(
                "fields.Amount"
              )}:   </span>
              <span class="text-note-2">${this.formData.Amount} VLG</span>
            </p>
            <p class="d-flex justify-content-between">
              <span class="text-note text-nowrap">${this.$t(
                "fields.DateTime"
              )}:</span>
              <span class="text-note-2">${moment().format(
                "h:mm A, MMM Do YYYY"
              )}</span>
            </p>
            <p class="d-flex justify-content-between mb-0">
              <span class="text-note text-nowrap">${this.$t(
                "transfer.message"
              )}:</span>
              <span
              style="
                width: 250px;
                text-overflow: ellipsis;
                overflow: hidden;
                text-align: right;
                white-space: nowrap;"
              class="text-note-2">${this.formData.Reason}</span>
            </p>
          </div>`;
          this.$notifyPopup(
            NOTIFICATION_ICON.SUCCESS,
            this.$t("action.transfered"),
            "",
            {
              action1Text: this.$t("transfer.close"),
              action1Func: () => this.cancel(),
              action2Text: this.$t("transfer.history"),
              action2Func: () => {
                this.cancel();
                this.$router.push(`/wallet/detail?code=${this.link}`);
              },
            },
            "light-theme",
            descHtml,
            "px-0",
            "light"
          );
        } catch (error) {
          this.$notifyPopup(
            NOTIFICATION_ICON.ERROR,
            this.$t("swal.title.fail"),
            error.response.data.message,
            {
              action1Text: this.$t("action.try"),
              action1Func: () => {},
            }
          );
        }
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.transfer-vio {
  color: #ffffff;
  margin-top: 24px;
  &__title {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #43464e;
    margin-bottom: 24px;
  }
  &__password {
    margin-top: 15px;
  }
  &__btn {
    height: 40px;
  }
  &__action {
    display: grid;
    grid-template-columns: 47% 47%;
    justify-content: space-between;
  }
  &__btn-cancel {
    color: #007bff;
    background: #ffffff;
    border: 1px solid #007bff;
    &:hover {
      background: #007bff;
      color: #ffffff;
    }
  }
  .wallet-type {
    margin-bottom: 20px;
    width: 100%;
    &__title {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #43464e;
      margin-bottom: 8px;
    }
    &__value {
      width: 100%;
      padding: 8px 12px;
      background: #e0e0e0;
      border-radius: 8px;
      color: #43464e;
      position: relative;
      i {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 19px;
        font-size: 20px;
        .arrow-down {
          color: #ffffff;
          display: block;
          transition: all 0.2s;
        }
      }
    }
  }
}
::v-deep .eye {
  color: #43464e !important;
}
::v-deep .base-select {
  margin-bottom: 20px;
  &__label {
    color: #43464e;
  }
}
::v-deep .base-select__wrap {
  background: #f7f7f7;
  .arrow-down {
    color: #43464e;
  }
}
::v-deep .base-select__inner {
  p {
    color: #141822;
  }
}
.text-note {
  font-size: 14px;
  line-height: 20px;
  color: #898b91;
}
.text-note-2 {
  width: 250px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
/deep/ textarea {
  background: #f7f7f7;
  border-radius: 8px;
  outline: none !important;
  border: none;
  font-family: "Overpass";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #141822;
  padding: 10px;
}
::v-deep .base-select__options {
  background: #ffffff;
  .base-select__item {
    position: relative;
    &.active {
      position: relative;
      &::before {
        display: block;
        content: "";
        width: 20px;
        height: 20px;
        background-image: url("/img/wallet/icon-check.svg");
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 8px;
      }
    }
    &:hover {
      background: #f7f7f7;
      color: #101828;
    }
    .base-select__link {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #101828;
      border: none;
      border-top: none !important;
      &:hover {
        text-decoration: none !important;
      }
    }
  }
}

.check-in {
  background: url("./background.png") no-repeat center;
  background-size: cover;
  .scan-camera {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 56px;
    width: 100%;
    height: 100%;
    background: black;
    z-index: 10;
    .scan-camera__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // padding: 15px 16px;
      // border-bottom: 1px solid #43464E;
      p {
        margin: 0;
        font-weight: 700;
        font-size: 24px;
        line-height: 34px;
        z-index: 9;
        top: 30px;
        left: 50%;
        transform: translate(-50%);
      }
    }
  }
  .arrow-left {
    position: absolute;
    left: 10px;
    top: 30px;
    width: 30px;
    z-index: 10;
  }
  .group-camera {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.check-in-title {
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  text-align: center;
  color: #141822;
}
.qrcode-stream-wrapper {
  width: 100%;
  height: 100vh;
}
.action {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  .scanner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
}
.btn-disabled {
  pointer-events: none;
  opacity: 0.6;
}
.address-wallet {
  /deep/.base-input-custom__wrap {
    input {
      padding: 8px 40px 8px 12px;
    }
  }
}
</style>
