import {
  formatNumber,
  formatDateNoOffset,
  formatDate,
  formatDateTime,
  toCurrency,
  toCryptocurrency,
  formatMarkToNumber,
  formatAmountUnitEvent,
  formatValueCurrency
 } from '@/core/filters';

const GlobalFilters = {
  install (Vue) {
    Vue.filter('formatDate', formatDate );
    Vue.filter('formatDateNoOffset', formatDateNoOffset );
    Vue.filter('formatNumber', formatNumber );
    Vue.filter('formatDateTime', formatDateTime );
    Vue.filter('toCurrency', toCurrency );
    Vue.filter('toCryptocurrency', toCryptocurrency );
    Vue.filter('formatMarkToNumber', formatMarkToNumber );
    Vue.filter('formatAmountUnitEvent', formatAmountUnitEvent);
    Vue.filter('formatValueCurrency', formatValueCurrency );
  }
}

export default GlobalFilters
