import BaseInput from '@/components/Inputs/BaseInput.vue'
import BaseCheckbox from '@/components/Inputs/BaseCheckbox.vue'
import BaseRadio from '@/components/Inputs/BaseRadio.vue'
import BaseDropdown from '@/components/BaseDropdown.vue'
import Card from '@/components/Cards/Card.vue'
import BaseModal from "@/components/Modal/BaseModal.vue"
import Background from "@/components/Background.vue"
import VueTree from '@/components/Tree.vue'
import DatePicker from 'vuejs-datepicker'
import VueCountdown from '@chenfengyuan/vue-countdown';


/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install (Vue) {

    Vue.component(VueCountdown.name, VueCountdown);
    Vue.component('DatePicker', DatePicker)
    Vue.component(BaseInput.name, BaseInput)
    Vue.component(BaseCheckbox.name, BaseCheckbox)
    Vue.component(BaseRadio.name, BaseRadio)
    Vue.component(BaseDropdown.name, BaseDropdown)
    Vue.component(BaseModal.name, BaseModal)
    Vue.component('vue-tree', VueTree)
    Vue.component('card', Card)
    Vue.component('vue-background', Background)
  }
}

export default GlobalComponents
