import repository from './repository'

const resource = '/kyc'

const kycResource = '/link-kyc'

export default {
  uploadKYC(payload) {
    return repository.post(`${resource}/submit-kyc`, payload)
  },
  getKYCSubmitted() {
    return repository.get(`${resource}/kyc-submitted`)
  },
  getKYCSubmittedDetails(id) {
    return repository.get(`${resource}/${id}`)
  },
  getMyLinked() {
    return repository.get(`${kycResource}/my-linked`)
  },
  getMyLinkTree(id) {
    return repository.get(`${kycResource}/linked-kyc-tree`)
  },
  getMyLinkRequest() {
    return repository.get(`${kycResource}/my-linking-request`)
  },
  requestLinkKyc(payload) {
    return repository.post(`${kycResource}/submit`, payload)
  },
  approveLinkKyc(payload) {
    return repository.post(`${kycResource}/approve`, payload)
  },
  rejectLinkKyc(payload) {
    return repository.post(`${kycResource}/reject`, payload)
  },
  revokeLinkKyc(payload) {
    return repository.post(`${kycResource}/revoke`, JSON.stringify(payload))
  },
  checkStatusLinkKyc() {
    return repository.get(`${kycResource}/status`)
  }
}
