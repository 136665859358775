<template>
  <div class="form-check" :class="{disabled: disabled}">
    <label :for="cbId" class="form-check-label">
      <input :id="cbId"
             class="form-check-input"
             type="checkbox"
             :disabled="disabled"
             v-model="model" />
      <span class="form-check-sign"></span>
    </label>
    <span>
      <slot></slot>
    </span>

  </div>
</template>
<script>
  export default{
    name: 'base-checkbox',
    model: {
      prop: 'checked'
    },
    props: {
      checked: [Array, Boolean],
      disabled: [Boolean, String],
      inline: Boolean
    },
    data () {
      return {
        cbId: ''
      }
    },
    computed: {
      model: {
        get () {
          return this.checked
        },
        set (check) {
          this.$emit('input', check)
        }
      },
      inlineClass () {
        if (this.inline) {
          return `checkbox-inline`
        }
      }
    },
    created () {
      this.cbId = Math.random().toString(16).slice(2)
    }
  }
</script>
<style lang="scss" scoped>
.from-check,
.form-check-radio {
  margin-bottom: 12px;
  position: relative;

}

.form-check{
  padding-left: 0;
  .form-check-label{
    display: inline-block;
    position: relative;
    cursor: pointer;
    padding-left: 35px;
    line-height: 26px;
    margin-bottom: 0;
  }

  .form-check-sign::before,
  .form-check-sign::after{
    font-family: 'FontAwesome';
    content: "\f096";
    display: inline-block;
    color: #007BFF;
    position: absolute;
    width: 19px;
    height: 19px;
    margin-top: -12px;
    margin-left: -23px;
    font-size: 21px;
    cursor: pointer;
    -webkit-transition: opacity 0.3s linear;
    -moz-transition: opacity 0.3s linear;
    -o-transition: opacity 0.3s linear;
    -ms-transition: opacity 0.3s linear;
    transition: opacity 0.3s linear;
  }
  .form-check-sign::after{
    font-family: 'FontAwesome';
    content: "\f046";
    text-align: center;
    opacity: 0;
    color: #007BFF;
    border: 0;
    background-color: inherit;
  }
  &.disabled{
    .form-check-label{
      color: #9A9A9A;
      opacity: .5;
      cursor: not-allowed;
    }
  }

}

.form-check.disabled .form-check-label,
.form-check.disabled .form-check-label {

}

.form-check input[type="checkbox"],
.form-check-radio input[type="radio"]{
  opacity: 0;
  position: absolute;
  visibility: hidden;
}
.form-check input[type="checkbox"]:checked + .form-check-sign::after{
  opacity: 1;
}

.form-control input[type="checkbox"]:disabled + .form-check-sign::before,
.checkbox input[type="checkbox"]:disabled + .form-check-sign::after{
  cursor: not-allowed;
}

.form-check .form-check-label input[type="checkbox"]:disabled + .form-check-sign,
.form-check-radio input[type="radio"]:disabled + .form-check-sign{
  pointer-events: none !important;
}

.form-check-radio{
  .form-check-label{
    padding-left: 2rem;
  }
  &.disabled{
    .form-check-label{
      color: #9A9A9A;
      opacity: .5;
      cursor: not-allowed;
    }
  }
}

.form-check-radio .form-check-sign::before{
  font-family: 'FontAwesome';
  content: "\f10c";
  font-size: 22px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  position: absolute;
  opacity: .50;
  left: 5px;
  top: -5px;
}

.form-check-label input[type="checkbox"]:checked + .form-check-sign:before{
  // background-color: #66615B;
}

.form-check-radio input[type="radio"] + .form-check-sign:after,
.form-check-radio input[type="radio"] {
  opacity: 0;
  -webkit-transition: opacity 0.3s linear;
  -moz-transition: opacity 0.3s linear;
  -o-transition: opacity 0.3s linear;
  -ms-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
  content:" ";
  display: block;
}

.form-check-radio input[type="radio"]:checked + .form-check-sign::after {
  font-family: 'FontAwesome';
  content: "\f192";
  top: -5px;
  position: absolute;
  left: 5px;
  opacity: 1;
  font-size: 22px;
}

.form-check-radio input[type="radio"]:checked + .form-check-sign::after{
  opacity: 1;
}


.form-check-radio input[type="radio"]:disabled + .form-check-sign::before,
.form-check-radio input[type="radio"]:disabled + .form-check-sign::after {
  color: #9A9A9A;
}

</style>
