import {faqIEn,faqIIEn, faqIIIEn, faqViiiEn, faqIxEn} from '@/assets/i18n/long-text/faq-en.js'

export default {
   "question-1": "I.	Benefits, characteristics and conditions of Merchant account",
  "answer-1": faqIEn,

  "question-2": "II. Benefits and features of an Affiliate account",
  "answer-2": faqIIEn,

    "question-3": "III. Benefits and features of Customer account",
  "answer-3": faqIIIEn,

  'question-4':
    'IV. How to check the amount of VLG SHARES and VLG TOKEN owned',
  'answer-4': `<ul><li>How can I sell my VLG shares? You can keep VLG Shares in your account and wait for the value to increase, then sell them on NASDAQ. <li class="mb-2"><span>What Can I Do with my VLG Tokens and How to Sell?</span></br><div><ol><li class="mb-1"><span class="mb-1">The value of VLG Token is projected to be approximately $50 per Token (based on the best scenario in accordance to the success of VLINKGROUP ecosystem).</span></br><span>Example 1: if you have 1,000 VLG Tokens today, would you decide to sell your VLG Tokens at a current price of $5 in May 2022 and earn $5,000?</span></br><span>Example 2: If you decide to sell your coins later, say in 2025 when each VLG Token will be worth of $50 each, the value of your VLG Tokens will be 1000 x $50 = $50,000</span></br><span>Example 3: What will be the total value of VLG Tokens if you have 2,000 Tokens? $100,000!!! (2,000 x $50)</span></li><li class="mb-1">You can use VLG Tokens to buy goods and services within the VLINKGROUP ecosystem platform</li><li class="mb-1">You can turn on the Master node to earn interest</li><li class="mb-1">You can trade VLG Tokens on VLINKEXCHANGE platform</li></ol></div></li></ul>`,


  'question-5': 'V. What is the VLINKMETA Affiliate Platform Network and how can I profit from it? ',
  'answer-5': `<p>VLINKMETA is a growing network globally, born from the growth of VLINKGROUP. When users become part of VLINKMETA's global network of millions of Members, it will be called VLINKMETA. VLINKMETA's business model is based on the concept of direct selling, in which members can profit from the Affiliate program by selling products and services at a discount, including owning VLINK NAIL Membership, electronic gift packages for nail and beauty services as well as beauty service supplies from the VLINKMART e-commerce platform.</p><p>There are four types of bonuses that the VLINK META offers to its members: </p><ol><li class="mb-1">Fast start bonus</li><li class="mb-1">Direct bonus</li><li class="mb-1">Indirect bonus</li><li class="mb-1">Leader and Rank Program</li></ol><p>60% of all bonuses will be transferred to a member’s VUSD account and 40% VTP and put in bonus wallet and receive 10% monthly.</p>`,

  "question-6": "VI. OTHER POLICIES",
  "answer-6": `
    <h5>Conditions to receive AIRDROP VLG TOKEN </h5>
    <p>Registering a new account at VLINKMETA will receive 5 VLG TOKEN Airdrops provided that KYC is successful or package activation from 100 VUSD (to reserve 5 VLG TOKEN Airdrops within the next 90 days) ) within 7 days of successful account registration. </p><p>After that, 5 VLG TOKEN Airdrops will be paid out as follows:</p><ul><li class="mb-1">If the account has not activated any packages (except for the initial 100 VUSD package), the payout rate is 2% of the total Airdrop per month to VLG TOKEN wallet If the account </li><li class="mb-1">If the account has activated the package from 500 VUSD, the rate will pay out 10% of the total Airdrop per month to the VLG TOKEN wallet </li></ul><p>Accordingly, the referrer will receive 20% of the commission to the VLG TOKEN Airdrop wallet.</p><ul><li class="mb-1">If the account has not activated any package, 1 VLG TOKEN will be recorded for each F1 that successfully activates 5 VLG TOKEN Airdrops. And this 1 VLG TOKEN Airdrop will be locked until the account activates the package from 500 VUSD within 30 days.</li><li class="mb-1">If the account has activated the package from 500 VUSD, you will receive 1 VLG TOKEN Airdrop for each F1 that successfully activates 5 VLG TOKEN Airdrops.</li></ul><p>Each month will pay out 10% of the total Airdrop each month to VLG TOKEN wallet.</p>
    <h5>Credit Lending Program</h5>
    <ul><li><p class="h6 font-italic font-weight-bold">Conditions of participation:</p> <p>- For participating packages with VLG Token Staking lending up to 50% of packages from VUSD 10,000.<br> - Allow the system to automatically deduct the necessary expenses from the investor's account to comply with this scheme.</p></li> <li><p class="h6 font-italic font-weight-bold">Duration:</p> <p>12 months from disbursement date</p></li> <li><p><span class="h6 font-italic font-weight-bold">Loan received token:</span> -  VTP</p></li> <li><p class="h6 font-italic font-weight-bold">Interest rate in 2022:</p> <p class="text-capitalize">- June 50% year  <br>- July 60%/year <br>- August 70%/year <br>- September 80%/year <br>- October 90%/year <br>- November 95%/year <br>- December 100%/year</p></li> <li><p class="h6 font-italic font-weight-bold">Interest payment time:</p> <p>- Pay monthly <br>- The system will automatically deduct from VLG/VUSD/VTP or all other digital assets in borrower’s account. </p></li> <li><p class="h6 font-italic font-weight-bold">Penalties for late payment or when the system cannot automatically deduct from borrower’s account:</p> <p>- From 1st day to 30th day: 5% * total amount due* number of days late <br>- From the 31st day to 60th day: 8% * total amount due* number of days late <br>- From the 61st day: 12% *total amount due* number of days late <br><p class="mt-4"><span class="h6 font-italic font-weight-bold"> Note:</span> If the amount owed is more than the membership package, the company will deduct the membership package from the borrower’s account</p></p></li></ul>
`,

  "question-7": "VII. ARCHIVEMENT",
  "answer-7":
    `<h5>Terms for the conversion of OFS futures shares into VLG TOKEN </h5>`
    + faqViiiEn +
    `<h5>Terms of giving VLG TOKEN to shareholders</h5>` +
    faqIxEn,
  "question-8": "VIII. WHAT IS LINK KYC",
  "answer-8":
    `<p>VLINKMETA views KYC (Know Your Customer) as a mandatory step for users to verify their identity, with the result that when KYC is completed, users will be able to participate and enjoy the corresponding benefits on the VLINKMETA ecosystem. This is done in order to contribute to the transparency, credibility, and safety of transactions in the ecosystem.</p>
    <p>However, many users find it challenging to take advantage of VLINKMETA's advantages due to how rigorous this process is, particularly for Merchants. In order to allow users who have successfully completed their KYC to sponsor other users who have not yet completed their KYC, VLINKMETA has proposed the Link KYC feature.</p>
    <p>The sponsor is defined as the individual who successfully completes KYC, then purchases and activates 1 of the Link KYC packages (equivalent to 5, 10, 25, 50, 100, and 500 slots of approving KYC). The sponsor will lose 1 slots for each time another user's KYC is authorized using this KYC form.</p>
    <p>The individual who requires the KYC link will submit a request to the sponsor, who will then be in charge of checking the applicants' identities and holding the authority to reject the KYC if they exhibit signs of non-compliance. Please follow the guidelines when taking part in KYC.</p>`,
    "question-9": "IX. What is a blocking wallet?",
    "answer-9": "A blocking wallet, also known as a 'time-locked' or 'vesting' wallet, is a type of cryptocurrency wallet that allows you to lock up your tokens for a specific duration of time. This can be a useful tool for managing your crypto assets, as it allows you to control the volume of coins entering the market at any given time.    ",
    "question-10": "X. What is a burn wallet?",
    "answer-10": "A burn wallet is a cryptocurrency wallet that is specifically designed for the purpose of 'burning' or destroying coins. This is typically done by sending the coins to an address with no known private key, effectively rendering them unusable and removing them from circulation. The idea behind a burn wallet is to decrease the overall supply of a particular cryptocurrency, which can help to increase its value.",
    "question-11": "XI. How does VMM block wallet work?",
    "answer-11": "With a blocking wallet, VMM token can be released gradually as scheduled over a period of time. This can help member to avoid the volatility of the market and ensure that member receive the best return on the investment.",
    "question-12": "XII. How does VMM burn wallet work?",
    "answer-12": "The burn wallet will hold a certain amount of the member's VMM tokens and perform a 'burn' to dispose of the tokens at the scheduled time. Accordingly, when the circulating supply in the market decreases but the demand remains constant (supply < demand), the value of the token will increase. Therefore, token burn is an effective strategy to deflate the project to increase the token value",
    "question-13": "XIII. What are the benefits of VMM block wallet and VMM burn wallet?",
    "answer-13": "VMM Block wallet help to control the volume of VMM token entering the market and can be used for long-term investment. VMM burn wallet can help to increase the value of member’s  remaining holdings, as it decreases the overall supply of VMM token on the market.",
    "question-14": "XIV. How the conversion works on Vlinkmeta?",
    "answer-14": "Vlinkmeta allows members to convert all VLG tokens from VLG Staking wallet into VMM tokens. Please note that VLG tokens in the Staking wallet will be converted directly into VMM tokens without going through a free VLG wallet",
    "question-15": "XV. Released plan of VMM Block wallet",
    "answer-15": "<div class=\"table-responsive\"><p>In VLINKMETA, the total amount of VMM token will be separated into 2 wallets of VMM: Block wallet and Burn wallet, with a proportion of 10% and 90%. The releasing plan from VMM block wallet to the VMM Release wallet will follow the table below: </p><table role=\"table\"class=\"table b-table table-bordered table-sm b-table-caption-top mb-3\"><thead role=\"rowgroup\" class=\"btn-primary\"><tr><th class=\"table-cell-center\">2023</th><th class=\"table-cell-center\">2024</th><th class=\"table-cell-center\">2025</th><th class=\"table-cell-center\">2026</th><th class=\"table-cell-center\">2027</th><th class=\"table-cell-center\">2028</th><th class=\"table-cell-center\">2029</th><th class=\"table-cell-center\">2030</th><th class=\"table-cell-center\">2031</th><th class=\"table-cell-center\">2032</th><th class=\"table-cell-center\">2033</th></tr></thead><tbody role=\"rowgroup\"><tr><td>2%</td><td>10%</td><td>10%</td><td>10%</td><td>10%</td><td>10%</td><td>10%</td><td>10%</td><td>10%</td><td>10%</td><td>10%</td></tr><tr><td>Released in 12 months</td><td>Released in 12 months</td><td>Released in 12 months</td><td>Released in 12 months</td><td>Released in 12 months</td><td>Released in 12 months</td><td>Released in 12 months</td><td>Released in 12 months</td><td>Released in 12 months</td><td>Released in 12 months</td><td>Released in 12 months</td></tr></tbody></table><p>For instance, a member has 100 available VLG token:</p><p>⇒ Total VMM token he can convert from VLG token: 100 / (exchange rate of VLG - VMM)</p><p>⇒ The total VMM token will then be hold 10% in VMM block wallet and 90% in VMM burn wallet.</p><p>⇒ Following the plan of 2023, 2% of the blocked token will be released to VMM Release wallet gradually over 12 months.</p><p>⇒ From 2024 to 2033, 10% of the blocked token will be released to VMM Release wallet each year, and gradually over 12 months.</p></div>",
  }
