<template>
  <div>
    <TheHeader />
    <HomeContent />
  </div>
</template>

<script>
import TheHeader from './common/TheHeader.vue'
import HomeContent from './common/Content.vue'
export default {
  name: "HomeLayout",
  components: {
    TheHeader,
    HomeContent
  }
}
</script>

<style scoped>

</style>
