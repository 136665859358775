var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"base-select",class:[_vm.className]},[(_vm.label)?_c('p',{staticClass:"base-select__label",class:[_vm.classLabel]},[_vm._v("\n    "+_vm._s(_vm.label)+"\n  ")]):_vm._e(),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hideOption),expression:"hideOption"}],staticClass:"base-select__wrap",class:{
      focused: _vm.currentValue,
      'input-error': _vm.$v.currentValue.$error,
      disabled: _vm.disabled,
      show: _vm.isShowOptions,
    },on:{"click":function($event){_vm.isShowOptions = !_vm.isShowOptions}}},[_vm._m(0),(_vm.isShowOptions)?_c('ul',{staticClass:"base-select__options"},[(_vm.isShowDefault)?_c('li',{staticClass:"base-select__item",class:{ active: !_vm.currentValue },on:{"click":function($event){$event.stopPropagation();return _vm.resetOption.apply(null, arguments)}}},[_c('a',{staticClass:"base-select__link",attrs:{"href":"javascript:void(0)"}},[_vm._v(_vm._s(_vm.placeholder))])]):_vm._e(),_vm._l((_vm.items),function(item,index){return _c('li',{key:_vm.getKeyLoop(item, index),staticClass:"base-select__item",class:{ active: _vm.currentValue === _vm.getValue(item) },on:{"click":function($event){$event.stopPropagation();return _vm.chooseOption(item)}}},[_c('a',{staticClass:"base-select__link",attrs:{"href":"javascript:void(0)"}},[(_vm.customDisplayItem)?_vm._t("item",null,{"item":item}):[_vm._v(_vm._s(_vm.getText(item)))]],2)])})],2):_vm._e(),_c('div',{staticClass:"base-select__inner"},[(!_vm.currentValue)?_c('div',{staticClass:"base-select__empty"},[_c('p',[_vm._v(_vm._s(_vm.placeholder))])]):_c('div',{staticClass:"base-select__value"},[(_vm.customDisplayItem)?_vm._t("text",null,{"textShow":_vm.textShow,"dataSelected":_vm.dataSelected}):_c('p',[_vm._v(_vm._s(_vm.textShow))])],2)])]),(_vm.$v.currentValue.$error)?_c('p',{staticClass:"error"},[(!_vm.$v.currentValue.required)?[_vm._v("\n      "+_vm._s(_vm.errorRequired
          ? _vm.errorRequired
          : _vm.$t("validate.required", {
              0: _vm.label ? _vm.label.toLowerCase() : "",
            }))+"\n    ")]:_vm._e()],2):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"base-select__icon"},[_c('i',{staticClass:"fa fa-caret-down arrow-down",attrs:{"aria-hidden":"true"}})])
}]

export { render, staticRenderFns }