import { RepositoryFactory } from '@/apis/repositoryFactory'
const WalletRepository = RepositoryFactory.get('wallet')

export default {
  state: {
    wallet: null,
  },
  getters: {
    getWallet: state => state.wallet,
  },
  mutations: {
    setWallet(state, payload) {
      state.wallet = { ...payload }
    }
  },
  actions: {
    fetchWallet({ commit }) {
      WalletRepository.myWallet()
      .then(
        response => {
          commit('setWallet', response)
        },
      )
    }
  }
}
