import VTooltip from 'v-tooltip'
import Notifications from 'src/components/NotificationPlugin'
import SideBar from './components/SidebarPlugin'

import {
  GlobalComponents,
  GlobalDirectives,
  GlobalFilters,
  GlobalNotificationPopup,
  GlobalSnackMessage
} from '@/core/plugins'
import globalMixin from '@/core/mixins/global'
export default {
  install (Vue) {
    Vue.use(GlobalComponents)
    Vue.use(GlobalDirectives)
    Vue.use(GlobalFilters)
    Vue.use(SideBar)
    Vue.use(Notifications)
    Vue.use(VTooltip)
    Vue.use(GlobalSnackMessage)
    Vue.use(GlobalNotificationPopup)
    Vue.mixin(globalMixin)
  }
}
