export default {
  data () {
    return {
      isLoading: false
    }
  },
  computed: {
    currentUser () {
      return this.$store.state.Auth.currentUser
    },
    currentLanguage () {
      return localStorage.getItem('currentLanguage')
    },
    isLogged () {
      return this.$store.state.Auth.currentUser &&
       Object.keys(this.$store.state.Auth.currentUser).length &&
       this.$store.state.Auth.currentUser.Id
    }
  },
  methods: {
    goback(defaultUrl = '/') {
      if (window.history.length > 2 ) {
        this.$router.go(-1)
        return
      }
      this.$router.push(defaultUrl)
      return
    },
    $_loading () {
      this.isLoading = true
    },
    $_load () {
      this.isLoading = false
    }
  }
}
