<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.1679 18.9998H3.83464C3.19028 18.9998 2.66797 18.4774 2.66797 17.8331C2.66797 17.1888 3.19028 16.6664 3.83464 16.6664H20.168C20.8123 16.6664 21.3346 17.1888 21.3346 17.8331C21.3346 18.4774 20.8123 18.9998 20.1679 18.9998Z"
      :fill="!active ? '#738CB0' : '#ffffff'"
    />
    <path
      d="M20.1679 13.1669H3.83464C3.19028 13.1669 2.66797 12.6446 2.66797 12.0002C2.66797 11.3559 3.19028 10.8336 3.83464 10.8336H20.168C20.8123 10.8336 21.3346 11.3559 21.3346 12.0002C21.3346 12.6446 20.8123 13.1669 20.1679 13.1669Z"
      :fill="!active ? '#738CB0' : '#ffffff'"
    />
    <path
      d="M20.1679 7.33334H3.83464C3.19028 7.33334 2.66797 6.81103 2.66797 6.16667C2.66797 5.52231 3.19028 5 3.83464 5H20.168C20.8123 5 21.3346 5.52231 21.3346 6.16667C21.3346 6.81103 20.8123 7.33334 20.1679 7.33334Z"
      :fill="!active ? '#738CB0' : '#ffffff'"
    />
  </svg>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
</style>