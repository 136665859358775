import repository from './repository'

const resource = '/api/Package'
const preOrder = '/api/PreOrder'
const preOrderUser = '/user/me'

export default {
  getAll() {
    return repository.get(`${resource}`)
  },
  get(id) {
    return repository.get(`${resource}/${id}`)
  },
  create(payload) {
    return repository.post(`${resource}`, payload)
  },
  update(id, payload) {
    return repository.put(`${resource}/${id}`, payload)
  },
  remove(id) {
    return repository.delete(`${resource}/${id}`)
  },
  getMyPackage() {
    return repository.get(`${resource}/get-my-package`)
  },
  getMyPackageHistory() {
    return repository.get(`${resource}/get-my-package-history`)
  },
  getUserRedeemPackage(PackageId) {
    return repository.get(`${resource}/${PackageId}/users`)
  },
  preOrder(id) {
    return repository.post(`${preOrder}/pre-order`, JSON.stringify(id))
  },
  getUserRedeemPackageVMF() {
    return repository.get(`${resource}/vmf/customer`)
  },
  getPackagesPreOrder() {
    return repository.get(`${preOrderUser}/my-pre-order`)
  },
  getUsersPackagesPreOrder() {
    return repository.get(`${preOrder}/pre-order-users`)
  },
  paymentPreOrder(id) {
    return repository.post(`${preOrder}/pre-orders/bills?id=${id}`)
  },
  buyEventTicket(payload) {
    return repository.post(`/event-ticket/payment`, payload)
  }
}
