<template>
  <div class="wrapper dashboard">
    <div class="sidebar">
      <div class="sidebar__top">
        <UserRanking />
        <div class="sidebar__transfer">
          <a class="btn-bg btn" @click="onOpenModal(MODAL.TRANSFER_HOME)">
            <!-- <img src="/img/sidebar/icon-transfer.svg" alt="transfer" /> -->
            <span>{{ $t("transfer.index") }}</span>
          </a>
          <a class="btn-bg btn" @click="onOpenModal(MODAL.RECEIVE)">
            <!-- <img src="/img/sidebar/icon-receive.svg" alt="transfer" /> -->
            <span>{{ $t("transfer.receive-title") }}</span>
          </a>
        </div>
        <div class="menu">
          <ul class="list-menu">
            <li v-for="(item, index) in sideBarMenu" :key="index" class="item"
              :class="{ active: $route.fullPath === item.path }">
              <a href="javascript:void(0)" class="nav-link border-bottom px-0" @click="openLink(item)">
                <!-- <span class="icon" :class="item.icon"></span> -->
                <img :src="item.imgIcon" alt="" />
                <span :class="{ 'font-weight-bold name text-gradient':  $route.fullPath === item.path}" class="name">{{ $t(`${item.label}`) }}</span>
                <img src="/assets/img/icons/Arrow_4.svg" class="position-absolute" style="right: 20px;">
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="sidebar__bottom d-flex">
        <a class="nav-link" href="javascript:void(0)" @click="$router.push('/faq')">
          <span class="name">{{ $t("menu-items.faq") }}</span>
        </a>
        <a class="nav-link log-out" @click="logout()">
          <span class="name">{{ $t("logout") }}</span>
        </a>
      </div>
    </div>
    <div class="main-panel">
      <TopNavbar class="nav-desktop" />
      <TopNavbarMb class="nav-mobile" />
      <DashboardContent />
    </div>
    <ModalTransfer />
    <ModalReceive />
    <BottomBar />
    <TheFooter />
    <!-- <TheFooter class="dashboard__footer" /> -->
  </div>
</template>
<script>
import MENU, { MENU_POSITION } from "@/constants/menu";
import TopNavbar from "./TopNavbar.vue";
import TopNavbarMb from "@/layout/mobile/TopNavbarMb";
import BottomBar from "@/layout/mobile/BottomBar";
import DashboardContent from "./Content.vue";
import UserRanking from "@/layout/UserRanking";
import ModalTransfer from "@/containers/Wallet/modal-transfer-home.vue";
import ModalReceive from "@/containers/Wallet/modal-receive.vue";
import { mapActions } from "vuex";
import { MODAL } from "@/constants";
import isEmpty from "lodash/isEmpty";
import TheFooter from './common/TheFooter.vue'

export default {
  components: {
    TopNavbar,
    DashboardContent,
    BottomBar,
    TopNavbarMb,
    UserRanking,
    ModalTransfer,
    TheFooter,
    ModalReceive,
  },
  data() {
    return {
      MODAL
    };
  },
  computed: {
    sideBarMenu() {
      return MENU.filter((i) =>
        i.position.includes(MENU_POSITION.PROFILE_MENU)
      );
    },
  },
  methods: {
    ...mapActions("Auth", {
      signOut: "signOut",
    }),
    logout() {
      this.signOut();
    },
    onOpenModal(id) {
      if (isEmpty(this.$store.getters["Auth/currentUser"])) {
        this.$bvModal.show(MODAL.LOGIN)
        return
      }
      this.$bvModal.show(id);
    },
    openLink(item) {
      if (item.requireLogin) {
        if (isEmpty(this.$store.getters["Auth/currentUser"])) {
          this.$bvModal.show(MODAL.LOGIN)
          return
        }
      }
      this.$router.push(item.path)
    }
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  background-color: #010062;
  padding-bottom: 50px;
  .btn-bg {
    background-image: url('/assets/img/btn-gradient.png');
    background-size: 100%;
    background-repeat: no-repeat;
    min-width: 130px;
    height: 32px;
  }

  .dashboard {
    background-image: url('/assets/img/main-panel.jpeg');
    background-size: cover;
    background-position: top;
    background-color: #010062;
    &__footer {
      margin-left: 280px;
      .footer-width {
        margin-left: 0px;
      }
    }
  }

  .sidebar {
    height: 100%;
    width: 280px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    // background: #102b5f;
    overflow-x: hidden;
    transition: 0.5s;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    box-shadow: 0 1px 25px rgba(0, 0, 0, 0.6);
    // background: linear-gradient(180deg, #010177 0%, #cf00f8 100%);
    // background: linear-gradient(180deg, rgb(0, 8, 99) 0%, #040179 18.5%, #0e017f 32.8%, #1e0189 45.7%, #2d007d 57%, #2d007d 70%, #370078 80%, #3c006e 90%, #cf00f847 100%);
    background: linear-gradient(180deg, rgb(0, 8, 99) 0%, rgb(0, 10, 110) 25%, rgb(5, 11, 116) 50%, #1e0189 75%, #2d007d 100%);
    border-right: 1px solid #F4CC59;
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &__top {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;

      .logo {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: auto;
          height: 56px;
        }

        &:hover {
          cursor: pointer;
        }
      }

      .user {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: rgba(21, 53, 116, 0.3);
        border: 1px solid #153574;
        border-radius: 8px;
        padding: 8px;
        width: 100%;

        .information {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;

          .name {
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            color: #ffffff;
          }

          .level {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #c99f4e;
          }
        }

        .logo {
          img {
            width: 58px;
            height: 54px;
          }
        }
      }

      .menu {
        margin-top: 16px;
        width: 100%;

        .list-menu {
          padding: 0;
          margin: 0;
          width: 100%;

          .item {
            list-style: none;

            .nav-link {
              padding: 12px 20px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              width: 100%;
              height: 100%;

              span.icon {
                &:before {
                  color: #fcfcfc;
                }
              }

              span.name {
                font-size: 16px;
                line-height: 24px;
                color: #fcfcfc;
                display: inline-block;
                white-space: nowrap;
                padding-left: 14px;
              }
            }

            &:hover,
            &.active {
              // background: #153574;
              border-radius: 8px;

              .nav-link {
                span.icon {
                  &:before {
                    color: #ffffff;
                    background: radial-gradient(circle, #a3783a 0%, #eed589 20%, #faf8da 30%, #eed589 40%, #eed589 60%, #f9f7da 70%, #eed589 80%, #a4793a 100%);
                    -webkit-background-clip: text;
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                  }
                }

                span.name {
                  color: #ffffff;
                  background: radial-gradient(circle, #a3783a 0%, #eed589 20%, #faf8da 30%, #eed589 40%, #eed589 60%, #f9f7da 70%, #eed589 80%, #a4793a 100%);
                  -webkit-background-clip: text;
                  background-clip: text;
                  -webkit-text-fill-color: transparent;
                }
              }
            }
          }
        }
      }
    }

    &__transfer {
      margin-top: 12px;
      height: 56px;
      width: 100%;
      display: grid;
      grid-template-columns: 50% 50%;
      // background: rgba(21, 53, 116, 0.3);
      // border: 1px solid #153574;
      border-radius: 8px;
      align-items: center;

      a {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        // height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        color: #3b2314;
        cursor: pointer;
        transition: all .3s;
        outline: none;
        &:first-child {
          border-right: solid 1px #153574;
        }

        &:hover {
          // background: #153574;
          transform: scale(1.1);
          outline: none;
          border: none;
          span {
            // color: #ffffff;
          }
        }

        img {
          width: 24px;
          height: 24px;
          margin-bottom: 5px;
        }
      }
    }

    &__bottom {
      width: 100%;

      .nav-link {
        padding: 12px 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;

        span.name {
          font-size: 16px;
          line-height: 24px;
          color: #fcfcfc;
          display: inline-block;
          white-space: nowrap;
          padding-left: 14px;
        }

        &.log-out {
          span.icon {
            &:before {
              color: #e96058;
            }
          }

          span.name {
            color: #e96058;
          }
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    @media only screen and (max-width: 768px) {
      display: none;
    }
  }

  .main-panel {
    margin-left: 280px;

    min-height: 100vh;
    .nav-mobile {
      display: none;
    }

    @media only screen and (max-width: 768px) {
      margin-left: 0;
      height: auto;

      .nav-desktop {
        display: none;
      }

      .nav-mobile {
        display: block;
      }
    }
  }
}
</style>
