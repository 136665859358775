<template>
  <div class="user-ranking" v-if="title">
    <div
      v-if="Object.keys(this.$store.getters['Auth/currentUser']).length"
      class="user-ranking__header flex-column"
      @click="activeRanking = !activeRanking">
      <div class="logo">
        <img :src="items[indexTitle].icon" alt="logo">
      </div>
      <div class="information align-items-center mt-3 w-75">
        <span class="name w-100 truncate text-center">{{ this.$store.getters["Auth/currentUser"].Username }}</span>
        <span class="level">{{ items[indexTitle].title }}</span>
      </div>
      <div :class="{'rotate':activeRanking}" class="arrow-down"></div>
    </div>
    <div :class="{'show':activeRanking}" class="user-ranking__content">
      <template v-if="Object.keys(conditions).length > 0">
        <div class="my-1 position-relative bonus-condition" v-if="conditions.HasBonusConditions.length > 0">
            <p class="bonus-condition__title">{{ $t('ranking.bonus-conditions') }}</p>
            <div class="bonus-condition__contents-item mb-2"
                 v-for="condition in conditions.HasBonusConditions"
                 :key="condition.Condition">
              <p class="mb-2 d-flex align-items-center description">
                <img v-if="condition.Completed" src="/img/check-true.svg"/>
                <img v-else src="/img/check-false.svg"/>
                <span class="ml-2">
                  {{ condition.Condition }} ({{ condition.Current }}/{{condition.Target}})
                </span>
              </p>
              <div class="progress p-0">
                <div class="progress-bar"
                     role="progressbar"
                     :style="`width: ` + 100 * (condition.Current/condition.Target) + `%`"
                     :aria-valuenow="condition.Current"
                     aria-valuemin="0"
                     :aria-valuemax="condition.Target"
                ></div>
              </div>
            </div>
        </div>
        <div class="next-ranking position-relative" v-if="conditions.NextRankingConditions.length > 0">
          <div class="next-ranking__title">
            <span class="d-block text">{{ $t('ranking.next') }}: <b>{{ nextTitle.title }}</b></span>
            <div class="progress p-0 mt-2 main-progress">
              <div class="position-absolute progress-img">
                <img src="/assets/img/processing.png" :style="`left: ` + conditions.NexRankingPercent + `%`" />
              </div>
              <div
                class="progress-bar bg-common"
                role="progressbar" :style="`width: ` + conditions.NexRankingPercent + `%`"
                :aria-valuenow="conditions.NexRankingPercent"
                aria-valuemin="0"
                aria-valuemax="100" >
                <!-- {{  Math.round(conditions.NexRankingPercent) }}% -->
              </div>
            </div>
          </div>
          <div class="my-3 next-ranking__content">
            <div class="next-ranking__content-item mb-2"
                   v-for="condition in conditions.NextRankingConditions"
                   :key="condition.Condition">
                <p class="mb-2 d-flex align-items-center description">
                  <img v-if="condition.Completed" src="/img/true-check.svg"/>
                  <img v-if="!condition.Completed" src="/img/false-check.svg" />
                  <span class="ml-2 ">
                    {{ condition.Condition }} ({{ condition.Current }}/{{condition.Target}})
                  </span>
                </p>
                <div class="progress p-0">
                  <div class="position-absolute progress-img">
                    <img src="/assets/img/processing2.png" :style="`left: ` + (100 * (condition.Current/condition.Target) > 100 ? 100 : 100 * (condition.Current/condition.Target)) + `%`"/>
                  </div>
                  <div class="progress-bar"
                       role="progressbar"
                       :style="`width: ` + 100 * (condition.Current/condition.Target) + `%`"
                       :aria-valuenow="condition.Current"
                       aria-valuemin="0"
                       :aria-valuemax="condition.Target">
                  </div>
                </div>
              </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { TITLE } from "@/constants";
import { RepositoryFactory } from '@/apis/repositoryFactory'
const UserRepository = RepositoryFactory.get('user')
import isEmpty from "lodash/isEmpty"
export default {
  name: "UserRanking",
  data() {
    return {
      items: TITLE,
      nextTitle: {},
      conditions: {},
      activeRanking: true
    }
  },
  async mounted() {
    try {
      if (!isEmpty(this.$store.getters["Auth/currentUser"])) {
        this.conditions = await UserRepository.getNextLevel()
        const nextTitle = this.conditions.NextRanking.toLowerCase()
        const next = this.items.filter(i => i.title === nextTitle)
        this.nextTitle = next[0]
      }
    } catch (error) {
      console.log(error)
    }
  },
  computed: {
    title() {
      const { Title } = this.$store.getters["Auth/currentUser"]
      return Title ? Title.toLowerCase() : '';
    },
    indexTitle() {
      return TITLE.findIndex((x) => x.title === this.title || x.title.replace(/\s+/g, '') === this.title);
    }
  },
}
</script>

<style scoped lang="scss">
.user-ranking {
  width: 100%;
  &__header {
    margin-top: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // background: rgba(21, 53, 116, 0.3);
    // border: 1px solid #153574;
    border-radius: 8px;
    padding: 8px;
    width: 100%;
    position: relative;
    .logo {
      img {
        width: 100px;
        height: 100px;
      }
    }
    .information {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      .name {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        color: #FFFFFF;
      }
      .level {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #C99F4E;
        text-transform: capitalize;
      }
    }
    .arrow-down {
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #ffffff;
      position: absolute;
      right: 8px;
      top: 73%;
      transform: translateY(-50%);
      transition: all linear 0.2s;
      &.rotate {
        transform: translateY(-50%) rotate(180deg);
      }
    }
    &:hover {
      cursor: pointer;
    }
  }
  &__content {
    height: 0;
    display: none;
    transition: all 1.5s ease;
    .bonus-condition {
      // background: rgba(21, 53, 116, 0.3);
      // border: 1px solid #153574;
      // border-radius: 8px;
      // padding: 12px;
      &__title {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #FFFFFF;
        margin-bottom: 8px;
      }
      &__contents-item {
        margin-bottom: 10px;
        .description {
          span {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #FFFFFF;
          }
        }
        .progress {
          height: 8px;
          border-radius: 16px;
          .progress-bar {
            // border: 1px solid #C99F4E;
            background: linear-gradient(90deg, #6074b8 0%, #0060ad 100%);
          }
        }
      }
    }
    .next-ranking {
      // background: rgba(21, 53, 116, 0.3);
      // border: 1px solid #153574;
      // border-radius: 8px;
      padding: 12px;
      &__title {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-start;
        .text {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: #FFFFFF;
          margin-bottom: 8px;
          b {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: #C99F4E;
            text-transform: capitalize;
          }
        }
        .main-progress {
          // background: linear-gradient(90deg, #6074b8 0%, #0060ad 100%);
          background: linear-gradient(90deg, #141a89 0%, #0927ae 100%);
          border-radius: 16px;
          height: 1rem;
          width: 100%;
          .progress-img {
            width: calc(100% - 48px);
            img {
              position: absolute;
              width: 22px;
              top: -4px;
            }
          }
          .progress-bar {
            background: #007BFF;
            background: linear-gradient(90deg, #ffba00 0%, #ff3300 100%);
            // border-radius: 16px;
          }
        }
      }
      &__content {
        &-item {
          .description {
            span {
              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
              color: #FFFFFF;
            }
          }
          .progress {
            height: 1rem;
            background: linear-gradient(90deg, #141a89 0%, #0927ae 100%);
            border-radius: 16px;

            .progress-img {
              width: calc(100% - 48px);
              img {
                position: absolute;
                width: 22px;
                top: -4px;
              }
            }
            .progress-bar {
              // border: 1px solid #C99F4E;
              background: linear-gradient(90deg, #6074b8 0%, #0060ad 100%);
            }
          }
        }
      }
    }
    &.show {
      height: auto;
      display: block;
      animation: slideDown 300ms ease-in-out forwards;
    }
  }
}
@keyframes growDown {
  0% {
    transform: scaleY(0)
  }
  80% {
    transform: scaleY(1.1)
  }
  100% {
    transform: scaleY(1)
  }
}
@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-60px)
  }

  100% {
    opacity: 1;
    transform: translateY(0)
  }
}
</style>
