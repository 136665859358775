export default {
    'title-1': {
      index: 'agent',
    },
  
    'title-2': {
      index: 'supervisor',  
      bonus: '30% trong tổng bể thưởng',
      description: '3 điều kiện đi kèm:',
      condition:
        '<span>1. Có 5 AGENT là F1 trực tiếp. </span></br><span>2. Doanh số trực tiếp F1 tối thiểu 4.000 AP mỗi quý.</span></br><span>3. Tổng doanh số AP trong hệ thống trực tiếp và gián tiếp tối thiểu 25,000 AP. </span>',
    },
  
    'title-3': {
      index: 'manager',
      bonus: '20% trong bể thưởng',
      description: '3 điều kiện đi kèm:',
      condition:
        '<span>1. Có 2 SUPERVISOR từ bất kỳ tầng nào thuộc 2 nhánh riêng biệt. </span></br><span>2. Doanh số trực tiếp F1 tối thiểu 6,000 AP trong 1 quý.</span></br><span>3. Tổng doanh số AP trong hệ thống trực tiếp và gián tiếp tối thiểu 50,000 AP. </span>',
    },
  
    'title-4': {
      index: 'regional manager',
      bonus: '10% trong tổng bể thưởng',
      description: '3 điều kiện đi kèm:',
      condition:
        '<span>1. Có 2 MANAGER từ bất kỳ tầng nào thuộc 2 nhánh riêng biệt. </span></br><span>2. Doanh số trực tiếp F1 tối thiểu 10,000 AP trong 1 quý.</span></br><span>3. Tổng doanh số AP trong hệ thống trực tiếp và gián tiếp tối thiểu 100,000 AP. </span> ',
    },
  
    'title-5': {
      index: 'state manager',
      bonus: '10% trong bể thưởng',
      description: '3 điều kiện đi kèm:',
      condition:
        '<span>1. Có 2 REGIONAL MANAGER từ bất kỳ tầng nào thuộc 2 nhánh riêng biệt. </span></br><span>2. Doanh số trực tiếp F1 tối thiểu 15,000 AP trong 1 quý. </span></br><span>3. Tổng doanh số AP trong hệ thống trực tiếp và gián tiếp tối thiểu 200.000 AP. </span>',
    },
  
    'title-6': {
      index: 'director',  
      bonus: '10% trong bể thưởng',
      description: '3 điều kiện đi kèm:',
      condition:
        '<span>1. Có 3 STATE MANAGER từ bất kỳ tầng nào thuộc 3 nhánh riêng biệt. </span></br><span>2. Doanh số trực tiếp F1 tối thiểu 20,000 AP trong 1 quý. </span></br><span>3. Tổng doanh số AP trong hệ thống trực tiếp và gián tiếp tối thiểu 600.000 AP. </span>',
    },
  
    'title-7': {
      index: 'global director',  
      bonus: '10% trong tổng bể thưởng',
      description: '3 điều kiện đi kèm:',
      condition:
        '<span>1. Có 3 DIRECTOR từ bất kỳ tầng nào thuộc 3 nhánh riêng biệt. </span></br><span>2. Doanh số trực tiếp F1 tối thiểu 25,000 AP trong 1 quý. </span></br><span>3. Tổng doanh số AP trong hệ thống trực tiếp và gián tiếp tối thiểu 1.800.000 AP. </span>',
    },
  
    'title-8': {
      index: 'chairman',  
      bonus: '10% trong tổng bể thưởng',
      description: '3 điều kiện đi kèm:',
      condition:
        '<span>1. Danh hiệu 3 GLOBAL DIRECTOR từ bất kỳ tầng nào thuộc 3 nhánh riêng biệt. </span></br><span>2. Doanh số trực tiếp F1 tối thiểu 30,000 AP trong 1 quý. </span></br><span>3. Tổng doanh số AP trong hệ thống trực tiếp và gián tiếp tối thiểu 5.400.000 AP. </span>',
    },
  }
  