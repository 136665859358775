<template>
  <div
    :class="{'active-sidebar':activeSidebar}"
    class="sidebar"
  >
    <img
      src="/img/icon-close.png"
      alt="icon-close"
      class="icon-close"
      @click="$emit('closeMenu', false)"
    >
    <div class="sidebar__top">
      <UserRanking />
      <div class="menu">
        <ul class="list-menu">
          <li
            v-for="(item, index) in sideBarMenu"
            :key="index"
            class="item"
            :class="{'active': $route.path === item.path}"
          >
            <a href="javascript:void(0)" class="nav-link"  @click="openLink(item)">
              <span class="icon" :class="item.icon"></span>
              <span class="name">{{ $t(`${item.label}`) }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="sidebar__bottom">
      <a class="nav-link" href="javascript:void(0)" @click="[$router.push('/faq'), $emit('closeMenu', false)]">
        <img src="/img/question.svg" alt="">
        <span class="name">{{ $t('menu-items.faq') }}</span>
      </a>
      <a class="nav-link log-out" @click="logout()">
        <span class="icon icon-logout"></span>
        <span class="name">{{ $t('logout') }}</span>
      </a>
    </div>
  </div>
</template>

<script>
import UserRanking from "@/layout/UserRanking";
import MENU, { MENU_POSITION } from "@/constants/menu";
import {mapActions} from "vuex";
import isEmpty from "lodash/isEmpty";
import {MODAL} from "@/constants";
export default {
  name: "SideBarMB",
  components: {
    UserRanking
  },
  computed: {
    sideBarMenu () {
      return MENU.filter((i) =>
        i.position.includes(MENU_POSITION.PROFILE_MENU)
      );
    }
  },
  props: {
    activeSidebar: {
      type: Boolean,
      default: () => {}
    }
  },
  methods: {
    ...mapActions('Auth', {
      signOut: 'signOut'
    }),
    logout() {
      this.signOut(null)
    },
    openLink (item) {
      if (item.requireLogin) {
        if (isEmpty(this.$store.getters["Auth/currentUser"])) {
          this.$bvModal.show(MODAL.LOGIN)
          return
        }
      }
      this.$router.push(item.path)
      this.$emit('closeMenu', false)
    }
  }
}
</script>

<style scoped lang="scss">
.sidebar {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 12;
  top: 0;
  left: -269px;
  bottom: 0;
  background: #102B5F;
  overflow-x: hidden;
  padding: 16px 16px 16px 16px;
  display: flex;
  opacity: 0;
  visibility: hidden;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  transition: all 0.5s;
  .icon-close {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 16px;
    right: 16px;
  }
  &__top {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding-top: 20px;
    .logo {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: auto;
        height: 56px;
      }
      &:hover {
        cursor: pointer;
      }
    }
    .user {
      margin-top: 38px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: rgba(21, 53, 116, 0.3);
      border: 1px solid #153574;
      border-radius: 8px;
      padding: 8px;
      width: 100%;
      .information {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        .name {
          font-weight: 500;
          font-size: 20px;
          line-height: 28px;
          color: #FFFFFF;
        }
        .level {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #C99F4E;
        }
      }
      .logo {
        img {
          width: 58px;
          height: 54px;
        }
      }
    }
    .menu {
      margin-top: 16px;
      width: 100%;
      .list-menu {
        padding: 0;
        margin: 0;
        width: 100%;
        .item {
          list-style: none;
          .nav-link {
            padding: 12px 20px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            height: 100%;
            span.icon {
              &:before {
                color: #738CB0;
              }
            }
            span.name {
              font-size: 16px;
              line-height: 24px;
              color: #738CB0;
              display: inline-block;
              white-space: nowrap;
              padding-left: 14px;
            }
          }
          &.active {
            background: #153574;
            border-radius: 8px;
            .nav-link {
              span.icon {
                &:before {
                  color: #FFFFFF;
                }
              }
              span.name {
                color: #FFFFFF;
              }
            }
          }
        }
      }
    }
  }
  &__bottom {
    width: 100%;
    .nav-link {
      padding: 12px 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      span.name {
        font-size: 16px;
        line-height: 24px;
        color: #738CB0;
        display: inline-block;
        white-space: nowrap;
        padding-left: 14px;
      }
      &.log-out {
        span.icon {
          &:before {
            color: #E96058;
          }
        }
        span.name {
          color: #E96058;
        }
      }
    }
  }
  &.active-sidebar {
    opacity: 1;
    visibility: visible;
    left: 0;
  }
}
</style>
