<template>
  <div class="transfer">
    <h4 class="transfer__title">
      <template v-if="walletSelected && walletSelected.label">
        {{ $t("titles.transfer-internal", [walletSelected.label]) }}
        <a href='http://vlinkmeta.com'>vlinkmeta.com</a>
      </template>
    </h4>
    <div class="transfer__content">
      <BaseInputCustom class-name="address-wallet theme-light" :customWrapClass="isNotFound ? 'input-error' : ''"
        ref="address" v-model="formData.ToWalletAddress" type="text" name="address" @blur="fetchUser" required
        :label="$t('transfer.address')">
        <span @click.prevent="scanEnabled = true" class="position-absolute d-md-none camera-icon">
          <img src="./scan.svg" alt="" />
        </span>
        <span @click.prevent="pasteContext" class="position-absolute d-md-block d-none camera-icon">
          <img src="./paste.svg" alt="" />
        </span>
        <p v-if="isNotFound" class="error">{{ $t('validWallet') }}</p>
      </BaseInputCustom>
      <BaseSelect ref="type" :input-value="type" name="type" :label="$t('wallet.wallet-type')" :items="WALLET_TRANSFER"
        text-field="label" value-field="value" :is-show-default="false" required error-required="Type must be required"
        key-loop="index" @change="changeType" class-name="theme-light" />
      <div class="content--amount">
        <BaseInputCustom class-name="theme-light" ref="amount" v-model="fiatValue" thousands-separator-symbol=","
          type="tel" name="amount" required :limitInput="20" @input="debounceInput" :label="$t('fields.Amount')">
          <small class="text-body mt-1">
            {{ $t("wallet.avaible-balance") }}:
            <strong>{{ wallet[type].Amount | toCurrency }}
              <template v-if="walletSelected && walletSelected.label">
                {{ walletSelected.label }}
              </template>
            </strong>
          </small>
        </BaseInputCustom>
        <BaseSelect ref="fiatSelected" :input-value="fiatSelected" name="fiatSelected" label=" " :items="amountType"
          :is-show-default="false" required classLabel="mh-16px" key-loop="index" class="position-relative"
          @change="fiatSelected = $event" />
        <span class="submit-amount">{{ amountTypeLabel }}</span>
      </div>
      <BaseInputCustom class-name="theme-light" ref="reason" v-model="formData.Reason" type="textarea" name="reason"
        required :limit-input="50" :placeholder="$t('placeholder.textarea.max')" :label="$t('transfer.message')"
        rows="3" />
      <BaseInputCustom class-name="theme-light" ref="pin" v-model="formData.Pin" name="pin" inputmode="numeric"
        :isPin="true" required :label="$t('transfer.pin')" />
    </div>
    <p class="text-note">
      <span>{{ $t("payments.methods.fee") }}:
        <template v-if="fetchedFee">{{ fee | toCurrency }} </template>
        <template v-else><span class="spinner-border spinner-border-sm" /></template>

        <template v-if="walletSelected && walletSelected.label">
          {{ walletSelected.label }}
        </template>
      </span>
      <a class="ml-1" href="/faq" target="_blank"><svg width="24" height="24" viewBox="0 0 24 24" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12 2C6.47301 2 2 6.4725 2 12C2 17.5269 6.4725 22 12 22C17.527 22 22 17.5275 22 12C22 6.47309 17.5275 2 12 2ZM12 20.6046C7.25539 20.6046 3.39535 16.7446 3.39535 12C3.39535 7.25535 7.25539 3.39535 12 3.39535C16.7446 3.39535 20.6046 7.25535 20.6046 12C20.6046 16.7446 16.7446 20.6046 12 20.6046Z"
            fill="#ADAEB2" />
          <path
            d="M12.0018 10.3359C11.4095 10.3359 10.9883 10.5861 10.9883 10.9546V15.9697C10.9883 16.2857 11.4095 16.6015 12.0018 16.6015C12.5679 16.6015 13.0285 16.2857 13.0285 15.9697V10.9546C13.0285 10.5861 12.5679 10.3359 12.0018 10.3359Z"
            fill="#ADAEB2" />
          <path
            d="M12.0012 7.24219C11.3957 7.24219 10.9219 7.67656 10.9219 8.17676C10.9219 8.67699 11.3957 9.12453 12.0012 9.12453C12.5936 9.12453 13.0675 8.67699 13.0675 8.17676C13.0675 7.67656 12.5935 7.24219 12.0012 7.24219Z"
            fill="#ADAEB2" />
        </svg>
      </a>
    </p>
    <p class="text-note">
      {{ $t("stock.pin.first") }}
      <a target="_blank" href="/profile/security?tab=pin" style="color: #007bff; font-weight: bold">{{
          $t("stock.pin.second")
      }}</a>{{ $t("stock.pin.third") }}
    </p>
    <div class="transfer__action">
      <button class="btn btn-primary btn-round transfer__btn-cancel" @click="cancel">
        <span>{{ isPage ? $t("transfer.Clean") : $t("action.cancel") }}</span>
      </button>
      <button :disabled="isDisabledButton" class="btn btn-primary transfer__btn-create"
        :class="{ 'button--loading': loading }" @click="submit">
        <span>{{ $t("transfer.index") }}</span>
      </button>
    </div>
    <div class="check-in" v-if="scanEnabled">
      <div class="container">
        <div class="row">
          <div class="scan-camera">
            <div class="scan-camera__header">
              <p class="text-white position-absolute">Scan QR Code</p>
              <img src="./arrow-left.svg" alt="trend-up" class="arrow-left" @click="scanEnabled = false">
            </div>
            <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit">
              <img src="./group.png" alt="" class="group-camera">
            </qrcode-stream>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MODAL, NOTIFICATION_ICON } from "@/constants";
import BaseSelect from "@/components/Inputs/BaseSelect.vue";
import BaseInputCustom from "@components/Inputs/BaseInputCustom";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { mapActions, mapGetters } from "vuex";
import { WALLET_TRANSFER } from "@/constants";
import { RepositoryFactory } from "@/apis/repositoryFactory";
const UserRepository = RepositoryFactory.get("user");
import globalMixin from "@/core/mixins/global";
import validateMixin from "@/core/mixins/validate";
const WalletRepository = RepositoryFactory.get("wallet");
import moment from "moment";
import { validationMixin } from "vuelidate";
import { QrcodeStream } from 'vue-qrcode-reader'
import debounce from 'lodash.debounce'

export default {
  name: "ModalTransfer",
  components: {
    BaseSelect,
    BaseInputCustom,
    QrcodeStream,
    "v-select": vSelect,
  },
  props: {
    address: {
      type: String,
      default: "",
    },
    amount: {
      type: [Number, String],
      default: 0,
    },
    reason: {
      type: String,
      default: "",
    },
    walletType: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
    unit: {
      type: String,
      default: "",
    },
    isShowCameraIcon: {
      type: Boolean,
      default: false,
    },
    isPage: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [globalMixin, validateMixin, validationMixin],
  data() {
    return {
      formData: {
        ToWalletAddress: "",
        Amount: "",
        Reason: "",
        ReceiverId: "",
        Pin: "",
      },
      amountType: ["VND", "USD", "VLG"],
      fiatSelected: "VLG",
      fiatValue: "",
      scanEnabled: false,
      type: "VLGTokenAvailable",
      members: [],
      loading: false,
      fetchedFee: true,
      WALLET_TRANSFER,
      rateUSDtoVnd: null,
      rateVlgToUsd: null,
      rateVlgToVnd: null,
      fee: 0,
      infoTransfer: null,
      isNotFound: false,
      camera: "auto"
    };
  },
  async mounted() {
    try {
      await this.$nextTick();
      this.formData.ToWalletAddress = this.address || ""
      this.type = this.walletType
        ? this.walletType.toString().split("-").join("")
        : "VLGTokenAvailable";
      this.formData.Reason = this.message ? this.message : "";
      this.formData.Amount = this.amount ? this.amount : "";
      if (this.unit) {
        this.fiatValue = this.amount;
        this.fiatSelected = this.unit;
        const rate = await WalletRepository.getExchangeRate();
        if (this.unit.toLowerCase() == "vnd") {
          this.formData.Amount =
            this.type != "VLGTokenAvailable"
              ? this.amount / rate.UsdToVnd
              : this.amount / rate.UsdToVnd / rate.VlgToUsd;
        } else if (this.unit.toLowerCase() == "usd") {
          this.formData.Amount =
            this.type != "VLGTokenAvailable"
              ? this.amount
              : this.amount / rate.VlgToUsd;
        } else if (this.unit.toLowerCase() == "vlg") {
          this.formData.Amount =
            this.type != "VLGTokenAvailable"
              ? this.amount * rate.VlgToUsd
              : this.amount;
        }
      }
      const rate = await WalletRepository.getExchangeRate();
      this.rateUSDtoVnd = rate.UsdToVnd;
      this.rateVlgToUsd = rate.VlgToUsd;
      this.rateVlgToVnd = rate.UsdToVnd * rate.VlgToUsd;
    } catch (e) {
      console.log(e);
    } finally {
      this.loading = false;
      this.$_load();
    }
  },
  computed: {
    ...mapGetters({
      wallet: "getWallet",
    }),
    isDisabledButton() {
      return !(this.formData.ToWalletAddress && this.formData.Amount && this.formData.Reason && this.formData.Pin) || !this.fetchedFee || this.isNotFound
    },
    walletSelected() {
      return WALLET_TRANSFER.find((i) => this.type == i.value);
    },
    amountTypeLabel() {
      const result = this.walletSelected ? this.walletSelected.label : "";
      const formatter = new Intl.NumberFormat('en', {
        minimumFractionDigits: 2, maximumFractionDigits: 8
      })
      return this.formData.Amount > 0 ? formatter.format(this.formData.Amount) + " " + result : ''
    },
  },
  watch: {
    type(val) {
      this.$emit("changeWaletType", val);
      if (this.fiatSelected === "VND") {
        this.formData.Amount =
          val != "VLGTokenAvailable"
            ? this.$options.filters.formatValueCurrency(this.fiatValue) /
            this.rateUSDtoVnd
            : this.this.$options.filters.formatValueCurrency(this.fiatValue) /
            this.rateVlgToVnd;
      } else if (this.fiatSelected === "USD") {
        this.formData.Amount =
          val != "VLGTokenAvailable"
            ? this.$options.filters.formatValueCurrency(this.fiatValue)
            : this.$options.filters.formatValueCurrency(this.fiatValue) /
            this.rateVlgToUsd;
      } else if (this.fiatSelected === "VLG") {
        this.formData.Amount =
          val != "VLGTokenAvailable"
            ? this.$options.filters.formatValueCurrency(this.fiatValue) *
            this.rateVlgToUsd
            : this.$options.filters.formatValueCurrency(this.fiatValue);
      }
    },
    fiatValue(val) {
      if (this.fiatSelected === "VND") {
        this.formData.Amount =
          this.type != "VLGTokenAvailable"
            ? this.$options.filters.formatValueCurrency(val) / this.rateUSDtoVnd
            : this.$options.filters.formatValueCurrency(val) /
            this.rateVlgToVnd;
      } else if (this.fiatSelected === "USD") {
        this.formData.Amount =
          this.type != "VLGTokenAvailable"
            ? this.$options.filters.formatValueCurrency(val)
            : this.$options.filters.formatValueCurrency(val) /
            this.rateVlgToUsd;
      } else if (this.fiatSelected === "VLG") {
        this.formData.Amount =
          this.type != "VLGTokenAvailable"
            ? this.$options.filters.formatValueCurrency(val) * this.rateVlgToUsd
            : this.$options.filters.formatValueCurrency(val);
      }
    },
    fiatSelected(val) {
      if (val === "VND") {
        this.formData.Amount =
          this.type != "VLGTokenAvailable"
            ? this.$options.filters.formatValueCurrency(this.fiatValue) /
            this.rateUSDtoVnd
            : this.$options.filters.formatValueCurrency(this.fiatValue) /
            this.rateVlgToVnd;
      } else if (val === "USD") {
        this.formData.Amount =
          this.type != "VLGTokenAvailable"
            ? this.$options.filters.formatValueCurrency(this.fiatValue)
            : this.$options.filters.formatValueCurrency(this.fiatValue) /
            this.rateVlgToUsd;
      } else if (val === "VLG") {
        this.formData.Amount =
          this.type != "VLGTokenAvailable"
            ? this.$options.filters.formatValueCurrency(this.fiatValue) *
            this.rateVlgToUsd
            : this.$options.filters.formatValueCurrency(this.fiatValue);
      }
      this.fetchFee()
    }
  },
  methods: {
    debounceInput: debounce(function (e) {
      this.fetchFee()
    }, 500),
    isValidHttpUrl(string) {
      let url;
      try {
        url = new URL(string);
      } catch (_) {
        return false;
      }
      return url.protocol === "http:" || url.protocol === "https:";
    },
    async onDecode(content) {
      try {
        if (!this.isValidHttpUrl(content)) {
          this.$alertMsg('fail', this.$t('swal.qr-fail'))
          return false;
        }
        window.location.href = content
        this.scanEnabled = false
      } catch (err) {
        this.$alertMsg('fail', this.$t('swal.qr-fail'))
        this.pause()
        setTimeout(() => this.unpause(), 100)
      }
    },
    unpause() {
      this.camera = 'auto'
    },
    pause() {
      this.camera = 'off'
    },
    async onInit(promise) {
      try {
        await promise;
      } catch (e) {
        console.error(e);
      } finally {
        this.showScanConfirmation = this.camera === "off";
      }
    },
    changeType($event) {
      this.type = $event;
      this.fiatSelected = this.type != "VLGTokenAvailable" ? "USD" : "VLG";
      this.fetchUser()
    },
    async pasteContext() {
      try {
        const text = await navigator.clipboard.readText()
        this.formData.ToWalletAddress = text;
        this.fetchUser()
      } catch (error) {
        console.log(error)
      }
    },
    cancel() {
      if (!this.isPage) {
        this.$emit("cancel");
        return;
      }
      this.formData = {
        ToWalletAddress: "",
        Amount: "",
        Reason: "",
        Pin: "",
        ReceiverId: "",
      };
      this.fiatValue = "";
      this.type = "";
      const refs = ["amount", "type", "reason", "pin", "address"];
      this.$_validateMixin_reset(refs);
    },
    ...mapActions(["fetchWallet"]),
    async fetchUser() {
      try {
        const typeWallet = WALLET_TRANSFER.find((i) => this.type == i.value);
        if (!this.formData.ToWalletAddress) {
          this.isNotFound = false;
          return
        }
        const response = await WalletRepository.fetchUsername(
          this.formData.ToWalletAddress,
          typeWallet.label
        );
        this.isNotFound = false;
        this.infoTransfer = response
        this.fetchFee()
      } catch (error) {
        this.infoTransfer = null
        this.isNotFound = true
      }
    },
    async fetchFee() {
      try {
        this.fetchedFee = false;
        const data = {
          toWalletAddress: this.formData.ToWalletAddress,
          amount: this.formData.Amount
        }
        if (data.toWalletAddress && data.amount) {
          const typeWallet = WALLET_TRANSFER.find((i) => this.type == i.value);
          const response = await WalletRepository.fetchFeeWalletByType(
            typeWallet.url.replace(/[^\x00-\x7F]/g, ""),
            data
          );
          this.fee = response.Fee
        }
      } catch (error) {
      } finally {
        this.fetchedFee = true
      }
    },
    async submit() {
      const refs = ["amount", "type", "reason", "pin", "address"];
      const refsValid = this.$_validateMixin_refs(refs);
      if (refsValid) {
        this.formData.ReceiverId = this.infoTransfer.ReceiverId
        this.isNotFound = !this.formData.ReceiverId
        if (!this.formData.ReceiverId) return
        this.loading = true;
        const receiver = this.members.find(
          (i) => i.ValueField == this.formData.ToWalletAddress
        );
        try {
          const typeWallet = WALLET_TRANSFER.find((i) => this.type == i.value);
          await WalletRepository.transferWalletByType(
            typeWallet.url.replace(/[^\x00-\x7F]/g, ""),
            this.formData
          );
          const descHtml = `<div class="transaction-sucess_detail">
            <p class="d-flex justify-content-between">
              <span class="text-note text-nowrap" title="${this.formData.ToWalletAddress}">${this.$t(
            "wallet.to-address"
          )}: </span>
              <span class="text-note-2 text-nowrap text-address">${this.formData.ToWalletAddress}</span>
            </p>
            <p class="d-flex justify-content-between">
              <span class="text-note text-nowrap">${this.$t(
            "fields.Amount"
          )}:   </span>
              <span class="text-note-2">${this.formData.Amount} ${typeWallet.label
            }</span>
            </p>
            <p class="d-flex justify-content-between">
              <span class="text-note text-nowrap">${this.$t("payments.methods.fee")}: </span>
              <span class="text-note-2 text-nowrap text-address">${this.$options.filters.toCurrency(this.fee)} ${this.walletSelected.label}</span>
            </p>
            <p class="d-flex justify-content-between">
              <span class="text-note text-nowrap">${this.$t(
              "fields.DateTime"
            )}:</span>
              <span class="text-note-2">${moment().format(
              "h:mm A, MMM Do YYYY"
            )}</span>
            </p>
            <p class="d-flex justify-content-between mb-0">
              <span class="text-note text-nowrap">${this.$t(
              "transfer.message"
            )}:</span>
              <span
              style="
                width: 250px;
                text-overflow: ellipsis;
                overflow: hidden;
                text-align: right;
                white-space: nowrap;"
                class="text-note-2">${this.formData.Reason}</span>
            </p>
          </div>`;
          this.$notifyPopup(
            NOTIFICATION_ICON.SUCCESS,
            this.$t("action.transfered"),
            "",
            {
              action1Text: this.$t("transfer.close"),
              action1Func: () => this.cancel(),
              didCloseFunc: () => this.cancel(),
              action2Text: this.$t("transfer.history"),
              action2Func: () => {
                this.redirectHistory();
                this.cancel();
              },
            },
            "light-theme",
            descHtml,
            "px-0",
            "light"
          );
          this.$emit("refresh");
        } catch (error) {
          console.log(error);
          this.$notifyPopup(
            NOTIFICATION_ICON.ERROR,
            this.$t("swal.title.fail"),
            error.response.data.message,
            {
              action1Text: this.$t("action.try"),
              action1Func: () => { },
            }
          );
        } finally {
          this.loading = false;
        }
      }
    },
    redirectHistory() {
      this.$router.push(`/wallet/detail?code=${this.type}`);
    }
  },
};
</script>

<style scoped lang="scss">
.transfer {
  margin-top: 24px;
  color: #ffffff;

  &__title {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    margin-bottom: 24px;
    color: #43464e;
  }

  &__content {
    .title-select {
      font-family: "Overpass";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #43464e;
      margin-bottom: 8px;
    }

    .camera-icon {
      right: 10px;
      top: 32px;
    }
  }

  &__btn {
    height: 40px;
  }

  &__action {
    display: grid;
    grid-template-columns: 47% 47%;
    justify-content: space-between;
  }

  &__btn-cancel {
    color: #007bff;
    background: #ffffff;
    border: 1px solid #007bff;

    &:hover {
      background: #007bff;
      color: #ffffff;
    }
  }

  .text-note {
    font-size: 14px;
    line-height: 20px;
    color: #898b91;
  }
}

.address-wallet {
  /deep/.base-input-custom__wrap {
    input {
      padding: 8px 40px 8px 12px;
    }
  }
}

::v-deep .vs__dropdown-toggle {
  background: #f7f7f7 !important;
  color: #898b91;
}

::v-deep .vs__dropdown-menu {
  background: #f7f7f7 !important;
  color: #898b91;
}

::v-deep .base-select {
  margin-bottom: 20px;

  &__label {
    color: #43464e;
  }
}

::v-deep .base-select__wrap {
  background: #f7f7f7;

  .arrow-down {
    color: #43464e;
  }
}

::v-deep .base-select__inner {
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #141822;
  }
}

/deep/ textarea {
  background: #f7f7f7;
  border-radius: 8px;
  outline: none !important;
  border: none;
  font-family: "Overpass";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #141822;
  padding: 10px;
}

// /deep/ .v-select {
// margin-bottom: 20px;
// }

.check-in {
  background: url("./background.png") no-repeat center;
  background-size: cover;

  .scan-camera {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 56px;
    width: 100%;
    height: 100%;
    background: black;
    z-index: 10;

    .scan-camera__header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      // padding: 15px 16px;
      // border-bottom: 1px solid #43464E;
      p {
        margin: 0;
        font-weight: 700;
        font-size: 24px;
        line-height: 34px;
        z-index: 9;
        top: 30px;
        left: 50%;
        transform: translate(-50%);
      }
    }
  }

  .arrow-left {
    position: absolute;
    left: 10px;
    top: 30px;
    width: 30px;
    z-index: 10;
  }

  .group-camera {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.check-in-title {
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  text-align: center;
  color: #141822;
}

.qrcode-stream-wrapper {
  width: 100%;
  height: 100vh;
}

::v-deep .base-select__options {
  background: #ffffff; //

  .base-select__item {
    position: relative;

    &.active {
      position: relative;

      &::before {
        display: block;
        content: "";
        width: 20px;
        height: 20px;
        background-image: url("/img/wallet/icon-check.svg");
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 8px;
      }
    }

    &:hover {
      background: #f7f7f7;
      color: #101828;
    }

    .base-select__link {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #101828;
      border: none;
      border-top: none !important;

      &:hover {
        text-decoration: none !important;
      }
    }
  }
}

.content--amount {
  position: relative;
  display: grid;
  grid-template-columns: 77% 23%;

  .submit-amount {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #adaeb2;
    position: absolute;
    right: 0;
  }
}
</style>
