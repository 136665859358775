import Swal from 'sweetalert2'

const GlobalSnackMessage = {
  install(Vue) {
    const Toast = Swal.mixin({
      toast: true,
      showConfirmButton: false,
      timer: 5000,
      timerProgressBar: false,
      showCloseButton: true,
      position: 'bottom-start'
    })
    const iconType = (icon) => {
      const success = '<img src="/img/icon-success.svg" alt="icon-success" class="icon-successs"/>'
      const fail = '<img src="/img/icon-fail.svg" alt="icon-success" class="icon-fail"/>'

      switch (icon) {
        case 'success':
          return success
        default:
          return fail
      }
    }

    const alertMsg = (icon, title) => Toast.fire({
      title: iconType(icon),
      customClass: {
        container: (icon || 'fail') + ' ' + 'custom-z-index'
      },
      html: title
    })
    Vue.prototype.$alertMsg = alertMsg
  }
}

export default GlobalSnackMessage




