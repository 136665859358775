export default {
    'name-1': 'Starter',
    'description-1': 'Gói tham gia bảo trợ trực tiếp F1 nhận 10% hoa hồng  trực tiếp của điểm AP tổng',
  
    'name-2': 'Super star',
    'description-2': 'Gói tham gia bảo trợ trực tiếp F1 nhận 12.5% hoa hồng  trực tiếp của điểm AP tổng',
  
    'name-3': 'VUSD 1k',
    'description-3': 'Gói tham gia bảo trợ trực tiếp F1 nhận 15% hoa hồng  trực tiếp của điểm AP tổng',
  
    'name-4': 'VUSD 2k',
    'description-4': 'Gói tham gia bảo trợ trực tiếp F1 nhận 17.5% hoa hồng  trực tiếp của điểm AP tổng',
  
    'name-5': 'VUSD 5k',
    'description-5': 'Gói tham gia bảo trợ trực tiếp F1 nhận 20% hoa hồng  trực tiếp của điểm AP tổng',
  
    'name-6': 'VUSD 10k',
    'description-6': 'Gói tham gia bảo trợ trực tiếp F1 nhận 25% hoa hồng  trực tiếp của điểm AP tổng',
  
    'name-7': 'VUSD 20k',
    'description-7': 'Gói tham gia bảo trợ trực tiếp F1 nhận 30% hoa hồng  trực tiếp của điểm AP tổng',
  
    'name-8': 'VUSD 50k',
    'description-8': 'Gói tham gia bảo trợ trực tiếp F1 nhận 30% hoa hồng  trực tiếp của điểm AP tổng',
  
    'name-9': 'VUSD 100k',
    'description-9': 'Gói tham gia bảo trợ trực tiếp F1 nhận 30% hoa hồng  trực tiếp của điểm AP tổng',
  
    'name-10': 'VLINK MERCHANT META',
    'description-10': 'Gói cổ đông tham gia bảo trợ trực tiếp F1 nhận 25% hoa hồng  trực tiếp của điểm AP tổng',
  
    'name-11': 'PRO BUSINESS',
    'description-11': 'Gói doanh nghiệp tham gia bảo trợ trực tiếp F1 nhận 20% hoa hồng  trực tiếp của điểm AP tổng',
  
    'name-12': 'PLATINUM BUSINESS',
    'description-12': 'Gói doanh nghiệp  tham gia bảo trợ trực tiếp F1 nhận 15% hoa hồng  trực tiếp của điểm AP tổng',
  
    'name-13': 'BRONZE BUSINESS',
    'description-13': 'Gói doanh nghiệp  tham gia bảo trợ trực tiếp F1 nhận 10% hoa hồng  trực tiếp của điểm AP tổng',
  }
  