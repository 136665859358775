export const MENU_POSITION = {
  PROFILE_MENU: 'PROFILE_MENU',
  DASHBOARD_PROFILE_MENU: 'DASHBOARD_PROFILE_MENU',
  HEADER_MENU: 'HEADER_MENU',
}

export const BONUS_TABS = [
  {
    name: 'menu-items.subBonuses.pools',
    component: 'pools',
    key: 'POOLS',
  },
  {
    name: 'menu-items.subBonuses.share-ap',
    component: 'share-ap',
    key: 'SHARE_AP',
  },
  {
    name: 'menu-items.subBonuses.fast',
    component: 'team',
    key: 'TEAM',
  },
  {
    name: 'menu-items.subBonuses.direct',
    component: 'direct',
    key: 'DIRECT',
  },
  {
    name: 'menu-items.subBonuses.indirect',
    component: 'indirect',
    key: 'INDIRECT',
  },
]

export default [
  {
    id: 'dashboards',
    icon: 'icon icon-chart',
    path: '/dashboards',
    requireLogin: true,
    label: 'menu-items.dashboards',
    imgIcon: '/assets/img/icons/dasboard.svg',
    position: [MENU_POSITION.PROFILE_MENU],
  },
  {
    id: 'wallets',
    icon: 'icon icon-wallet',
    path: '/wallet',
    requireLogin: true,
    label: 'menu-items.wallets',
    imgIcon: '/assets/img/icons/wallet.svg',
    position: [MENU_POSITION.PROFILE_MENU],
  },
  {
    id: 'shop',
    icon: 'icon icon-shop',
    path: '/shop?tab=PreOrder',
    label: 'menu-items.shop',
    key: 'Shop',
    imgIcon: '/assets/img/icons/shop.svg',
    position: [MENU_POSITION.PROFILE_MENU],
  },
  {
    id: 'network',
    icon: 'icon icon-network-tree',
    path: '/network-tree',
    requireLogin: true,
    label: 'menu-items.network',
    imgIcon: '/assets/img/icons/tree.svg',
    position: [MENU_POSITION.PROFILE_MENU],
  },
  {
    id: 'bonuses',
    icon: 'icon icon-network',
    path: '/network',
    requireLogin: true,
    label: 'menu-items.bonuses',
    imgIcon: '/assets/img/icons/bonus.svg',
    position: [MENU_POSITION.PROFILE_MENU],
  },
  {
    id: 'partners',
    icon: 'icon icon-our-partners',
    path: '/our-partners',
    label: 'menu-items.partners',
    imgIcon: '/assets/img/icons/partner.svg',
    position: [MENU_POSITION.PROFILE_MENU],
  },
  {
    id: 'profile',
    icon: 'icon icon-profile',
    path: '/profile',
    requireLogin: true,
    label: 'menu-items.profile',
    imgIcon: '/assets/img/icons/setting.svg',
    position: [MENU_POSITION.PROFILE_MENU],
  },
  {
    id: 'home',
    icon: '',
    path: '/',
    label: 'menu.home',
    position: [MENU_POSITION.HEADER_MENU],
  },
  {
    id: 'compensation',
    icon: '',
    path: '/compensation',
    label: 'menu.docs',
    position: [MENU_POSITION.HEADER_MENU],
  },
  {
    id: 'news',
    icon: '',
    path: '/news',
    label: 'menu-items.news',
    position: [MENU_POSITION.HEADER_MENU],
  },
  {
    id: 'faq',
    icon: '',
    path: '/faq',
    label: 'menu.faq',
    position: [MENU_POSITION.HEADER_MENU],
  },
  {
    id: 'contact',
    icon: '',
    path: '/contact',
    label: 'menu-items.contact',
    position: [MENU_POSITION.HEADER_MENU],
  },
  {
    id: 'userguide',
    icon: '',
    path: '/user-guide',
    label: 'menu-items.user-guide',
    position: [MENU_POSITION.HEADER_MENU],
  },
  {
    id: 'overview',
    icon: '',
    path: '/profile/overview',
    label: 'overview',
    position: [MENU_POSITION.DASHBOARD_PROFILE_MENU],
  },
  {
    id: 'kyc',
    icon: '',
    path: '/profile/kyc',
    label: 'kyc',
    position: [MENU_POSITION.DASHBOARD_PROFILE_MENU],
  },
  {
    id: 'link-kyc',
    icon: '',
    path: '/profile/link-kyc',
    label: 'link-kyc',
    position: [MENU_POSITION.DASHBOARD_PROFILE_MENU],
  },
  {
    id: 'password',
    icon: '',
    path: '/profile/password',
    label: 'password',
    position: [MENU_POSITION.DASHBOARD_PROFILE_MENU],
  },
  {
    id: 'security',
    icon: '',
    path: '/profile/security',
    label: 'security',
    position: [MENU_POSITION.DASHBOARD_PROFILE_MENU],
  },
  {
    id: 'accounts',
    icon: '',
    path: '/profile/accounts',
    label: 'add-account',
    position: [MENU_POSITION.DASHBOARD_PROFILE_MENU],
  },
]
