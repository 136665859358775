import repository from './repository'

const resource = '/event-ticket'

export default {
  getEventTicket() {
    return repository.get(`${resource}/my-ticket`)
  },
  getInfoTicket(code) {
    return repository.get(`${resource}/ticket?code=${code}`)
  },
  checkinTicket(payload) {
    return repository.post(`${resource}/check-in`, payload)
  },
  updateEventTicket(payload) {
    return repository.put(`${resource}/ticket-info`, payload)
  },
  transferEventTicket(payload) {
    return repository.put(`${resource}/ticket-transfer`, payload)
  },
  searchPhonenumber(phoneNumber) {
    return repository.get(`${resource}/search-ticket?phone=${phoneNumber}`)
  }
}
