import Vue from "vue";
import App from "./App.vue";
import store from "@/store";
import VueI18n from "vue-i18n";
import "@/registerServiceWorker";
import router from "@/core/routes";
import languages from "@/core/languages";
import { getCurrentLanguage } from "@/core/utils";
import VlinkMetaBootstrap from "@/vlink-meta-main";
import { VueReCaptcha } from "vue-recaptcha-v3";
import { reCaptchaKey, apiImageUrl } from "@/constants/config";
import VueAwesomeSwiper from "vue-awesome-swiper";
import "src/assets/sass/light-bootstrap-dashboard.scss";
import BootstrapVue from "bootstrap-vue/dist/bootstrap-vue.esm";
import VueClipboard from 'vue-clipboard2'
import device from 'vue-device-detector-js'
import { CoolSelectPlugin } from 'vue-cool-select'
import 'vue-cool-select/dist/themes/bootstrap.css'
import Colxx from './components/Common/Colxx'
import VueScrollTo from 'vue-scrollto'
// import VueAnalytics from 'vue-analytics';

VueClipboard.config.autoSetContainer = true
Vue.use(VueScrollTo)
Vue.component('b-colxx', Colxx);
Vue.use(CoolSelectPlugin)
Vue.use(device)
Vue.use(VueClipboard)
Vue.use(VueI18n);
Vue.use(VueAwesomeSwiper);
Vue.use(VlinkMetaBootstrap);
Vue.use(VueReCaptcha, { siteKey: reCaptchaKey });
Vue.prototype.$apiImageUrl = apiImageUrl;
Vue.use(BootstrapVue);
const i18n = new VueI18n({
  locale: getCurrentLanguage(),
  fallbackLocale: "en",
  messages: languages
});
// Vue.use(VueAnalytics, {
//   id: 'G-P5GBSSZFDD',
//   router
// });

export default new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
