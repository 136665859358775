import axios from 'axios'
import { apiUrl } from "@/constants/config";
import store from '@/store/index'
import get from 'lodash.get'

const interceptor = axios.create({
  baseURL: apiUrl,
  headers: {
    Accept: 'application/json',
    'Accept-Language': localStorage.getItem('currentLanguage') || 'en',
    'Content-Type': 'application/json'
  },
  timeout: 60000,
})

if (localStorage.getItem('user')) {
  const user = JSON.parse(localStorage.user)
  const token = get(user, 'Token', null)
  if(!!token) {
    interceptor.defaults.headers.common['Authorization'] = `Bearer ${token}`
  }
}

interceptor.interceptors.response.use(function (response) {
  const res = get(response, 'data', response)
  return res;
}, function (error) {
  store.dispatch('axiosError', error)
  return Promise.reject(error);
});

export default interceptor